/*-----------------------------------------------------------------------------------------
	/Base
-----------------------------------------------------------------------------------------*/
html,body {
	padding: 0;
	margin: 0;
	height: 100%;
}
html {
	font-size: 100%;
	scroll-behavior: smooth;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}
body {
	line-height: 1.7;
	color: @color-body;
	font-family: @font-body;
	font-size: 1em;
	background-color: white;

	@media(min-width: 100em) {
		font-size: 1.0625em;
	}
}
a {
	color: @color-links;
	transition: color .15s ease-in-out, background-color .15s ease-in-out;
	text-decoration: none;
}
p {
	margin-top: 0;
	margin-bottom: 1.5em;

	&:last-child {
		margin-bottom: 0;
	}
}
p a,
.link,
.wysiwyg a {
	border-bottom: 2px solid @color-links;
	transition: .15s border-color, .15s color, .15s box-shadow;
	outline: none;
	color: @color-links;
	// line-height: 1;
	display: inline-block;

	&:hover,
	&:focus {
		outline: none;
		color: @color-secondary;
		border-color: @color-secondary;
		box-shadow:  0 4px 0  fade(@color-secondary, 25%);
	}
}
em {
}
.no-outline:focus {
	outline: none;
}
.link--purple,
.wysiwyg .link--purple{
	border-bottom: 2px solid @color-secondary;
	transition: .15s border-color, .15s color, .15s box-shadow;
	outline: none;
	color: @color-secondary;
	// line-height: 1;
	display: inline-block;
	.card-link:hover &,
	.card-link:focus &,
	&:hover,
	&:focus {
		outline: none;
		color: @color-secondary;
		border-color: @color-secondary;
		box-shadow:  0 4px 0  fade(@color-secondary, 25%);
	}
}
hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid fade(@color-body,15%);
    margin: 2.5em 0 2.5em;
    padding: 0;
    clear: both;
}
.hr--body {
	border-top-color: @color-body;
}
html ::selection { 			background: @color-primary; color: white;}
html ::-moz-selection{ 		background: @color-primary; color: white;}

iframe {
	border: none;
}
figure {
	margin: 0;
}
figcaption {
	margin: 1.7em 0 0;
	font-size: .9em;
	position: relative;
	max-width: 54em;
	padding: 0 2em 0 2.4em;

	&:before {
		content: "";
		height: 2px;
		background: @color-body;
		display: inline-block;
		vertical-align: middle;
		position: absolute;
		left: 0;
		top: 0.75em;
		width: 1.5em;
	}
}
.media {
	position: relative;
	overflow: hidden;
	img {
		object-fit: cover;
		object-position: 50% 50%;
		position: absolute;
		inset: 0;
		width: 100%;
		height: 100%;
	}
}
.footnote {
	margin: 2em 0 0;
	font-size: .9em;
	max-width: 54em;
}
b,strong {
	.weight-medium;
}
.inline-icon-wrap { //https://snook.ca/archives/html_and_css/icon-wrap-after-text
	display: inline;
	white-space: nowrap;
}
.icon {
	display: inline-block;
	text-indent: -5000px;
	overflow: hidden;
	text-align: left;
	vertical-align: middle;
	background-image: url('/images/sprites.svg@{timestamp}');
	background-repeat: no-repeat;
}
small {
	font-size: .9em;
	color: fade(@color-body,70%);
}
.small {
	font-size: .9em;
}
.notification {
	color: white;
	background: @color-secondary;
	border-radius: 10px;
	padding: 1em 1.1em;
	font-size: .88em;
	margin: 2em 0;
	display: flex;
	max-width: 33em;
}
.notification__label {
	flex: 1;
	margin-left: 0.8em;
}
mark {
	background-color: rgba(58, 163, 191, 0.2);
	padding: 0.3em;
	color: @color-body;

	.heading--block & {
		padding: 0.1em 0.2em;
	}
}


/*-----------------------------------------------------------------------------------------
	/Headings
-----------------------------------------------------------------------------------------*/
.headings--page {
	margin-bottom: 2em;
}
.heading--page {
	.weight-bold;
	line-height: 1.2;
	letter-spacing: -.02em;
	font-size: 2.3em;

	@media(min-width: 43.75em) {
		font-size: 3.29em;
	}
}
.headings--page--with-dec:before {
	border-top: 5px solid;
	width: 8em;
	content: "";
	margin-bottom: 2em;
	display: inline-block;

	@media(min-width: 43.75em) {
		width: 12em;
		margin-bottom: 2.5em;
	}
}
.headings--section {
	// margin-bottom: 2em;
	margin-bottom: 3em;
}
.headings--section--with-dec {
	&:after {
		border-top: 3px solid;
		content: "";
		width: 6em;
		display: block;
		margin: 1.5em 0 -1.5em;
	}
}
.headings--section--with-cta {
	@media(min-width: 43.75em) {
		display: flex;
		justify-content: space-between;
		align-items: baseline;
	}

	.heading--section {
		padding-right: 1em;
	}
}
.heading--section {
	.weight-regular;
	font-size: 1.8em;
	line-height: 1.3;

	@media(min-width: 43.75em) {
		line-height: 1.2;
		font-size: 2.4em;
	}


	& + .heading--block { //If block heading is preceeded by section heading
		margin-top: .3em;
	}
}
.headings--block {
	margin-bottom: 1em;
	margin-bottom: 1.5em;
}
.headings--block--tight {
	margin-bottom: .5em;
}
.headings--block--with-dec:before {
	border-top: 3px solid;
	width: 6.5em;
	content: "";
	display: block;
	margin-bottom: 1.5em;
}
.headings--block-s {
	margin-bottom: .6em;
}
.heading--block {
	.weight-regular;
	font-size: 1.43em;
	line-height: 1.4;

	@media(min-width: 43.75em) {
		font-size: 1.53em;
		line-height: 1.5;
	}

	& + .heading--copy { //If copy heading is preceeded by block heading
		margin-top: .3em;
	}
}
.heading--copy,
.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
	.weight-medium;
	text-transform: uppercase;
	font-size: .9em;
	letter-spacing: .05em;
}
.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
	margin: 3em 0 .6em;
}
.template--landing-2022,
.template--news-article{
	.cms-block--wysiwyg{
		.wysiwyg h1,
		.wysiwyg h2,
		.wysiwyg h3 {
			.weight-regular;
			text-transform: inherit;
			letter-spacing: 0;
		}
		.wysiwyg h1{ font-size: 1.625rem; }
		.wysiwyg h2{ font-size: 1.4375rem; }
		.wysiwyg h3{ font-size: 1.25rem; }
	}
}

.heading--card {
	.weight-medium;
	font-size: 1.06em;
	line-height: 1.6;
}
.empty-state {
	max-width: 44em;
}




/*-----------------------------------------------------------------------------------------
	/Images
-----------------------------------------------------------------------------------------*/
.image-bg {
	background-position: center;
	background-size: cover;
	// .bg-senary--light;
	background-color: rgba(232, 222, 216, 0.7);
	background-repeat: no-repeat;
}
img {
	border: none;
}

.page-hero--full-col-height {
	height: 100%;
	margin: 0 -2em;

	@media(min-width: 43.75em) {
		margin: 0 -4em;
	}
	@media(min-width: 62.5em) {
		margin-right: 0;
	}
}
.page-hero--negative-offset {
	@media(min-width: 62.5em) {
		margin-left: -16.666666%;
	}
}
.image-col-stretch {
	@media(min-width: 62.5em) {
		height: 100%;
	}
}
.image-extend--r {
	@media(min-width: 62.5em) {
		margin-right: -16.6666%
	}
}




/*-----------------------------------------------------------------------------------------
	/CMS tables
-----------------------------------------------------------------------------------------*/
.wysiwyg {
	&.table-wrap {
		overflow-x: auto;
		margin: 0 -2em;
		padding: 0 0 0 2em;

		@media(min-width: 43.75em) {
			margin: 0;
			overflow: visible;
			padding: 0;
		}
	}
	.table-wrapper { // #36453 Added this class, and the js that wraps a .wysiwyg table with it
		overflow-x: auto;
		margin: 0 -2em;
		padding: 0 2em 0 2em;
		max-width: 100vw;

		@media(min-width: 43.75em) {
			margin: 0;
			overflow: visible;
			padding: 0;
		}
	}
	table {
		table-layout: fixed;
		border-collapse: collapse;
		width: 100%;
		min-width: 35em;  // #36453 Dropped from 40em to give more affordance
	}
	th {
		padding: 0.9rem 1.2rem;
		.card-date;
		text-align: left;
		vertical-align: top;
	}
	td {
		border-bottom: 1px solid @color-body;
		padding: 0.9rem 1.2rem;
		font-size: .95em;
		vertical-align: top;
	}
	tr:last-child td {
		border-bottom: 0;
		padding-bottom: 0;
	}
}


/*-----------------------------------------------------------------------------------------
	/Global
-----------------------------------------------------------------------------------------*/
.site-main {
	margin-top: @header-height-mobile;
	overflow: hidden;
	position: relative;

	@media(min-width: 43.75em) {
        margin-top: @header-height-desktop;
    }
	@media(min-width: 80em) {
		margin-top: @header-height-xl;
	}
	@media(min-width: 100em) {
		margin-top: @header-height-xxl;
	}

	a[href^="tel:"] {
		color: @color-secondary;
		.weight-medium;
	}
	p a[href^="tel:"] {
		color: inherit;
		border: none;
		box-shadow: none;
	}
}
.check-items {
	margin-bottom: -1.7em;
	margin-top: -1em;
	display: flex;
	flex-wrap: wrap;

}
.check-item {
	margin-bottom: .8em;
	margin: 0 1.8em 1.7em 0;
	display: flex;

	@media(min-width: 62.5em) {
		width: 100%;
		margin: 0 0 .8em;
	}

	.icon--check {
		margin-top: 0.2em;
	}
}
.check-item__sub {
	color: fade(@color-body,60%);
	font-size: .88em;
	// margin-left: .6em;
	display: block;
}
.option-items {
	margin-bottom: 1em;
	margin-top: -1.5em;
}
.option-item {
	@media(min-width: 31.25em) {
		width: auto;
	}
}
.option-item__link,
.option-item__labels {
	display: flex;
	outline: none;
	padding-right: 1em;
	align-items: flex-start;

	.bg-dark & {
		color: white;
	}
}

.option-item__link {
	.option-item__label {
		border-bottom: 2px solid fade(white,20%);
		transition: .15s border;
	}
	&:hover .option-item__label,
	&:focus .option-item__label {
		border-bottom-color: white;
	}
}
.article-back {
	text-align: right;
	padding-top: 4em;
}
.media-object {
	display: flex;
}
.media-object__detail {
	flex: 1;
}
.media-object--centre {
	align-items: center;
}


/*-----------------------------------------------------------------------------------------
	/Data items
-----------------------------------------------------------------------------------------*/
.data-item {
    border-bottom: 1px solid @color-body;
    margin-bottom: 2em;
    padding-bottom: 2em;

	@media(min-width: 62.5em) {
		display: flex;
	}
	// align-items: baseline;

    &:last-child {
        border: none;
		margin-bottom: 0;
		padding-bottom: 0;
    }
}
.data-item--flex-m {
	@media(min-width: 43.75em) {
		display: flex;
	}
}
.data-item__col--flexible {
	flex: 1;
}
.data-item--between {
	justify-content: space-between;
}
.data-item--small {
	margin-bottom: 1em;
    padding-bottom: 1em;
	display: flex;
	align-items: baseline;

	.data-item__col--date {
		width: 10.5em;
	}
}
.data-item__col {
	@media(min-width: 43.75em) {
		padding-right: 3em;
	}

	&:last-child {
		padding-right: 0;
	}
}
.data-item__col--with-icon {
	display: flex;

	.icon {
		margin-right: .8em;
	}
}
.data-item__col__label {
	flex: 1;
}
@media(min-width: 62.5em) {

	.data-item__col--date {
		width: 9.5em;
	}
	.data-item__col--type {
		width: 14em;
		text-align: right;
	}
	.data-item__col--title {
		width: 35%;
	}
	.data-item__col--title--sm { width: 15em; }
	.data-item__col--location { width: 12em; }
}
.data-item__col--title {
	.link {
		white-space: nowrap;
		max-width: 100%;
	}
	.heading--card {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.data-item__col--excerpt {
	flex: 1;

	@media not all and (min-width: 62.5em) {
		margin-top: 1.5em;
	}
}
.data-item__col--filesize {
	white-space: nowrap;
	color: fade(@color-body,50%);

	@media(min-width: 43.75em) {
		text-align: right;
		width: 6em;
	}
}
.data-item__link {
	outline: none;

	&:hover,&:focus {
		color: @color-secondary;
	}
}
.data-item__col--cta {
	margin-top: 2em;
	@media(min-width: 62.5em) {
		margin-top: 0;
		text-align: right;
		width: 9em;
	}
}
.data-item__col--excerpt__small {
	font-size: .88em;
}
.data-item__col--report-name {
	@media not all and (min-width: 43.75em) {
		margin-top: 0.4em;
		margin-bottom: 1.1em;
	}
}




/*-----------------------------------------------------------------------------------------
	/Global type control
-----------------------------------------------------------------------------------------*/
.copy--2col {
	columns: 2 20em;
	column-gap: 4em;

	.wysiwyg ul {
		margin-top: 0;
	}
}



/*-----------------------------------------------------------------------------------------
	/CMS WYSIWYG HELL - force fixes from Word formatting etc
-----------------------------------------------------------------------------------------*/
.wysiwyg {
	p span {
		font-size: inherit !important;
		font-weight: inherit !important;
		color: inherit !important;
	}
}
