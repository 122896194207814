.si-float-wrapper {
  position: absolute;
  width: 100%;
  font-family: @font-body;
}
  .si-float-wrapper,
  .si-float-wrapper * {
    box-sizing: border-box; }

[class*='si-wrapper'] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 17px;
  cursor: default; }

.si-wrapper-top {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-top: -40px;
  margin-left: 0px;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%); }

.si-wrapper-bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  margin-top: 0px;
  margin-left: 0px;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

.si-wrapper-left {
  margin-top: -20px;
  margin-left: -11px;
  -webkit-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%); }

.si-wrapper-right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  margin-top: -20px;
  margin-left: 11px;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

[class*='si-shadow-wrapper'] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.4;
  z-index: 1; }

.si-shadow-wrapper-top,
.si-shadow-wrapper-bottom {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

.si-shadow-pointer-bottom,
.si-shadow-pointer-right {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1; }

.si-shadow-frame {
  box-shadow: 20px 20px 0 0 #000; }

[class*='si-shadow-pointer'] {
  position: relative;
  width: 15px;
  height: 15px;
  margin: auto; }

[class*='si-shadow-inner-pointer'] {
  position: absolute;
  width: 141%;
  height: 141%;
  box-shadow: 0px 28.28427px 0 0 #000; }

.si-shadow-inner-pointer-top {
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg); }

.si-shadow-inner-pointer-bottom {
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 50%) rotate(-45deg);
          transform: translate(-50%, 50%) rotate(-45deg); }

.si-shadow-inner-pointer-left {
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg); }

.si-shadow-inner-pointer-right {
  top: 50%;
  right: 0;
  -webkit-transform: translate(50%, -50%) rotate(-45deg);
          transform: translate(50%, -50%) rotate(-45deg); }

.si-frame {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  border-radius: 3px;
  overflow: hidden;
  z-index: 2;
  border-radius: 10px !important;
  box-shadow: 0 26px 40px rgb(27 83 134 / 30%);
}

.si-content-wrapper {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  background-color: #fff; }
  .si-has-border .si-content-wrapper {
    border: 8px solid #2b3031; }

.si-content {
  // overflow: auto;
}

.si-close-button {
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
  outline: none;
  background-color: transparent;
  color: inherit;
  font-family: Arial, Baskerville, monospace;
  font-size: 2em;
  cursor: pointer;
  opacity: 0.7;
  display: none;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;


      }
  .si-close-button:hover, .si-close-button:focus {
    opacity: 1;
}

[class*='si-pointer-border'] {
  position: absolute;
  border: 15px solid transparent;
  z-index: 3; }

[class*='si-pointer-bg'] {
  position: relative;
  border: 15px solid transparent;
  z-index: 4; }
  .si-has-border [class*='si-pointer-bg'] {
    border-width: 12px; }

.si-pointer-border-top,
.si-pointer-border-bottom {
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

.si-pointer-border-left,
.si-pointer-border-right {
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%); }

.si-pointer-top {
  border-bottom: 0; }

.si-pointer-border-top {
  bottom: 0;
  border-top-color: #2b3031; }

.si-pointer-bg-top {
  border-top-color: #fff; }
  .si-has-border .si-pointer-bg-top {
    top: -8px;
    margin-bottom: 3px; }

.si-pointer-bottom {
  border-top: 0; }

.si-pointer-border-bottom {
  top: 0;
  border-bottom-color: #2b3031; }

.si-pointer-bg-bottom {
  border-bottom-color: #fff; }
  .si-has-border .si-pointer-bg-bottom {
    bottom: -8px;
    margin-top: 3px; }

.si-pointer-left {
  border-right: 0; }

.si-pointer-border-left {
  right: 0;
  border-left-color: #2b3031; }

.si-pointer-bg-left {
  border-left-color: #fff; }
  .si-has-border .si-pointer-bg-left {
    left: -8px;
    margin-right: 3px; }

.si-pointer-right {
  border-left: 0; }

.si-pointer-border-right {
  left: 0;
  border-right-color: #2b3031; }

.si-pointer-bg-right {
  border-right-color: #fff; }
  .si-has-border .si-pointer-bg-right {
    right: -8px;
    margin-left: 3px; }



.map-canvas {
  width: 100%;
  height: 100%; }

.si-wrapper-top {
  max-width: 610px; }
  @media (min-width: 600px) {
    .si-wrapper-top {
      max-width: 610px; } }
  @media (min-width: 992px) {
    .si-wrapper-top {
      max-width: 610px; } }



.si-close-button {
  width: 40px;
  height: 40px; }

.si-content h1 {
  margin: 0 0 50px;
  padding: 10px 0;
  border-top: 2px solid #2b3031;
  border-bottom: 2px solid #2b3031;
}

.si-content hr {
  margin: 50px 0 0;
  border-color: #2b3031; }

.si-content em {
  font-size: 0.85em; }

/*# sourceMappingURL=styles.css.map */
