/*-----------------------------------------------------------------------------------------
	/Footer
-----------------------------------------------------------------------------------------*/
.site-footer {
	border-top: 1px solid fade(@color-body, 10%);
	background: white;
}
.logo-footer {
	display: block;
	width: 8em;
	margin: 0 0 1em;
}
.footer-grid {
	justify-content: space-between;
}
.footer-link {
	font-size: .9em;
	margin-right: 1em;
	border-color: fade(@color-body,15%)
}
.footer-nav {
	margin-bottom: 0.7em;
}
.footer-copyright {
	color: fade(@color-body, 50%);
	font-size: .9em;
}
.footer-terms {
	border-top: 1px solid fade(@color-body, 10%);
	margin-top: 3em;
	padding-top: 2em;
}
.footer-subscribe__field {
	position: relative;
	display: flex;
	margin-top: .5em;
}
.footer-subscribe-input {
	background-color: #f5f5f5;
	border-radius: 10px 0 0 10px;
	border-right: 0;
	width: 100%;
}
.footer-subscribe-button {
	outline: none;
	background: @color-primary;
	background: linear-gradient(45deg, #F6921E, #FAA61A);
	width: 5em;
    border-radius: 0 10px 10px 0;
	transition: .2s box-shadow @ease-quint;

	&:hover,&:focus {
		box-shadow: 0 0 0 5px fade(@color-primary, 20%);
	}
}
.icon--social {
	width: 34px;
	height: 34px;
	margin: 0 0 0 .3em;
}
.icon--social--fb { background-position: 0 -100px; }
.icon--social--tw { background-position: -50px -100px; }
.icon--social--yt { background-position: -100px -100px; }

.footer-members {
	display: flex;
	margin-top: .5em;
	align-items: center;
}
.footer-member-item {
	margin-right: 1.5em;

	&:last-child {
		margin-right: 0;
	}
}
.footer-col {
	width: 100%;

	@media(min-width: 62.5em) {
		width: auto;
	}
}
.footer-col--subscribe {
	padding-bottom: 2em;
	// max-width: 27em;

	@media(min-width: 62.5em) {
		width: 30em;
		padding-bottom: 0;
	}
}
.footer-col--social {
	display: flex;
}
.footer-col--nav,
.footer-col--social {
	@media(min-width: 37.5em) {
		width: auto;
	}
}
.footer-col--nav {
	padding-bottom: 2em;

	@media(min-width: 37.5em) {
		padding-bottom: 0;
	}
}
.icon--shielded__tooltip{
	span{
		position: relative;
		background-color: @color-body;
		border-radius: 10px;
		padding: .5rem 1rem;
		font-size: 0.75rem;
		display: inline-block;
		&:before{
			content: '';
			position: absolute;
			top: 100%;
			left: 10px;
			right: auto;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			
			border-top: 8px solid @color-body;
			
			@media(min-width: 37.5em) {
				right: 10px;
				left: auto;
			}
			
		}

	}
	width: 8.125rem;
	color: white;
	opacity: 0;
	transition: all .3s;
	transform: translateY(5px);
	position: absolute;
	bottom: 3rem;
	left: 0;
	right: auto;
	visibility: hidden;
	text-align: right;
	@media(min-width: 37.5em) {
		left: auto;
		right: 0;
	}
}
.icon--shielded{
	position: relative;
	&:hover, &:focus{
		@media(hover:hover){
			.icon--shielded__tooltip{
				opacity: 1;
				transform: none;
				visibility: visible;
			}
		}
	}
}