/* https://github.com/hankchizljaw/modern-css-reset */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}
header,footer, article, main, nav {
    display: block;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}
nav ul, nav li, .list-reset {
    padding: 0;
    margin: 0;
    list-style: none;
}


/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}


/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}
button,
textarea,
select {
  appearance: none;
}
