/*-----------------------------------------------------------------------------------------
	/Landing 2022
-----------------------------------------------------------------------------------------*/
.template--landing-2022{

    /*------- BUTTONS -------- */

    .button--tertiary{
        &:focus, &:hover{
            box-shadow: 0 0 0 5px fade(@color-primary, 20%);
        }
    }
    .button--quaternary{
        color: white;
        background: @color-quaternary;

        &:hover,&:focus {
            box-shadow: 0 0 0 5px fade(@color-quaternary,20%);
        }
    }
    .button--gf{
        color: white;
        background: @color-gf-teal;

        &:hover,&:focus {
            box-shadow: 0 0 0 5px fade(@color-gf-teal,20%);
        }
    }


    /*------- PDF LINK -------- */
    .--with-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    .icon--button-pdf {
        margin-top: -0.625rem;
        margin-bottom: -0.625rem;
    }

	.color-orange {color: #F6921E;}
	.color-purple{ color: #904098;}
	.color-blue{ color: #008FD5;}
	.color-teal{ color: #007490;}

    .wysiwyg p{
        a:not(.button)[href$=".pdf"]{

            display: inline-flex;
            align-items: center;
            position: relative; 
            color: @color-secondary;
            border-bottom: 2px solid fade(@color-secondary, 20%);
            // margin: 1em 0;
            margin-left: 1.85em;
            
            &:hover,
            &:focus{
                border-bottom-color: @color-secondary;
            }
            &:before{
                content: "";
                position: absolute;
                left: -1.85em;
                background: url('/images/icons/icon--download--primary.svg');
                width: 21px;
                height: 25px;
                flex-shrink: 0;
                margin-bottom: auto;
            }
        } 
        
    }


    /*------- CARD LINK -------- */

    .card-link{
        display: flex;
        flex-direction: column;
    }
    .information-card-image {
        @media not all and (min-width: 31.25em) {
			width: 100%;
			margin: 0.4em 0 0 0;
		}
    }
    .cta-link {
        color: @color-secondary;
        display: inline-flex;
        align-items: center;
        padding-top: 1em;
        span {
            border-bottom: 2px solid currentColor;
        }
        span.--empty {
            border-bottom: 2px solid transparent;
            width: 0;
        }
    }
    .cta-link--compact {
        font-size: 0.9375rem;
        line-height: 1.85;
        letter-spacing: 0.05em;
        text-transform: uppercase;
    }
    .cta-link--has-arrow {
        i {
            transition: transform .2s;
        }
    }
    .card-link--information:hover,
    .card-link--information:focus{
        i {
            transform: translateX(5px);
        }
    }


    /*------- WHITESPACE -------- */

    .l22-block{
        .section-inner--py;
    }
    
    .--no-bg + .--no-bg,
    .--no-bg + .--has-bg,
    .--has-bg-top + .--no-bg,
    .--has-bg-top + .--has-bg,
    // .--has-bg + .--no-bg,
    .--has-bg + .--has-bg{
        // rules for adjacent sections
        padding-top: 0;
    }
    .--s-has-bg + .--no-bg{
        @media (max-width: 62.4375em){
            padding-top: 0;
        }
    }
    .--white-bot + .--white-top{
        // For sections where the top is solid (BG) and the bottom is not
        margin-top: 0;
    }
    .--m-white-bot + .--white-top{ 
        // For sections where the top is solid (BG) and the bottom is not on MOBILE
        @media (max-width: 62.4375em){
            margin-top: 0;
        }
    }
    
    .overlap-compensation{
        // to compensate overlapping overlaps (doesnt add extra space, just a white strip for separation
        display: none;
        position: absolute;
        top: 0;
        left: -2em;
        right: -2em;
        height: 7em;
        background-color: white;
        z-index: -1;
        width: 1000%;

        @media (min-width: 43.75em){
            left: -4em;
            right: -4em;
        }
        @media (min-width: 87.5em){
            left: -6em;
            right: -6em;
        }
    }

    .has-overlap + .has-overlap{
        .overlap-compensation{
            display: block;
        }
    }


    /*------- BLOCKS -------- */

    .section--l22-left-image-text-block{
        .flexi{
            @media(min-width: 62.5em){
                height: 100%;
            }
        }
    }
    .section--l22-left-image-text-block,
    .section--l22-right-image-text-block{
        figure{
            @media(max-width: 62.4375em){
                margin-top: 2em; 
            }
        }
        .flexi{
            @media(max-width: 62.4375em){
                aspect-ratio: 16/9;
            }
        }
        *[class^='aspect-ratio--'], *[class*='aspect-ratio--']{
            position: relative;
            img{
                position: absolute;
                object-fit: cover;
                object-position: center;
                height: 100%;
                width: 100%;
            }
        }
    }
    .mbhs-matrix-item{
        align-items: baseline;
    }
    .left-image-text-block__figure{
        order: 2;
        @media(min-width: 62.5em){
            order:0;
        }
    }
    .image-text-block__chart {
        margin-top: 2em;
        @media(min-width: 62.5em) {
            margin-top: 0;
        }
    }
    .image-text-block__chart--left {
        order: 2;
        @media(min-width: 62.5em){
            order:0;
        }
    }


    .lifestyle__top-images{
        margin-top: 5px;
        @media (min-width: 80em){
            margin-top: 10px;
        }
        .grid-row{
            flex-wrap: nowrap;
            column-gap: 5px;
            @media (min-width: 80em){
                column-gap: 10px;
            }
        }
    }


    .grid-hero__text{
        position: relative;
        
        &:before{
            @media (min-width: 62.5em){
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                background: linear-gradient(#f1e9e5 6em,#fff) no-repeat;
                z-index: -1;
                max-height: 75vh;
                left: -100em;
                right: ~'calc(-6em - 100%)';
            }
        }
    }


    .info-pack-cta__contact{
        &.--primary{
            a[href^="tel:"]{
                color: @color-secondary;
            }
        }
        &.--secondary{
            a[href^="tel:"]{
                color: @color-secondary;
            }
        }
        &.--tertiary{
            a[href^="tel:"]{
                color: @color-primary;
            }
        }
        &.--quaternary{
            a[href^="tel:"]{
                color: @color-quaternary;
            }
        }
        &.--gf{
            a[href^="tel:"]{
                color: @color-gf-teal;
            }
        }
    }


    .section--landing-main-blurb{
        margin-top: 0;
    }

    .section--landing-quote {
        margin-top: 0;
    }

    .cms-block--wysiwyg--covid{
        position: relative;
        background-color: white;
    }

    .has-overlap + .cms-block--wysiwyg--covid{
        .overlap-compensation{
            display: block;
        }
    }

    .section--landing-hero{
        @media (min-width: 62.5em){
            padding-bottom: 0;
        }
    }
    .section--landing-hero__bg{
        @media (min-width: 62.5em){
            max-height: none;
        }
        @media (min-width: 100em){
            max-height: none;
        }

        .hero-img{
            width: 100%;
            height: 100%;
            position: initial;

            img{
                position: absolute;
                top: 0; 
                bottom: 0;
                left: 0;
                right: 0;
                max-height: none;
                object-fit: cover;
                height: 100%;
            }
        }
    }

    .section--landing-hero__bg.object-fit .hero-img img{
        max-height: none;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
 
    
    .info-pack-cta__contact.--no-cta {
        padding-left: 0;
    }

    
    .two-column-hero__form{
        scroll-margin-top: @header-height-mobile + 3em;
    
        @media(min-width: 43.75em) {
            scroll-margin-top: @header-height-desktop + 3em;
        }
        @media(min-width: 80em) {
            scroll-margin-top: @header-height-xl + 3em;
        }
        @media(min-width: 100em) {
            scroll-margin-top: @header-height-xxl + 3em;
        }
    }

    .section--l22-lifestyle {
        .link-video__label--no-video{
            display: flex;
            align-items: center;
            height: 7.469vw;
            position: relative;
            top: -2px;
            
            @media (min-width: 68em){
                height: 5.1594rem;
            }
        }
    }
}