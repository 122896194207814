button{
	appearance: none;
	background: transparent;
	border: 0;
	padding: 0;
	cursor: pointer;
}
.button {
	font-family: @font-body;
	position: relative;
	display: block;
	width: 100%;
	padding: 1.6em 1.5em;
	font-size: .94em;
	.weight-medium;
	border: none;
	border-radius: 10px;
	box-shadow: none;
	outline: 0px solid #333;
	text-align: center;
	cursor: pointer;
	line-height: 1;
	white-space: nowrap;

	transition-duration: .3s;
	transition-property:
		background-color,
		border,
		box-shadow,
		color,
		transform;
	transition-timing-function: @ease-quint;

	&:disabled {
		pointer-events: none;
		opacity: .3;
		background: @color-body !important;
		border-color: @color-body !important;
	}
	&:hover,&:focus {
		box-shadow: 0 15px 7px -10px fade(@color-body,15%);
	}


	@media(min-width: 31.25em) { //500px
		display: inline-block;
		width: auto;
		padding: 1.6em 2.3em;
	}
}
.button-label {
	display: inline-block;
	vertical-align: middle;
}




/*-----------------------------------------------------------------------------------------
	/Button variants
-----------------------------------------------------------------------------------------*/
.button-bar {
	.button {
		margin-bottom: 5px;

		@media (max-width: 37.4375rem) {
			padding: 1.6em 1.6em;
		}
	}
	@media (min-width: 31.25em) {
		display: flex;

		.button:first-child {
			border-radius: 10px 0 0 10px;
		}
		.button:last-child {
			border-radius: 0 10px 10px 0;
		}
		.button:only-child {
			border-radius: 10px;
		}
	}
}
.button-bar--flex { //Always flex
	display: flex;

	.button {
		margin-bottom: 0;
	}
	.button:first-child {
		border-radius: 10px 0 0 10px;
	}
	.button:last-child {
		border-radius: 0 10px 10px 0;
	}
	.button:only-child {
		border-radius: 10px;
	}
}
.button-bar--inline {
	margin-top: 2em;
	margin-bottom: 2em;
}
.button-bar--ctas {
	margin-top: 2em;
	width: fit-content;
}
.button--with-icon {
	display: flex;
	align-items: center;
	justify-content: center;
}
.button--sm {
	font-size: .9em;
	padding: 1em 1.6em;
}
.button--xs {
	font-size: 13px;
	padding: 12px 20px;
}
.button--primary { // Purple
	color: white;
	background: @color-secondary;
	.bg-gradient-purple;

	&:hover,&:focus {
		box-shadow: 0 0 0 5px fade(@color-secondary,20%);
	}
}
.button--secondary { // White, purple outline
	color: @color-secondary;
	background-color: white;
	box-shadow: 0 0 0 1px @color-secondary inset;

	&:disabled {
		background: transparent !important;
	}
	&:hover,&:focus {
		box-shadow: 0 0 0 1px @color-secondary inset, 0 0 0 5px fade(@color-secondary,20%);
	}
}
.button--tertiary { // Orange
	background: @color-primary;
	background: linear-gradient(45deg, #F6921E, #FAA61A);
	color: white;

	&:hover,&:focus {
		box-shadow: 0 0 0 5px fade(white,30%);
	}
}
.button--white {
	background-color: white;
	color: @color-body;
	border: 1px solid #e5e5e5;

	&:is(:hover, :focus) {
		box-shadow: 0 0 0 5px fade(white,30%);
	}
}
.button--grey {
	background-color: #e4e7eb;
	color: @color-body;
	border: 0;
	&:is(:hover, :focus) {
		box-shadow: 0 0 0 5px fade(#e4e7eb,30%);
	}
}
.button--borderless {
	border: none;
}
.button--link {
	padding-inline: 0;
	
	> span {
		padding-block: 7px 5px;
		border-bottom: 2px solid transparent;
		transition: .15s border-color, .15s color, .15s box-shadow;
	}
	
	&:is(:hover, :focus) {
		> span {
			border-bottom: 2px solid @color-body;
			box-shadow: 0 4px 0 fade(@color-body, 10%);
		}
	}
}
.button--cancel {
	color: @color-secondary;
	padding-left: 1.3em;
	padding-right: 1.3em;
	box-shadow: none !important;
}
.button--video {
	display: flex;
	align-items: center;
	outline: none;
}
.button--video--inline {
	margin: 2.5em 0;
}
.button--video__icon {
	margin-right: 1em;
	transition: .3s transform @ease-quint;

	.button--video:hover &,
	.button--video:focus & {
		transform: scale(1.1);
	}
}
.wysiwyg .button--outline,
p .button--outline,
.button--outline {
	color: @color-secondary;
	border: 1px solid @color-secondary;
	border-bottom-width: 2px;
	box-shadow: 0 4px 0 fade(@color-secondary,30%);
	&:hover,
	&:focus {
		color: @color-body;
		border-color: @color-body;
		box-shadow: 0 4px 0 fade(@color-body,30%);
	}
}



/*-----------------------------------------------------------------------------------------
	/Global CTA Icons
-----------------------------------------------------------------------------------------*/
.has-external-icon {
	display: inline-flex;
	align-items: baseline;
}
.icon--r { margin-left: 0.5em; }
.icon--l { margin-right: 0.5em; }

.icon--new-window-white {
	width: 13px;
	height: 13px;
	background-position: 0 -50px;
}
.icon--new-window {
	width: 13px;
	height: 13px;
	background-position: -25px -50px;
}
.icon--shield {
	width: 22px;
	height: 26px;
	background-position: -100px 0;
}
.icon--bed {
	width: 26px;
	height: 23px;
	background-position: 0 -200px;
}
.icon--doc {
	width: 21px;
	height: 25px;
	background-position: -50px 0;
}
.icon--audio {
	width: 22px;
	height: 19px;
	margin-top: 0.3em;
	background-position: -400px -150px;
}
.icon--location {
	width: 14px;
	height: 17px;
	background-position: -250px -50px;
}
.icon--video-xs {
	width: 13px;
	height: 17px;
	background-position: -350px 0;
}
.icon--video {
	width: 29px;
	height: 29px;
	background-position: -200px 0;
}
.icon--video-s {
	width: 26px;
	height: 26px;
	background-position: -250px -100px;
}
.icon--gallery {
	width: 23px;
	height: 15px;
	background-position: -400px 0;
}
.icon--check {
	width: 21px;
	height: 20px;
	background-position: -100px -50px;
}
.icon--brochure {
	width: 28px;
	height: 26px;
	background-position: -150px 0;
}
.icon--info {
	width: 25px;
	height: 25px;
	background-position: -200px -100px;
}
.icon--search--lg {
	width: 27px;
	height: 27px;
	background-position: -350px -50px;
}
.icon--search--lg--infocentre {
	width: 27px;
	height: 27px;
	background-position: -350px -100px;
}
.icon--marker--dark {
	background-position: -300px -75px;
	width: 17px;
	height: 21px;
	margin-top: -1px;
}



//Arrows
.icon--arrow-r {
	width: 32px;
	height: 14px;
	background-position: -500px 0;
}
.icon--arrow-d {
	width: 14px;
	height: 23px;
	background-position: -500px -100px;
}
.icon--arrow-r--gf {
	width: 32px;
	height: 14px;
	background-position: -550px -200px;
}
.icon--arrow-r--white {
	width: 32px;
	height: 14px;
	background-position: -500px -50px;
}
.icon--arrow-r--body {
	width: 32px;
	height: 14px;
	background-position: -500px -25px;
}
.icon--arrow-r--purple {
	width: 32px;
	height: 14px;
	background-position: -500px -125px;
}
.icon--arrow-r--blue {
	width: 32px;
	height: 14px;
	background-position: -500px -175px;
}
.icon--arrow-r--purple {
	width: 32px;
	height: 14px;
	background-position: -500px -125px;
}
.icon--arrow-r--teal {
	width: 32px;
	height: 14px;
	background-position: -500px -150px;
}
.icon--arrow-d--gf {
	height: 23px;
	width: 14px;
	background-position: -500px -200px;
}
.icon--arrow-l {
	width: 32px;
	height: 14px;
	background-position: -550px 0;
}
.icon--arrow-l--white {
	width: 32px;
	height: 14px;
	background-position: -550px -50px;
}
.icon--arrow-l--purple {
	width: 32px;
	height: 14px;
	background-position: -550px -125px;
}
.icon--arrow-l--dark {
	width: 32px;
	height: 14px;
	background-position: -550px -25px;
}
