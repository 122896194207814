/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
  transition: .3s height;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  bottom: 32px;
  width: 32px;
  height: 14px;
  margin-bottom: 2px;
  .icon;
  background-color: transparent;

  .flickity-single-slide-only & {
      display: none;
  }
}

.flickity-prev-next-button.previous {
    background-position: -549px -25px;
 }
.flickity-prev-next-button.next {
    left: 42px;
    background-position: -500px -25px;
 }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
  display: none;
}

/* ---- page dots ---- */

.flickity-page-dots {
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
  line-height: 1;
  padding-top: 4em;
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 12px 0 0;
    border-radius: 50%;
    cursor: pointer;
    border: 2px solid @color-body;
    position: relative;
    transition: .15s opacity;
    opacity: .2;

    &:after {
        content: "";
        width: 5px;
        height: 5px;
        position: absolute;
        background: @color-body;
        top: 50%;
        left: 50%;
        margin: -2.5px;
        border-radius: 100%;
        opacity: 0;
        transition: .15s opacity;
    }
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;

  &:after {
    opacity: 1;
  }
}
