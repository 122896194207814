/*-----------------------------------------------------------------------------------------
	/Global
-----------------------------------------------------------------------------------------*/
.form-contained {
    max-width: 40em;
}
@field-padding: 0.95em 1.3em;
fieldset {
    border: none;
}
.input--txt,
select,
textarea {
    border: 1px solid fade(@color-body,25%);
    background: white;
    color: @color-body;
    padding: @field-padding;
    border-radius: 5px;
    width: 100%;
	transition: background-color .2s ease-in-out, border .2s ease-in-out, box-shadow .2s ease-in-out;
    appearance: none;
    font-family: @font-body;
    outline: none;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);

    @media(min-width: 80em) {
        font-size: .94em;
    }
    .bg-dark & {
        border: none;
    }
}
.input--txt:hover,
textarea:hover,
select:hover {
    border-color: @color-body;
    outline: none;
}
.input--txt:focus,
textarea:focus,
select:focus {
    border-color: @color-body;
    box-shadow: 0 13px 10px -10px rgba(0, 0, 0, 0.2);
}
a.input--txt {
    display: block;
}
.form-field {
    padding: 0;
    margin-bottom: .9em;
}
.form-footnote {
    margin-top: 4em;
    color: #999;
    display: block;
    font-size: .82em;

    a {
        color: @color-secondary;
    }
}

/*-----------------------------------------------------------------------------------------
	/Select
-----------------------------------------------------------------------------------------*/
select {
	height: auto;
    appearance: none;
    -webkit-appearance: none;
	cursor: pointer;
}
.select {
	position: relative;

	&:before {
        content: "";
		background: url('/images/icon--select.svg') no-repeat;
		position: absolute;
        width: 13px;
        height: 9px;
		right: 0.9em;
		top: 50%;
        margin-top: -3.5px;
		pointer-events: none;
		transition: opacity .2s ease-in-out;
	}
}
select:hover,
select:focus {
    outline: none;
}
select::-ms-expand {
    display: none;
}
option:focus {
	// outline: none;
}
option {
	color: @color-body;
}


/*-----------------------------------------------------------------------------------------
	/Textarea
-----------------------------------------------------------------------------------------*/
textarea {
    height: 9.5em;
}


/*-----------------------------------------------------------------------------------------
	/Labels
-----------------------------------------------------------------------------------------*/
.label {
    font-size: .94em;
    display: block;
    margin-bottom: .2em;
}
.label--margin-bot {
    display: block;
    margin: 0 0 0.5em;
}


/*-----------------------------------------------------------------------------------------
	/Inline labels
-----------------------------------------------------------------------------------------*/
.form--inline-labels {
    .form-field {
        position: relative;
    }
    .field--text  .label,
    .field--date  .label,
    .field--email  .label,
    .field--textarea  .label {

        @media(min-width: 37.5em) {
            color: @color-body;
            position: absolute;
            top: 1.3em;
            left: 1.4em;
            pointer-events: none;
            z-index: 1;
        }
        @media(min-width: 80em) {
            top: 0.95em;
        }
    }
    .input--txt {
        @media(min-width: 37.5em) {
            padding-left: 13em;
        }
    }
    textarea {
        @media(min-width: 37.5em) {
            padding-top: 3em;
        }
    }
}
.form--inline-labels--sm {
    .input--txt {
        @media(min-width: 37.5em) {
            padding-left: 8em;
        }
    }
}
.field--submit {
    margin-top: 1em;
    margin-bottom: 0;
}


/*-----------------------------------------------------------------------------------------
	/Checkbox
-----------------------------------------------------------------------------------------*/
.checkbox-item {
    position: relative;
    display: flex;
    align-items: flex-start;

    input {
        margin: 5px 0.4em 0 0;
    }
    label {
        cursor: pointer;
        display: block;
        font-weight: normal;
        flex: 1;
    }
    .form-field--choice--2col & {
        @media(min-width: 31.25em) {
            width: 50%;
            padding-right: 2em;
            margin-right: 0 !important;
        }
    }
}




/*-----------------------------------------------------------------------------------------
	/Browser overrides
-----------------------------------------------------------------------------------------*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
::-webkit-input-placeholder {       color: fade(@color-body, 30%); }
::-moz-placeholder {                color: fade(@color-body, 30%); }
:-ms-input-placeholder {            color: fade(@color-body, 30%); }
:-moz-placeholder {                 color: fade(@color-body, 30%); }

input[type=date] {
    min-height: 4.7em;
}




/*-----------------------------------------------------------------------------------------
	/Validation
-----------------------------------------------------------------------------------------*/
.validationErrors {
    .list-reset;
}
.formValidationError {
    background: @color-secondary;
    font-weight: normal;
    color: white;
    line-height: normal;
    font-size: .8em;
    overflow: hidden;
    width: auto;
    position: relative;
    padding: 0.5em 0.8em;
    display: inline-block;
    border-radius: 0 0 5px 5px;
    margin-left: 6px;

    .field--checkbox & {
        margin: 2.4em 0 0;
        border-radius: 5px;
    }

    .field--choice & {
        margin: 0 0 0 6px;
        border-radius: 0px 0px 5px 5px;
    }
}


/*-----------------------------------------------------------------------------------------
	/Checkboxes,radios
-----------------------------------------------------------------------------------------*/
input[type=radio],
input[type=checkbox] {
    margin-left: 0;
}
.field--choice {
    .checkbox-item {
        display: flex;

        @media(min-width: 31.25em) {
            display: inline-flex;
            margin-right: 1.5em;
        }
    }
}
.field-choice-item__label {
    cursor: pointer;
}
.field-choice-item--inline {
    display: inline-block;
    margin: 0 0.9em 0 0;
}
.checkbox-item--styled {
    input {
        .visuallyhidden;
    }
    label {
        transition: color .2s;
        position: relative;
        padding-bottom: .6em;
    }
    input:focus + label:before,
    input:hover + label:before {
        border-color: @color-links;
    }
    input:checked + label {
        color: @color-links;
        .weight-medium;
    }
    //Icon
    label:before {
        content: "";
        width: 20px;
        height: 20px;
        border: 2px solid fade(black,20%);
        border-radius: 100%;
        position: absolute;
        left: 0;
        top: 0.3em;
        transition: .2s border, .2s background-color;
    }
    input:checked + label:before {
        background: @color-links;
        border-color: @color-links;

        .icon;
        background-position: -397px 5px;
    }
}

.field--styled-check{
    label{
        display: inline-flex;
        cursor: pointer;

        &:before{
            content: '';
            display: block;
            width: 1.25rem;
            height: 1.25rem;
            margin: .25em .5em 0 0;
            border: 2px solid fade(@color-body, 20%);
            border-radius: 20em;
            .icon;
        }
    }

    input:checked + label{
        &:before{
            border: 0;
            background-position: -100px -50px;
        }
    }

    &.field--styled-check--purple{
        input:checked + label{
            color: @color-secondary;

            &:before{
                border: 0;
                background-position: -100px -75px;
            }
        }
    }
}






/*-----------------------------------------------------------------------------------------
	/Filter items, styled checkboxes
-----------------------------------------------------------------------------------------*/
.filter-items {
    margin-top: 1em;
}
.filter-items--only-child {
    margin-top: 0;
}
.filter-item {
    margin: 0 .3em .3em 0;

    @media(min-width: 31.25em) {
        position: relative;
        margin-right: .5em;
    }
}
.filter-item__button  {
    @media not all and (min-width: 80em) {
        padding: .45em .9em;
        font-size: .94em;
    }
}
.filter-item__dropdown {

    &.active {
        .icon {
            transform: rotate(180deg);
        }
    }
}
.filter-dropdown {
    position: absolute;
    background: #fff;
    border: 1px solid #d8d8da;
    padding: 1.1em 1.5em;
    visibility: hidden;
    opacity: 0;
    transition: .2s opacity, .2s visibility, .2s transform;
    border-radius: 5px;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.15);
    z-index: 1;
    transform: translateY(5px);
    left: 1em;
    right: 1em;
    margin-top: .3em;
    max-height: 20em;
    overflow: auto;

    @media(min-width: 31.25em) {
        overflow: visible;
        max-height: none;
        width: 17em;
        margin-top: 0;
        top: 3.2em;
        left: 0;
        right: auto;
    }

    @media(min-width: 80em) {
        top: 4em;
    }

    &.active {
        transform: none;
        opacity: 1;
        visibility: visible;
    }
}
.filter-dropdown--2col {
    @media(min-width: 32.5em) {
        columns: 2;
        width: 24em;
    }
}
.checkbox-styled--label {
    cursor: pointer;
    user-select: none;
    display: flex;
    padding: .3em 0;
    transition: .15s color;

    @media(min-width: 80em) {
        padding: .2em 0;
    }
    &:hover, {
        color: @color-secondary;
    }
}
.icon--checkbox-styled {
    border: 2px solid fade(@color-body, 20%);
    width: 20px;
    height: 20px;
    border-radius: 20em;
    margin: 1px 0 0;
    transition: .15s border;

    .checkbox-styled--label:hover & {
        border-color: @color-secondary;
    }
}
.checkbox-styled--input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.checkbox-styled--input:focus + .checkbox-styled--label { // Focus state
    color: @color-secondary;

    .icon--checkbox-styled {
        border-color: @color-secondary;
    }
}
.checkbox-styled--label__txt {
    flex: 1;
    font-size: .94em;
    line-height: 1.6;
}
//Checked
.checkbox-styled--input:checked + .checkbox-styled--label,
.checkbox-styled--label.active {
    color: @color-secondary;

    .icon--checkbox-styled {
        background-position: -100px -75px;
        border: none;
        border-radius: 0;
    }
}
.filter-count {
    align-self: center;
    width: 100%;
    margin: .7em 0 0;

    @media(min-width: 80em) {
        text-align: right;
    }
    @media(min-width: 62.5em) {
        flex: 1;
        margin: 0 0 0 1em;
        width: auto;
    }
}
.filter-count__number {
    color: @color-secondary;
    white-space: nowrap;
}


/*-----------------------------------------------------------------------------------------
	/Tag items
-----------------------------------------------------------------------------------------*/
.tag-items {
    display: flex;
    flex-wrap: wrap;
    margin-top: .9em;
    margin-bottom: -4px;
}
.tag {
    font-size: .85rem;
    outline: none;
    color: #fff;
    background-color: @color-secondary;
    .bg-gradient-purple;
    padding: 0.2em 0.8em;
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    margin: 0 4px 4px 0;
}
.icon--tag-remove {
    width: 8px;
    height: 8px;
    background-position: -150px -50px;
}
