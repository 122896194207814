/*-----------------------------------------------------------------------------------------
	/UI autocomplete
-----------------------------------------------------------------------------------------*/
.ui-autocomplete  {
	background: #fff;
    position: absolute;
    width: 100%;
    border-radius: 10px;
    overflow: auto;
    max-height: 20em;
    width: 100%;
	box-shadow: 0 20px 20px rgba(0,0,0,.05);
	border-top: 1px solid #e5e5e5;
	// transition: .2s top, .15s visibility, .15s opacity, .15s transform;
	// transform: translateY(10px);
	margin: 3px 0 0;
	padding: 0;
	width: 80%;
	max-width: 28em;
	// visibility: hidden;
	// opacity: 0;
	box-shadow: 0 0 0 1px #ddd inset, 0 20px 40px fade(black,10%);
	border-top: none;

}
.ui-menu-item {
	padding: 0;
	border-bottom: 1px solid #e5e5e5;
	margin: 0;

	&:last-child {
		border: none;
	}
}
.ui-menu-item-wrapper {
	padding: 1.1em 1.5em;
	display: block;
	outline: none;
	cursor: pointer;
	transition: .15s background-color;

}
.ui-state-active {
	background-color: fade(@color-body,10%);
	.bg-gradient-purple;
	color: white;

	.autocomplete-search-option--no-results & {
	    color: fade(@color-body, 60%) !important;
		background: transparent !important;
		cursor: default !important;
	}

	.autocomplete-highlight {
		background-color: fade(white,10%);
	}
	.autocomplete-search-option__module-name {
		color: fade(white,60%);
	}
}
.ui-helper-hidden-accessible {
	display: none;
}
