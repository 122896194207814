/*-----------------------------------------------------------------------------------------
	/Video blocks
-----------------------------------------------------------------------------------------*/
.link--video {
	display: block;
	position: relative;
	background-color: @color-body;
	outline: none;
	transition: .2s box-shadow;

	@media not all and (min-width: 31.25em) {
		padding-bottom: 75%;
	}
	&:after {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		background: linear-gradient(transparent,rgba(0, 0, 0, 0.7));
		content: "";
		height: 70%;

	}
	&:hover,&:focus {
		box-shadow: 0 50px 30px -30px rgba(0, 0, 0, 0.26);
	}
}
.link--video__title {
	position: absolute;
	bottom: 0;
	color: #fff;
	z-index: 1;
	line-height: 1.5;
	font-weight: 400;
	right: 0;
	left: 0;
	padding: 1em 1em 1.4em;
	display: flex;
	align-items: center;
	max-width: 30em;

	@media(min-width: 43.75em) {
		font-size: 1.29em;
		padding: 2em;
	}
}
.link--video__icon {
	width: 18%;
	max-width: 4em;
	margin-right: 1em;
	transition: .2s transform @ease-quint;

	.link--video:hover &,
	.link--video:focus & {
		transform: scale(1.08);
	}
}
.link--video__title__label {
	flex: 1;
}


/*-----------------------------------------------------------------------------------------
	/Full bleed image
-----------------------------------------------------------------------------------------*/
.block--full-bleed-img {
	@media not all and (min-width: 80em) {
		height: 100vh;
	}
}



/*-----------------------------------------------------------------------------------------
	/Accordions
-----------------------------------------------------------------------------------------*/
.accordion {
	margin-top: -1.1em;
}
.button--accordion-toggle {
	width: 100%;
	padding: 1.1em 3em 1.1em 0;
	text-align: left;
	outline: none;
	color: @color-body;
	position: relative;
	transition: .15s color;

	&.active,
	&:focus,&:hover {
		color: @color-secondary;
	}

}
.accordion__copy {
	height: 0;
	overflow: hidden;
	border-bottom: 1px solid @color-body;

	&.active {
		height: auto;
	}
}
.accordion__copy__inner {
	opacity: 0;
	transition: .3s opacity;
	padding: 0em 0 3em;

	.accordion__copy.active & {
		opacity: 1;
	}
}
.icon--accordion {
	width: 13px;
	height: 8px;
	position: absolute;
	right: 0;
	top: 1.6em;
	background-position: -325px 0;

	.button--accordion-toggle.active & {
		transform: rotate(180deg);

	}
}
.accordion-disclaimer {
	display: block;
	max-width: 25em;
	margin-top: 3em;
}


/*-----------------------------------------------------------------------------------------
	/Inline subscribe form
-----------------------------------------------------------------------------------------*/
.subscribe-inline__form {
	display: inline-flex;
	width: 26em;
}
.subscribe-inline {
	display: flex;
	align-items: center;

	&:before,
	&:after {
		height: 1px;
		content: "";
		background: fade(@color-body,20%);
		flex: 1;
	}
}
.section--subscribe-inline {
	text-align: center;
}


/*-----------------------------------------------------------------------------------------
	/CTA sections - Info Centre, Contact etc
-----------------------------------------------------------------------------------------*/
.section--cta {
	text-align: center;
}
.section--cta--bg-image { // Has background image
	position: relative;
	background-color: @color-body;

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(20, 20, 20, 0.6);
		content: "";
	}
	.button--primary:hover,.button--primary:focus {
		box-shadow: 0 0 0 4px rgba(189, 130, 234, 0.45);
	}
}
.section--cta--bg-image--large {
	padding-top: 10em;
	position: relative;
	background-color: transparent;

	@media(min-width: 43.75em) {
		padding-top: 20em;
	}

	&:before {
		background: linear-gradient(16deg,rgba(32,29,29,.7) 23%,transparent 80%);
		z-index: 1;

		@media(min-width: 100em) {
			right: 7em;
		}
	}
	a {
		border-bottom-color: white !important;
	}
}
.section--cta__copy {
	position: relative;

	.heading--section {
		@media not all and (min-width: 80em) {
			line-height: 1.4em;
			max-width: 25em;
			font-size: 1.43em; //Matches block heading
		}
	}
}
.section--cta--bg-image__img {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: @color-body;

	@media(min-width: 100em) {
		right: 7em;
	}
}
.section--cta--bg-image__type {
	position: relative;
	z-index: 1;
}

/*-----------------------------------------------------------------------------------------
	/Community register
-----------------------------------------------------------------------------------------*/
.section--community-register{
	padding-top:0;
	color:@color-body;
	margin-top:-1em;
	@media(min-width: 43.75em){
		margin-top:-2em;
	}
	@media(min-width: 80em){
		margin-top:-3em;
	}

	.grid-col{
		@media(min-width: 62.5em){
			margin-left: 41.666666667%;
		}
	}

	.accordion-disclaimer{
		max-width: none;
		margin-top: 0;
		margin-bottom: 2em;
	}
}


/*-----------------------------------------------------------------------------------------
	/CTA link
-----------------------------------------------------------------------------------------*/
.link--cta {
	white-space: nowrap;
}
.link--cta + .icon {
	transition: .3s transform @ease-quint;
}
.link--cta:hover + .icon--arrow-r,
.link--cta:focus + .icon--arrow-r {
	transform: translateX(5px);
}
.cta-footnote {
	margin-top: 3em;
}
.cta-footnote--center {
	text-align: center;
}
.cta-footnote--space-between {
	@media(min-width: 43.75em) {
		display: flex;
		justify-content: space-between;
	}
	.cta-footnote__label {
		padding-right: 4em;
	}
}
.cta-footnote__label {
	color: @color-body;
	display: block;
	max-width: 36em;
	margin-bottom: 1em;
}


/*-----------------------------------------------------------------------------------------
	/Document download
-----------------------------------------------------------------------------------------*/
.doc-download-inline {
	margin-top: 2em;
}
.link--doc-download {
	display: block;
	font-size: .82em;
	display: flex;
	transition: .15s color;
	outline: none;
}
.link--doc-download__type {
	margin-top: -.4em;
	margin-left: .4em;
	transition: .15s color;

	.link--doc-download:hover &, .link--doc-download:focus & {
		color: @color-secondary;
	}
}
.link--doc-download__size {
	// color: fade(@color-body,60%);
	opacity: .6;
	font-size: .9em;
	display: block;
	margin-top: -0.3em;
}


/*-----------------------------------------------------------------------------------------
	/Tabs
-----------------------------------------------------------------------------------------*/
.tabs {
	overflow-x: auto;
	margin-right: -2em;
	margin-left: -2em;
	padding-left: 2em;
	padding-right: 2em;
	margin-bottom: 3em;
	margin-top: 0.8em;

	@media(min-width: 43.75em) {
		margin-right: -4em;
		margin-left: -4em;
		padding-left: 4em;
		padding-right: 4em;
	}
	@media(min-width: 62.5em) {
		margin-left: auto;
		padding: 0;
		margin-right: auto;
		overflow: visible;
	}
}
.tabs--form-field {
	margin-bottom: 1em;
}
.tab-items {
	display: inline-flex;
	border-radius: 5px;
	background-color: white;
	box-shadow: 0 0 0 1px fade(@color-body,15%) inset;
	overflow: hidden;
}
.tab-link {
	display: block;
	padding: 0.6em 1.5em;
	font-size: .9em;
	box-shadow: -1px 0px 0 0px fade(@color-body,15%) inset;
	white-space: nowrap;

	.tab-item:last-child & {
		box-shadow: none;
	}
	&:hover,&:focus {
		outline: none;
		background: fade(@color-secondary,10%);
		color: @color-secondary;
	}
	&.active {
		background: @color-secondary;
		.bg-gradient-purple;
		color: white;
	}
}



/*-----------------------------------------------------------------------------------------
	/Panel overlaps
-----------------------------------------------------------------------------------------*/
.has-overlap {
	position: relative;
}
.panel-overlap {
	position: absolute;
	z-index: -1;
	bottom: -7em;
	left: 0;
	top: -10em;
	width: 100%;

	@media(min-width: 80em) {
		top: -7em - 7em;
	}
}
.panel-overlap--grid {
	position: absolute;
	z-index: -1;
	bottom: 0;
	top: 0;
}
.panel-overlap--grid--r {
	padding-right: 100vw;
}
.panel-overlap__extend {
	content: "";
	position: absolute;
	left: 0;
	height: 100%;
	margin-left: -100vw;
	padding-left: 100vw;
}
//Extensions
.panel-overlap--grid--bottom-0 {
	bottom: 0;
}
.panel-overlap--grid--extend-t-2x {
	top: -8em;
	@media(min-width: 62.5em) { top: -14em; }
}
.panel-overlap--grid--extend-t {
	top: -4em;
	@media(min-width: 43.75em) { top: -7em; }
}
.panel-overlap--grid--extend-b {
	bottom: -4em;
	@media(min-width: 43.75em) {	bottom: -7em; }
}
.panel-overlap--grid--extend-b-2x {
	bottom: -8em;
	@media(min-width: 62.5em) { bottom: -14em; }
}

//Vertical Offsets
.panel-overlap--grid--offset-t {
	top: 4em;
	@media(min-width: 43.75em) {  top: 7em;  }
}
.panel-overlap--grid--offset-b {
	bottom: 4em;
	@media(min-width: 43.75em) {  bottom: 7em;  }
}


/*-----------------------------------------------------------------------------------------
	/Cards
-----------------------------------------------------------------------------------------*/
.card-link {
	display: block;
	height: 100%;
	outline: none;
}
.card-link__detail {

	@media(min-width: 31.25em) {
		padding-right: 1em;
	}
	@media(min-width: 43.75em) {
		padding-right: 3em;
	}
}
.card-link--information {
	@media not all and (min-width: 31.25em) {
		display: flex;

		.card-link__detail {
			flex: 1;
		}
	}
}
.information-card-image {
    @media not all and (min-width: 31.25em) {
		width: 33%;
    	margin: 0.4em 1.6em 0 0;
	}
}
.card-img {
	position: relative;
	border-radius: 3px;
	margin-bottom: 1.5em;
	transition: .4s box-shadow @ease-quint, .4s transform @ease-quint;

	@media(min-width: 80em) {
		.card-link:hover &,
		.card-link:focus & {
			transform: translateY(-5px);
			box-shadow: 0 20px 25px -20px rgba(0, 0, 0, 0.28);
		}
	}
}
.card-img__tag {
	.bg-gradient-purple;
	.weight-medium;
	padding: 0.8125rem 1.3125rem;
	font-size: 0.75rem;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	line-height: 1.153;
	display: inline-block;
	border-radius: 3px 0 3px 0;
	position: absolute;
	top: 0;
	left: 0;
}
.card-img--placeholder {
	background-image: url('/images/placeholder--card.svg');
	background-size: 3em;
}
.card-date {
	font-size: .8em;
	text-transform: uppercase;
	letter-spacing: .06em;
	.weight-medium;

	.bg-dark & {
		color: white;
	}
}
.card-date--date-display {
	margin-bottom: .7em;
}
.card-tag {
	background: @color-quaternary;
	padding: .5em 1.3em;
	color: white;
	display: inline-block;
	border-radius: 3px 3px 0 0;
	position: absolute;
	height: 2.7em;
	margin-top: -2.7em;
	white-space: nowrap;
}
.card-date--location {
	margin-top: 0.2em;
	margin-bottom: .9em;
	color: fade(@color-body,60%);
	display: flex;


	.icon {
		margin-top: 0.15em;
		margin-right: 0.4em;
	}
}
.card-date--location__label {
	flex: 1;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.headings--card {
	margin-bottom: .3em;
}
.headings--card--with-icon {
	display: flex;
	margin-bottom: 0.8em;
	align-items: center;
}
.headings--card__cat {
	display: block;
	font-size: 0.74em;
}
.card-excerpt {
	font-size: .94em;
}
.card-excerpt__small {
	font-size: .9em;
	display: block;
}
.card-excerpt__modal-link {
	margin-top: 1em;
}
.card-icons {
	margin-bottom: 1em;
	margin-top: 1em;
	display: flex;
}
.card-icon-with-tooltip {
	position: relative;
	outline: none;
	margin-right: .7em;
	pointer-events: default;
}
.card-tooltip {
	position: absolute;
	background: @color-secondary;
	color: #fff;
	font-size: .76em;
	padding: 0.6em 1.2em;
	border-radius: 3px;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
	white-space: nowrap;
	top: -3.5em;
	left: 50%;
	transform: translateX(-50%);
	visibility: hidden;
	opacity: 0;
	transition: .2s visibility .1s, .2s opacity .1s;

	.card-icon-with-tooltip:hover &,
	.card-icon-with-tooltip:focus & {
		visibility: visible;
		opacity: 1;
	}

	&:after {
		content: "";
	    width: 0;
	    height: 0;
	    border-left: 6px solid transparent;
	    border-right: 6px solid transparent;
	    border-top: 6px solid @color-secondary;
	    position: absolute;
	    left: 50%;
	    margin-left: -6px;
	    bottom: -6px;
	}
}
.card-readmore {
	margin-top: 2em;
	border: none;
	box-shadow: none;
}



/*-----------------------------------------------------------------------------------------
	/Person card - Governance etc
-----------------------------------------------------------------------------------------*/
.person-card {
	@media(min-width: 43.75em) {
		display: flex;
		flex-direction: column;

		.card-excerpt {
			display: flex;
			flex-direction: column;
			flex: 1;
		}
		.card-excerpt__modal-link {
		    margin-top: auto;
		    justify-self: start;
			margin-right: auto;
		}
		.card-link__detail {
		    flex: 1;
		    display: flex;
		    flex-direction: column;
		}
	}
}


/*-----------------------------------------------------------------------------------------
	/Cards - small screen horizontal overflow scroll with snapping
-----------------------------------------------------------------------------------------*/
.card-items--overflow-scroll {
    margin: 0 -2em;

	@media(min-width: 43.75em) {
		margin: 0 -4em;
	}

	.card-items {
	    flex-wrap: nowrap;
	    overflow-x: auto;
    	scroll-snap-type: x mandatory;
	    padding: 0 2em;

		@media(min-width: 43.75em) {
			padding: 0 4em;
		}
	}
	.card-item {
		flex: 0 0 95%;
   		scroll-snap-align: center;

		@media(min-width: 37.5em) {
			flex: 0 0 55%;
		}
		@media(min-width: 43.75em) {
			flex: 0 0 60%;
		}
		@media(min-width: 62.5em) {
			flex-grow: 0;
			flex-basis: auto;
		}
		&:last-child:after { //Filthy hack to get my right-hand edge padding back
			content: "";
			width: 120%;
			height: 1px;
			display: inline-block;
			visibility: hidden;

			@media(min-width: 62.5em) {
				display: none;
			}
		}
	}
}





/*-----------------------------------------------------------------------------------------
	/Info cards - Info centre, Good Friends etc
-----------------------------------------------------------------------------------------*/
.card-link--info {
	background: white;
	border-radius: 10px;
	padding: 2em;
	box-shadow: 0 30px 20px -20px rgba(138, 116, 107, 0.15);
	outline: none;
	transition: .1s box-shadow;
	height: 100%;

	display: flex;
	flex-direction: column;

	@media(min-width: 43.75em) {
		padding: 3em;
	}
	.card-readmore {
		transition: .2s padding, .2s background-color;
		box-shadow: none !important; //Override everything, not needed
		border-radius: 3px;
		margin-top: auto;
		margin-right: auto;
	}
	.card-excerpt {
		margin-bottom: 1.5em;
	}
}
.card-link.card-link--info {
	&:hover,&:focus {
		box-shadow:
			0 30px 20px -20px rgba(138, 116, 107, 0.15),
			0 0px 0px 2px rgb(213, 200, 193);

		.card-readmore {
			background-color: @color-tertiary;
			padding: 0 0.4em 0 0.5em;
			box-shadow: none;
		}
	}
}
.card-links--values {
	margin-top: 4em;
}
.card-link--info--values {
	text-align: center;
	position: relative;
}
.card-link--info--values__icon {
	position: absolute;
	top: -1.8em;
	left: 50%;
	margin: 0 0 0 -32px;
}
.card-link--info--values__heading {
	margin-bottom: 0.4em;
}




/*-----------------------------------------------------------------------------------------
	/CTA cards
-----------------------------------------------------------------------------------------*/
.card-link--cta {
	position: relative;
}
.card-link--cta__heading {
	position: absolute;
    bottom: 2.1em;
    left: 2.5em;
    right: 2em;
    color: #fff;
    z-index: 1;
}
.card-link--cta_card-img {
	margin-bottom: 0;
	position: relative;
	background-color: @color-body;

	&:before {
		border-radius: 3px;
		content: "";
		background: linear-gradient(19deg, fade(black,90%), fade(black,0%) 19em);
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
	}
}


/*-----------------------------------------------------------------------------------------
	/Facility cards
-----------------------------------------------------------------------------------------*/
.card--facility {
	display: block;
	font-size: .9em;
	padding: 1.2em 1.1em;
	border-radius: 10px;
	height: 100%;
	transition: .15s box-shadow;
	outline: none;
	box-shadow: 0 0 0 1px fade(@color-body,15%) inset,
				0 15px 5px -15px fade(@color-body,10%);

	@media(min-width: 43.75em) {
		display: flex;
		align-items: flex-start;
		padding: 1.5em 1.8em;
		font-size: 1em;
	}

}
.card--facility--link {
	&:focus,&:hover {
		box-shadow: 0 0 0 2px @color-body inset,
					0 17px 15px -15px fade(@color-body,25%);
	}
}
.card--facility__icon {
	width: 2em;
	margin: -.3em .7em 0 0;
}
.card--facility__label {
	flex: 1;
}
.card--facility__label__subtitle {
	opacity: .5;
	font-size: .9em;
}


/*-----------------------------------------------------------------------------------------
	/Pagination
-----------------------------------------------------------------------------------------*/
.pagination {
	position: relative;
	text-align: center;
}
.pagination-items {
	margin: 0;
	padding: 0;
}
.pagination-item {
    display: inline-block;
    padding: 0 0.2em;
}
.pagination-prev,
.pagination-next {
	margin: 0 1em;
	border-bottom: 2px solid transparent;
	transition: .15s border;
	outline: none;

	&.disabled {
		opacity: .4;
		pointer-events: none;
	}
	&:hover,&:focus {
		border-color: @color-body;
	}
}
.pagination-page {
    display: block;
	width: 2.8em;
    height: 45px;
    line-height: 43px;
    transition: .2s border;
	.weight-medium;
	text-align: center;
	box-shadow: 0 2px 0 @color-body;
	transition: .2s color, .2s box-shadow;
	outline: none;

	&:hover,&:focus {
		box-shadow: 0 4px 0 @color-body;
	}

	&.active {
		box-shadow: 0 4px 0 @color-secondary;
		color: @color-secondary;
    }

    &:hover {
		border-color: white;
    }
}
.pagination-next-prev {
	.weight-medium;
    text-transform: uppercase;
	color: white;
	margin: 0 1em;

	&:hover,&:focus {
		border-color: fade(white,20%);
	}

    .disabled & {
        color: fade(@color-body,30%);
        border-color: transparent;
        pointer-events: none;
		cursor: default;
    }
}
.pagination-height-placeholder {
    height: 2em;
}



/*-----------------------------------------------------------------------------------------
	/Sliders
-----------------------------------------------------------------------------------------*/
//Base
.slide--gallery {
	width: 100%;
	margin: 0 10px 0 0;
}
.slider-caption {
	margin: 2.5em 0 0 2em;
	transition: .5s opacity;
	opacity: 0;

	@media(min-width: 62.5em) {
		margin-left: 0;
	}

	.flickity-enabled .is-selected & {
		opacity: 1;
	}
	&:before {
		top: 1.15em;
	}
}

//Full width
.slides--full-width {
	.flickity-page-dots {
		text-align: center;
		padding-top: 5em;
	}
	.flickity-page-dots .dot {
		margin-right: 0 6px;
	}
	.flickity-prev-next-button {
		// bottom: 32px;
	}
	.flickity-prev-next-button.previous{
		left: 50%;
	    margin-left: -2.6em;
	}
	.flickity-prev-next-button.next{
		left: 50%;
		margin-left: 0;
	}
}
.slides--full-width--left {
	text-align: left;
	margin: 0 -2em;

	@media(min-width: 43.75em) {
		margin: 0 -4em;
	}
	@media(min-width: 62.5em) {
		margin-right: -6em;
		margin-left: 0;
	}
	.flickity-page-dots {
		padding-left: 2em;

		@media(min-width: 43.75em) {
			padding-left: 4em;
		}
		@media(min-width: 62.5em) {
			padding-left: 0;
		}
	}
	.flickity-prev-next-button {
		margin-left: 2em;

		@media(min-width: 43.75em) {
			margin-left: 4em;
		}
		@media(min-width: 62.5em) {
			margin-left: 0;
		}
	}
}
.slide--full-width {
    width: 100%;
    max-width: 1200px;
    transform: scale(.9);
    transition: .6s transform;

	@media(min-width: 43.75em) {
		width: 80%;
	}
	@media(min-width: 62.5em) {
		width: 70%;
	}
	@media(min-width: 100em) {
		width: 50%;
	}

	&.is-selected {
		transform: none;
	}
}
.slide--full-width--alt { // No transform
    width: 70%;
    max-width: 1000px;
	margin: 0 5px;
}
.panel-dec--slider {
	position: relative;
	margin-bottom: -7em;

	.section--image-slider & {
		display: none;

		@media(min-width: 100em) {
			display: block;
		}
	}
}


//Edge viewport bleed - right
.slides--gallery--edge-bleed-r {
	@media(min-width: 43.75em) {
		margin-right: -4em;
	}
	@media(min-width: 62.5em) {
		margin-right: -20vw;
	}

	.slide--gallery {
		width: 100%;

		@media(min-width: 43.75em) {
			width: 66.6666%;
		}
		@media(min-width: 62.5em) {
			width: 75%;
			max-width: 42em;
		}
	}
}


/*-----------------------------------------------------------------------------------------
	/CMS Blocks
-----------------------------------------------------------------------------------------*/
.cms-block {
	margin-bottom: 4em;
	scroll-margin-top: 4em;

	&:last-child {
		margin-bottom: 0;
	}
}
.cms-block--inline-image {
	position: relative;
	scroll-margin-top: 96px;
}
.cms-block--infocard-image {
	padding: 0 10px;

	figcaption {
		margin-left: 2em;
		padding-right: 4em;
		max-width: 34em;
	}
}
.cms-block--inline-image__img {
	position: relative;

	&:before {
		background: @color-senary;
	    content: "";
	    position: absolute;
	    top: 15%;
	    bottom: 15%;
	    z-index: -1;
	    left: 0;
	    right: 0;
	    margin-left: -50vw;
	    margin-right: -50vw;
	}
}

.cms-block--article-intro {
	& + .cms-block--wysiwyg {

		&:before {
			width: 8em;
			display: block;
			height: 1px;
			background: @color-body;
			content: "";
			margin: -1em 0 3em;
		}
	}
}

.cms-block--embed-form {
	> div {
		width: fit-content;
		margin-inline: auto;
	}
}

/*-----------------------------------------------------------------------------------------
	/Image grids
-----------------------------------------------------------------------------------------*/
.image-grid {
	column-gap: 5px;
      row-gap: 5px;
	// display: -ms-grid;
	display: grid;

	@media (min-width: 80em) {
		column-gap: 10px;
	      row-gap: 10px;
	}
}

//3 up
.image-grid--3up {
    grid-template-columns: 1fr 1fr;

	@media not all and (min-width: 62.5em) {
		margin: 0 -4em;
	}
	@media (orientation: portrait) {
		// min-height: 50vh;
	}

    @media(min-width: 62.5em) {
		grid-template-columns: 4fr 6fr;
	}

	.image-grid__img:nth-child(3n) {
		grid-column-start: 1;
		grid-column-end: 3;

		@media(min-width: 62.5em) {
			grid-column-start: 2;
			grid-row-end: 3;
			grid-row-start: 1;
		}
	}
}
.image-grid__img--wrapper {
	position: relative;

	&:before {
		content: "";
	    position: absolute;
	    height: 10em;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    background: linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.7));
	}
}
.image-grid__img--cover {
	height: 100%;
}
.image-grid--gallery-button {
	position: absolute;
	bottom: 2em;
	left: 4em;

	@media (min-width: 43.75em) {
		left: 2em;
	}
}


//4 up
.image-grid--4up {
	grid-template-columns: repeat(12, [col] 1fr);
	grid-template-rows: 2;
	margin-left: -4em;
	margin-right: -4em;

	@media (orientation: portrait) {
		min-height: 70vh;
	}
	@media (min-width: 62.5em) {
		margin-left: auto;
		margin-right: auto;
	}

	.image-grid__img1 { grid-column: col 1 / span 4; }
	.image-grid__img2 { grid-column: col 5 / span 8; }
	.image-grid__img3 { grid-column: col 1 / span 8; }
	.image-grid__img4 { grid-column: col 9 / span 4; }
}
.image-grid__img--wrapper {
	position: relative;

	&:before {
		content: "";
	    position: absolute;
	    height: 10em;
	    bottom: 0;
	    left: 0;
	    right: 0;
	    background: linear-gradient(rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.7));
	}
}
.image-grid__img--cover {
	height: 100%;
}


//Hero image grid
.hero-image-grid-area {
	margin-top: 1em;

	@media(min-width: 43.75em) {
		margin-top: 5em;
	}
	@media(min-width: 62.5em) {
		padding-left: 6em;
		margin-top: 0;
	}
}
.grid-hero--video-cta {
	@media (min-width:43.75em) and (max-width:62.5em) {
		.button--video--inline {
			display: block;
			text-align: center;
			margin: 0;
		}
		.button--video__icon {
			margin: 0 auto 1em;
		}
	}
}
.image-grid--hero {
	// -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	// -ms-grid-rows: 3;

	grid-template-columns: repeat(12, [col] 1fr);
	grid-template-rows: 3;
	margin: -10px -2em 0;

	@media(min-width: 62.5em) {
		min-height: 48em;
		margin-left: 0;
	}
	@media(min-width: 80em) {
		margin-right: 0;
		min-height: 0;
	}

	.image-grid__img--1 {
		-ms-grid-column: col 1 / span 4;
		-ms-grid-row: row / span 2;

		grid-column: col 1 / span 4;
	    grid-row: row / span 2;
	}
	.image-grid__img--2 {
		grid-column: col 1 / span 4;
	    grid-row: row 3 / span 2;
	}
	.image-grid__img--3 {
		grid-column: col 5 / span 9;
	    grid-row: row / span 4;
	}
	.image-grid__img--4 {
		grid-column: col 2/span 6;
		grid-row: row 5 / span 2;
	}
	.image-grid__img--5 {
		grid-column: col 8/span 6;
		grid-row: row 5 / span 3;
	}
}



/*-----------------------------------------------------------------------------------------
	/Modals
-----------------------------------------------------------------------------------------*/
.modal-content {
	display: none;
}
.modal--enquire,
.modal--service,
.modal--bio {
	width: 100%;
	max-width: 38em;

	@media(min-width: 37.5em) {
		margin-top: 2.5%;
		margin-bottom: 2.5%;
		width: 95%;
	}
	@media(min-width: 62.5em) {
		margin-top: 2.5%;
		margin-bottom: 2.5%;
		max-width: 1200px;
	}
}
.modal--bio {
	max-width: none;
	@media(min-width: 62.5em) {
		max-width: 1200px;
	}
}
.modal--service,
.modal--career {
	@media(min-width: 62.5em) {
		max-width: 700px;
	}
}
.modal--map-infowindow {
	max-width: 27em;
	width: 100%;
}
.modal--enquire__img {
	padding-bottom: 68%;
}
.modal--gallery {
	width: 100%;
	border-radius: 0;
	height: 100vh;

	.flickity-page-dots {
		padding-top: 3em;
	}
	.slide--full-width {
		width: 100%;
		opacity: 0;
		transition: .6s transform, .6s opacity;

		@media(min-width: 62.5em) {
			filter: grayscale(1);
			width: 79%;
		}

		&.is-selected {
			opacity: 1;
			filter: none;
		}
	}
	.flickity-prev-next-button {
		display: none;
		@media(min-width: 62.5em) {
			display: block;
			margin: 0;
			left: 0;
			bottom: auto;
			top: 50%;
			width: 54px;
			height: 54px;
			background-color: transparent;
		}
	}
	.flickity-prev-next-button.previous {
		margin: -22px 0 0;
		left: 2em;
		background-position: -150px -150px;
	}
	.flickity-prev-next-button.next {
		margin: -22px 0 0;
		right: 2em;
		background-position: -50px -150px;
		left: auto;
	}
}
.modal--gallery__heading {
	text-align: center;
	margin: 3em 0 2em;

	@media(min-width: 62.5em) {
		margin-top: 1em;
	}
	@media(min-width: 80em) {
		margin-top: -2em;
	}
}
.logo--modal__img {
	@media not all and (min-width: 43.75em) {
		width: 118px;
	}
}
.logo--modal {
	outline: none;
	margin: -3em 0 0 1.3em;
	display: block;

	@media(min-width: 43.75em) {
		margin: -3.2em 0 0 2em
	}
	@media(min-width: 62.5em) {
		position: absolute;
	    top: 2em;
	    left: 2em;
		margin: 0;
	}
}

//Bio modal
.modal--bio__image {
	background: linear-gradient(90deg, @color-senary 85%, transparent 85%);
}
.modal--bio__img {
	background-color: @color-senary;

	@media(min-width: 43.75em) {
		box-shadow: 0 40px 40px -40px rgb(37 22 15 / 80%);
	}
}



/*-----------------------------------------------------------------------------------------
	/Career slider
-----------------------------------------------------------------------------------------*/
.career-slide {
	width: 100%;
	@media (min-width: 80em) {
		align-items: center;
	}
}
.career-testimonial {
	padding: 2em;
	position: relative;

	@media (min-width: 43.75em) {
		padding-left: 4em;
	}
	@media (min-width: 80em) {
		padding-top: 0;
		padding-bottom: 0;
	}

	&:before {
		content: "";
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background: @color-tertiary;
		position: absolute;
		z-index: -1;

		@media (min-width: 80em) {
			top: -4em;
			right: 8em;
			left: 0;
			bottom: -4em;
		}
	}
}
.career-testimonial__copy {
	font-size: 1.05em;
	font-weight: normal;
	margin-bottom: 1.5em;
	margin-top: -1em;

	@media (min-width: 62.5em) {
		font-size: 1.35em;
	}
}
.career-testimonial-intro {
	@media not all and (min-width: 62.5em) {
		padding-top: 0;
	}
}
.career-video-cta {
	@media (min-width:43.75em) and (max-width:80em) {
		.button--video--inline {
			display: block;
			text-align: center;
			margin: 0;
		}
		.button--video__icon {
			margin: 0 auto 1em;
		}
	}
}


/*-----------------------------------------------------------------------------------------
	/Read more content
-----------------------------------------------------------------------------------------*/
.read-more-content {
	display: none;
	margin-top: 2em;

	&.active {
		display: block;
	}
}
.read-more-toggle__more {
	.read-more-toggle.active & {
		display: none;
	}
}
.read-more-toggle__less {
	display: none;

	.read-more-toggle.active & {
		display: inline;
	}
}



/*-----------------------------------------------------------------------------------------
	/WYSIWYG
-----------------------------------------------------------------------------------------*/
.wysiwyg {
	ul {
		list-style-type: none;
		margin: 2em 0;
	}
	li {
		margin-bottom: 1em;
		position: relative;
		&:last-child {
			margin-bottom: 0;
		}
	}
	ul li {
		&:before {
			width: 7px;
			height: 7px;
			background-color: @color-secondary;
			content: "";
			position: absolute;
			left: -1.4em;
			top: 0.6em;
			border-radius: 100%;
		}
	}
}


/*-----------------------------------------------------------------------------------------
	/Pathway CTA
-----------------------------------------------------------------------------------------*/
.button--pathway-cta {
	color: @color-secondary;
}
.pathway-cta {
	align-items: center;
	position: relative;
	z-index: 1;
}
.pathway-cta__copy {
	position: relative;

	@media not all and (min-width: 62.5em) {
		padding-left: 0;
		padding-right: 0;
		padding-top: 2em;
	}
	@media(min-width: 62.5em) {
		padding-left: 4em;
	}

	&:before {
		background: linear-gradient(95deg,#662D91 0%,#A154A1 80%);
		content: "";
		position: absolute;
		top: -10em;
		left: -4em;
		right: -4em;
		bottom: 0;
		z-index: -1;

		@media(min-width: 62.5em) {
			top: 0;
			left: -12em;
			right: 0;
			bottom: 0;
		}
	}
}
.pathway-cta__copy {
	.button:hover, .button:focus {
		box-shadow: 0 0 0 5px rgba(205, 146, 215, 0.5);
	}
}




/*-----------------------------------------------------------------------------------------
	/Autocomplete dropdowns
-----------------------------------------------------------------------------------------*/
// .has-autocomplete-dropdown {
// 	position: relative;
// }
.autocomplete-search-options {
	background: #fff;
    position: absolute;
    width: 100%;
    border-radius: 10px;
    margin-top: 2px;
    overflow: auto;
    max-height: 20em;
    width: 100%;
	box-shadow: 0 20px 20px rgba(0,0,0,.05);
	transition: .2s top, .15s visibility, .15s opacity, .15s transform;
	transform: translateY(10px);
	visibility: hidden;
	opacity: 0;

	&.active {
		visibility: visible;
		opacity: 1;
		transform: none;
	}
}
.autocomplete-search-option {
	border-bottom: 1px solid #e5e5e5;

	&:last-child {
		border: none;
	}
}
.autocomplete-search-option__module-name {
	font-size: 0.8em;
	color: fade(@color-body, 60%);
	line-height: 1.5;
	display: block;
	text-align: right;
	margin-top: 0.3em;
}
.autocomplete-search-option__meta {
	font-size: .9em;
}
.autocomplete-search-option__link {
	padding: 0.9em;
	font-size: .88em;
	display: block;
	outline: none;

	&:hover, &:focus {
		background-color: fade(@color-tertiary,50%);

	}
}
.autocomplete-search-option__link--center {
	align-items: center;
}
.autocomplete-search-option__link--chunky,
.autocomplete-search-option--chunky {
	font-size: 1em;
	padding: 1.1em 1.5em;
}
.autocomplete-highlight {
	.weight-medium;
	background-color: @color-tertiary;
}
.autocomplete-search-option__col1 {
	width: 6em;
	margin: 0 1.4em 0 0;
}
.autocomplete-search-option__col2 {
	flex: 1;
}
.autocomplete-search-option__img {
	border-radius: 3px;
}
.autocomplete-search-option--no-results {
    color: fade(@color-body, 60%) !important;
	background: transparent !important;
	cursor: default !important;
}


/*-----------------------------------------------------------------------------------------
	/Vertical subnav
-----------------------------------------------------------------------------------------*/
.vertical-subnav-items {
    transition: .3s opacity, .3s visibility, .3s transform @ease-quint;
	display: none;

	@media(min-width: 62.5em) {
		padding-right: 4em;
		display: block;
	}

	&.sticky {
		@media(min-width: 62.5em) {
			position: fixed;
			top: 2em;
		}
		@media(min-width: 80em) {
			top: 8em;
		}
	}
}
.vertical-subnav-items--hidden {
    @media(min-width: 43.75em) {
		opacity: 0;
		visibility: hidden;
		transform: translateY(-20px);
	}
}
.vertical-subnav-item {
	border-bottom: 1px solid fade(@color-quaternary,20%);

	&:last-child {
		border: none;
	}
}
.vertical-subnav-link {
	padding: 1em;
	padding-left: 0;
	outline: none;

	&.active,
	&:hover,&:focus {
		color: @color-quaternary;
	}
}
.vertical-subnav-item__icon {
	margin-right: 1em;
}



/*-----------------------------------------------------------------------------------------
	/Toast - community.  'Back to community'
-----------------------------------------------------------------------------------------*/
.toast--community {
	position: fixed;
	bottom: .5em;
	left: .5em;
	z-index: 2;
	transition: .4s opacity, .4s transform, .4s visibility;
	transform: translateY(10px);
	opacity: 0;
	visibility: hidden;
	animation: fadeIn ease 2s;
	font-size: .9em;

	@media(min-width: 43.75em) {
		font-size: 1em;
		bottom: 2em;
		left: 2em;
	}

	&.active {
		opacity: 1;
		visibility: visible;
		transform: none;
	}
}
@keyframes fadeIn {
	0% {
		opacity:0;
		transform: translateY(10px);
	}
	80% {
		opacity:0;
		transform: translateY(10px);
	}
	100% {
		transform: none;
		opacity:1;
	}
}
.toast--community__link {
	display: block;
	background: @color-body;
	display: flex;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 18px 20px -10px rgba(0, 0, 0, 0.36);
	transition: .4s transform, .4s background-color;

}
.toast--community__prefix {
	font-size: .7em;
	display: block;
}
.toast--community__title {
	font-size: 1.07em;
	display: block;

	@media(min-width: 43.75em) {
		font-size: 1.17em;
	}
}
.toast--community__titles {
	padding: 1.2em 2em 1.2em 1.2em;
	display: flex;
	align-items: center;
	line-height: 1.4;
	position: relative;
	z-index: 1;
	flex: 1;

	&:after {
		content: "";
		position: absolute;
		.bg-gradient-purple;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		opacity: 0;
		transition: .3s opacity;
	}
}
.toast--community__link:hover,
.toast--community__link:focus {
	.toast--community__titles:after {
		opacity: 1;
	}
}
.toast--community__img {
	display: none;
	@media(min-width: 43.75em) {
		display: block;
		width: 6em;
	}
}
.toast--community__close {
	position: absolute;
	top: 0;
	right: 0;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	outline: none;
	z-index: 2;
}


/*-----------------------------------------------------------------------------------------
	/Breadcrumb
-----------------------------------------------------------------------------------------*/
.breadcrumb-link {
	color: currentColor;
	font-size: 0.9375rem;
	position: relative;
	margin-left: 1.625rem;
	font-weight: 500;
	svg {
		position: absolute;
		top: 50%;
		left: -1.625rem;
		transform: translateY(-50%);
	}
	&.breadcrumb-link--premium {
		border-bottom-color: transparent;
		&:hover,
		&:focus {
			border-bottom-color: #fff;
		}
	}
	&.breadcrumb-link--basic {
		margin-bottom: 1em;
		border-bottom-color: transparent;
		&:hover,
		&:focus {
			border-bottom-color: @color-secondary;
		}
		@media (min-width: 62.5em) {
			margin-bottom: 0.5em;
		}
	}
}
/*-----------------------------------------------------------------------------------------
	/Landing hero
-----------------------------------------------------------------------------------------*/
.section--landing-hero {
	position: relative;
	max-width: 128rem;
	margin-left: auto;
	margin-right: auto;
	.headings--page {
		@media (max-width: 62.4375em) {
			&:before {
				display: none;
			}
		}
	}
}
.section--landing-hero__bg {
	display: none;
	@media (min-width: 62.5em) {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 50%;
		text-align: center;
		max-height: 37.5rem;
		.fade {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 15%;
		}
		.fade--r {
			left: auto;
			right: 0;
		}
	}
	@media (min-width: 100em) {
		max-height: calc(100vh - 8.5rem);
	}
	.hero-img {
		@media (min-width: 62.5em) {
			background-size: contain;
			background-position: 50%;
			display: inline-block;
			width: auto;
			height: 100%;
			position: relative;
			img {
				display: block;
				max-height: 100%;
			}
		}
	}
}
.section--landing-hero__cols {
		@media (min-width: 62.5em) {
			// min-height: 75vh;
		}
}
.section--landing-hero__type {
	@media(min-width: 62.5em) {
		position: relative;
		// padding-bottom: 0;
		&:before {
			content: "";
			position: absolute;
			top: 0; right: 0; bottom: 0; left: 0;
			background: linear-gradient(-180deg, @color-senary 60%, transparent);
			z-index: -1;
			max-height: 75vh;
		}
	}
}
.landing-hero-image {
	position: relative;
}
.landing-hero-image__img {
	padding-bottom: 90%;
	@media (min-width: 43.75em) {
		padding-bottom: 60%;
	}
	@media (min-width: 62.5em) {
		padding-bottom: 112%;
	}
}
.landing-hero-image__img--mobile {
	@media (min-width: 43.75em) {
		display: none;
	}
}
.landing-hero-image__img--tablet {
	display: none;
	@media (min-width: 43.75em) {
		display: block;
	}
	@media (min-width: 62.5em) {
		display: none;
	}
}
.landing-hero-image__img--desktop {
	display: none;
	@media (min-width: 62.5em) {
		display: block;
		.fade {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 15%;
		}
	}
}


/*-----------------------------------------------------------------------------------------
	/Info pack CTA
-----------------------------------------------------------------------------------------*/
.info-pack-cta {
	text-align: center;
	.button {
		width: 100%;
		@media (min-width: 43.75em) {
			width: 50%;
		}
		@media (min-width: 62.5em) {
			width: auto;
		}
	}
	@media (min-width: 43.75em) {
		display: flex;
		align-items: center;
		text-align: left;
	}
}
.info-pack-cta__contact {
	.weight-bold;
	padding-top: 1.25rem;
	.tel {
		font-size: 1.25rem;
	}
	@media (min-width: 43.75em) {
		padding-top: 0;
		padding-left: 2.5rem;
	}
}


/*-----------------------------------------------------------------------------------------
	/Landing quote
-----------------------------------------------------------------------------------------*/
.section--landing-quote {
	position: relative;
	@media (min-width: 62.5em) {
		margin-top: 5rem;
		&:before {
			content: "";
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			width: 100%;
			border-top: 1px solid @color-body;
		}
	}
	@media (min-width: 80em) {
		margin-top: 7.5rem;
	}
}
.section--landing-quote__inner {
	position: relative;
	max-width: 71.375rem;
	@media (min-width: 43.75em) {
		padding: 5rem 0;
		&:before {
			background: @color-tertiary;
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 50vw;
		}
	}
	@media (min-width: 62.5em) {
		padding: 3.75rem 1.25rem;
		background: #fff;
		&:before {
			width: 18.75rem;
			left: 9.375rem;
		}
	}

}
.section--landing-quote__cols {
	position: relative;
	@media (min-width: 43.75em) {
		padding-left: 4em;
		padding-right: 4em;
		flex-wrap: nowrap;
	}
	@media (min-width: 62.5em) {
		padding: 0;
	}
}
.section--landing-quote__image {
	width: 11.875rem;
	height: 11.875rem;
	border-radius: 50%;
	margin: 0 auto -5.6875rem;
	position: relative;
	z-index: 2;
	@media (min-width: 43.75em) {
		margin: 0 2.375rem 0 0;
		width: 10rem;
		height: 10rem;
		flex: 0 0 10rem;
	}
	@media (min-width: 62.5em) {
		width: 32.125rem;
		height: 32.125rem;
		flex: 0 0 32.125rem;
	}
}
.section--landing-quote__text {
	background: @color-tertiary;
	padding: 7.5rem 2em 3.75rem;
	.heading--block {
		margin: 0 0 0.875rem;
	}
	@media (min-width: 43.75em) {
		background: 0;
		padding: 0;
	}
}


/*-----------------------------------------------------------------------------------------
	/Landing - main blurb
-----------------------------------------------------------------------------------------*/
.section--landing-main-blurb {
	margin-top: 3.125rem;
	.info-pack-cta {
		justify-content: center;
		margin-top: 3.4375rem;
	}
	@media (min-width: 43.75em) {
		margin-top: 5rem;
	}
	@media (min-width: 80em) {
		margin-top: 7.5rem;
	}
}
.section--landing-main-blurb__text {
	@media (min-width: 62.5em) {
		columns: 2;
		column-gap: 4.375rem;
	}
}



/*-----------------------------------------------------------------------------------------
	/Home - campaign CTA
-----------------------------------------------------------------------------------------*/
.home-campaign {
	position: relative;
	margin-bottom: 0.625rem;
	&:not(.home-campaign--default) {
		.home-campaign__cols {
			@media (min-width: 62.5em) {
				justify-content: center;
			}
		}
	}
	@media (min-width: 100em) {
		margin-right: 7em;
	}
}
.home-campaign__bg {
	@media (min-width: 62.5em) {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 50%;
	}
}
.home-campaign__cols {
	position: relative;
	z-index: 1;
	@media (min-width: 62.5em) {
		display: flex;
		align-items: center;
	}
}
.home-campaign__media {
	position: relative;
	.fade {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 15%;
		display: none;
	}
	.fade--b {
		display: block;
		width: 100%;
		height: 20%;
		top: auto;
		bottom: 0;
		left: 0;
		right: 0;
		@media (min-width: 62.5em) {
			display: none;
		}
	}
	.fade--l {
		left: 0;
		@media (min-width: 62.5em) {
			display: block;
		}
	}
	.fade--r {
		right: 0;
		@media (min-width: 62.5em) {
			display: block;
		}
	}
	@media (min-width: 62.5em) {
		width: 75%;
	}
}
.home-campaign__hero--mobile {
	width: 100%;
	@media (min-width: 62.5em) {
		display: none;
	}
}
.home-campaign__hero--desktop {
	display: none;
	@media (min-width: 62.5em) {
		display: block;
		// margin-left: -4.875rem;
	}
}
.home-campaign__cta {
	padding: 1rem 2rem 2.5rem;
	text-align: center;
	@media (min-width: 62.5em) {
		padding: 0 2rem 0 0;
		text-align: left;
		&:first-child {
			padding: 2rem 0;
		}
	}
}



/*-----------------------------------------------------------------------------------------
	/cta links
-----------------------------------------------------------------------------------------*/

.cta-list{
	display: inline-flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -.75rem;

	.cta-list__item{
		padding: 0.5rem .75rem;
	}
}



/*-----------------------------------------------------------------------------------------
	/checklist
-----------------------------------------------------------------------------------------*/
.checklist-block{
	.checklist-block__block{
		max-width: 60ch;
		padding-bottom: 3rem;

		@media(min-width: 62.5em) {
			padding-bottom: 0;
		}
		
		ul{
			padding-left: 1.5rem;
			margin-bottom: 0;
			list-style-type: none;
		}
	
		li{
			position: relative;
	
			&:before{
				.pseudo;
				.icon;
				position: absolute;
				top: .5rem;
				left: -1.5rem;
				width: 14px;
				height: 11px;
				background-position: -450px 0;
			}
	
			& + li{
				margin-top: 1rem;
			}
		}
	}
}




/*-----------------------------------------------------------------------------------------
	/open vacancies
-----------------------------------------------------------------------------------------*/

.open-vacancies{
	@media(min-width: 43.75em) {
		.text-center;
	}
}


/*-----------------------------------------------------------------------------------------
	/Share information - Contact
-----------------------------------------------------------------------------------------*/
.block--share-info-content {
	tr:not(:last-child) td {
		padding-bottom: 2em;
	}
	td p {
		margin-bottom: 0;
	}
}