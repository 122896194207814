body.fancybox-active{
    overflow: hidden;
}


/*  ----------------------------------------------------------------------------
	/General components
    ------------------------------------------------------------------------- */

.pathway-care{
    width: 100%;
    height: 100vh;
    padding: 0;
    background: linear-gradient(103.79deg, #662D91 2.06%, #A154A1 91.86%), #904098;
    border-radius: 0;

    .fancybox-close-small{
        position: absolute;
        top: 1rem;
        right: 1rem;
        padding: 0;
        background: transparent;
        border-radius: 0;

        @media(min-width: 43.75em) { //700px
            top: 2rem;
            right: 2rem;
        }

        @media(min-width: 62.5em) { //1000px
            position: fixed;
            top: 1rem;
            right: 1rem;
    	}

        @media(min-width: 80em) { //1000px
            top: 1.875rem;
            right: 1.875rem;
    	}

        &:after{
            position: relative;
            top: 0;
            left: 0;
            display: block;
            width: 34px;
            height: 34px;
            margin: 0;
            background-position: -150px -100px;
        }
    }

    .heading--block{
        em,
        strong{
            font-style: inherit;
            background-color: fade(@color-secondary, 10%);
            color: @color-secondary;
        }
    }

    &__inner{
        display: flex;
        justify-content: center;
        // align-items: center;
        width: 100%;
        min-height: 100vh;
        padding: 1.2rem 1rem;

        @media(min-width: 43.75em) { //700px
            padding: 2.2rem 2.6rem;
        }

        @media(min-width: 62.5em) { //1000px
            padding: 3.5rem 4rem;
    	}
    }

    &__content{
        display: block;
        width: 100%;
        max-width: 70.625rem;
        // margin: 0 auto;
    }

    &__main{
        background-color: #FFF;
        border-radius: .625rem;

        @media(min-width: 62.5em) { //1000px
            display: flex;
    	}
    }

    &__info{
        padding: 1.5rem 1.2rem;
        background-color: #F6F6F6;
        border-radius: .625rem .625rem 0 0;

        @media(min-width: 43.75em) { //700px
            padding: 2.2rem 2rem;
        }

        @media(min-width: 62.5em) { //1000px
            flex-shrink: 0;
            flex-basis: 28.7610619469%;
            max-width: 28.7610619469%;
            padding: 3rem 2.5rem;
            border-radius: .625rem 0 0 .625rem;
    	}

        @media(min-width: 80em) { //1280px
    		padding: 4.5rem 3.125rem;
    	}

        p{
            max-width: 60ch;
        }
    }

    &__question{
        width: 100%;
        padding: 1.5rem 1.2rem;

        @media(min-width: 43.75em) { //700px
            padding: 2.2rem 2rem;
        }

        @media(min-width: 62.5em) { //1000px
            padding: 3rem 2.8rem 3rem 2.6rem;
        }

        @media(min-width: 80em) { //1280px
    		padding: 4.5rem 4.375rem 4.5rem 4.0625rem;
    	}
    }

    &__result{
        padding: 1.5rem 1.2rem;

        @media(min-width: 43.75em) { //700px
            padding: 2.2rem 2rem;
        }

        @media(min-width: 62.5em) { //1000px
            padding: 3rem 2.6rem 3rem 2.8rem;
        }

        @media(min-width: 80em) { //1280px
            padding: 4.5rem 4.0625rem 4.5rem 4.375rem;
    	}
    }

    &__form-info{
        padding: 1.5rem 1.2rem;
        background-color: #F6F6F6;
        border-radius: 0 0 .625rem .625rem;

        @media(min-width: 43.75em) { //700px
            padding: 2.2rem 2rem;
        }

        @media(min-width: 62.5em) { //1000px
            flex-shrink: 0;
            flex-basis: 40.8088235294%;
            max-width: 40.8088235294%;
            padding: 3rem 2.5rem;
            border-radius: 0 .625rem .625rem 0;
    	}

        @media(min-width: 80em) { //1280px
    		padding: 4.5rem 4.0625rem 4.5rem 3.75rem;
    	}
    }
}


// header
.pathway-header{
    padding-bottom: 2.5rem;
    color: #FFF;

    @media(min-width: 62.5em) { //1000px
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;
        padding-bottom: 3.25rem;
    }

    &__headings{
        @media(min-width: 62.5em) { //1000px
            flex-grow: 2;
            padding-right: 1rem;
        }
    }

    &__heading{
        padding-top: 1.5rem;
        padding-bottom: .5rem;
        color: inherit;

        @media(min-width: 62.5em) { //1000px
            padding-top: 0;
        }
    }
}


// steps
.pathway-steps{
    display: flex;

    @media(min-width: 62.5em) { //1000px
        justify-content: flex-end;
    }

    &__copy{
        padding-right: .625rem;
        text-transform: uppercase;
        .weight-medium;
        font-size: .8125rem;
        line-height: 1;

        @media(min-width: 43.75em) { //700px
            padding-right: 1.25rem;
        }
    }

    &__jauge{
        display: block;
        overflow: hidden;
        width: 13.125rem;
        height: .75rem;
        background-color: fade(#FFF, 20%);
        border-radius: 20em;

        &:before{
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-color: #FFF;
        }

        &[data-step="1"]:before{ width: 25%; }
        &[data-step="2"]:before{ width: 50%; }
        &[data-step="3"]:before{ width: 75%; }
        &[data-step="4"]:before{ width: calc(100% - .5em); }
    }
}


// questions
.pathway-question{

    &__heading{
        margin-bottom: 1.25rem;

        @media(min-width: 43.75em) { //700px
            margin-bottom: 1.5rem;
        }

        @media(min-width: 62.5em) { //1000px
            margin-bottom: 2.5rem;
        }
    }

    &__answer-list{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -.3rem;

        @media(min-width: 43.75em) { //700px
            margin: 0 -.625rem;
        }
    }

    &__answer-option{
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        max-width: 50%;
        padding: .3rem;

        @media(min-width: 43.75em) { //700px
            flex-basis: 33.3333333%;
            max-width: 33.3333333%;
            padding: .625rem;
        }
    }

    &__nav{
        margin-top: 1.25rem;

        @media(min-width: 43.75em) { //700px
            margin-top: 1.5rem;
        }

        @media(min-width: 62.5em) { //1000px
            margin-top: 2.5rem;
        }
    }

    &__error{
        padding-top: .5em;
        color: @color-red;
    }
}

// option card
.option-card{
    position: relative;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 2.875rem 1.25rem 1.25rem;
    padding: 22% 1.25rem 10%;
    border-radius: .625rem;
    line-height: 1.2;
    transition: background-color 200ms linear;

    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #D0D0D0;
        border-radius: .625rem;
        transition: border-color 200ms linear;
    }

    &__icon-block{
        display: block;
        padding: 1.5em;
        margin-bottom: .625rem;
        background-color: fade(@color-secondary, 10%);
        border-radius: 50%;
    }

    &__icon{
        display: block;
    }

    &__label{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 3.875rem;
        width: 100%;
        text-align: center;
        font-size: .875rem;
        transition: color 200ms linear;

        @media(min-width: 43.75em) { //700px
            font-size: 1rem;
        }

        small{
            display: block;
            font-size: .875rem;
            line-height: 130%;
            color: inherit;
        }
    }

    &:hover,
    &:focus{
        &:before{
            border: 1px solid @color-secondary;
        }

        .option-card__label{
            color: @color-secondary;
        }
    }

    input:checked + &{
        background-color: fade(@color-secondary, 10%);

        &:before{
            border: 2px solid @color-secondary;
        }

        .option-card__label{
            color: @color-secondary;
        }
    }
}









/*  ----------------------------------------------------------------------------
	/step 2
    ------------------------------------------------------------------------- */

.pathway-location{
    @media(min-width: 43.75em) { //700px
        display: flex;
    }

    &__list{
        columns: 2;
        padding-bottom: 1rem;

        @media(min-width: 43.75em) { //700px
            flex-shrink: 0;
            padding-top: 2.5rem;
            padding-right: 2.5rem;
            padding-bottom: 0;
            columns: 1;
        }
    }

    &__item{
        display: inline-block;
        vertical-align: top;

        @media(min-width: 43.75em) { //700px
            display: initial;
        }
    }

    &__map{
        position: relative;
        flex-shrink: 0;
        flex-grow: 2;

        svg{
            max-width: 100%;
        }
    }

    &__pin{
        position: absolute;
        cursor: pointer;

        &.pathway-location__pin--select{
            color: @color-secondary;
        }
    }
}









/*  ----------------------------------------------------------------------------
	/step 4
    ------------------------------------------------------------------------- */

.pathway-nasc{
    margin-top: 1.25rem;

    &__list{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -.625rem;

        .field{
            padding: .25rem .625rem;
        }
    }
}








/*  ----------------------------------------------------------------------------
	/final
    ------------------------------------------------------------------------- */

.pathway-care.pathway-care--final{
    .pathway-care__content{
        max-width: 85rem;
    }
}

.pathway-result{

    &__back{
        padding: .75rem;
        margin: 1.25rem -.75rem 0;
        text-transform: uppercase;
        .weight-medium;
        font-size: .9375rem;
        line-height: 1.2;
        letter-spacing: .05rem;
        color: @color-secondary;

        .icon--arrow-l--purple{
            margin-top: -3px;
            transition: transform 200ms ease;
        }

        .txt{
            border-bottom: 2px solid transparent;
            transition: border-bottom-color 200ms linear;
        }

        &:hover,
        &:focus{
            outline: none;

            .icon--arrow-l--purple{
                transform: translateX(-.5rem);
            }
            .txt{
                border-bottom-color: @color-secondary;
            }
        }
    }

    &__resume{
        padding-bottom: 3.125rem;
        margin-bottom: 3.125rem;
        border-bottom: 1px solid @color-body;
    }

    &__help{
        margin-bottom: 3.125rem;
    }

    &__list{
        padding-top: 1.25rem;

        @media(min-width: 62.5em) { //1000px
            padding-top: 2rem;
        }
    }
}

.pathway-form-info{
    .form--inline-labels .input--txt{
        @media (min-width: 37.5em) {
            padding-left: 7em;
        }
    }

    &__copy{
        margin-top: 1.875rem;
        margin-bottom: 1.875rem;

        a[href^="tel:"]{
            border-bottom: 0;
            .weight-bold;

            &:hover,
            &:focus{
                box-shadow: 0 2px 0 @color-secondary;
            }
        }
    }

    .form--inline-labels .filter-item__dropdown{
        display: flex;
        align-items: center;
        padding-left: 1.3em;
        text-align: left;

        .txt{
            flex-grow: 2;
        }

        .icon{
            flex-shrink: 0;
        }
    }

    .filter-dropdown{
        z-index: 10;
    }
}

.pathway-care_result_form-fields {
    margin-top: 2.5rem;
}
