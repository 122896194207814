/*-----------------------------------------------------------------------------------------
	/Disgusting overrides for MS Dynamic third-party form embeds
-----------------------------------------------------------------------------------------*/
.marketingForm {
    scroll-margin-top: 6.25rem;
    
    p, a {
        color: @color-body;
        font-family: @font-body !important;
        font-size: 1rem !important;
        text-decoration: none !important;

        @media(min-width: 100em) {
            font-size: 1rem !important;
        }
    }
    h1 {
        color: @color-body !important;
    }


    /*-----------------------------------------------------------------------------------------
    	/Containers
    -----------------------------------------------------------------------------------------*/     
    .outer {
        width: 100% !important;
    }
    .inner {
        padding: 0 !important;
    }
    [data-layout^="true"] {
        max-width: none !important;
    }

    
    /*-----------------------------------------------------------------------------------------
    	/Copy blocks
    -----------------------------------------------------------------------------------------*/    
    [data-editorblocktype^="Text"] {
        margin-left: unset !important;
        margin-right: unset !important;

        * {
            font-family: @font-body !important;
            font-size: 1rem !important;
        
            @media(min-width: 100em) {
                font-size: 1rem !important;
            }
        }
        // &:first-child { // Intro
            // margin-bottom: 2rem !important;
        // } 
        &:last-child { // Form footnote
            margin: 2rem 0 0 !important;
            
            * {
                font-size: .82rem !important;
                color: @color-grey--a11y;
            }
            a {
                color: @color-secondary !important;
                border: none !important;
            }
        }  
        p {
            text-align: unset !important;
            margin-bottom: 1rem !important;
            line-height: 1.6 !important;
            
            &:last-child {
                margin-bottom: 0 !important;
            }
        }
    }
    
    /*-----------------------------------------------------------------------------------------
        /Blocks
    -----------------------------------------------------------------------------------------*/    
    .textFormFieldBlock, .dateTimeFormFieldBlock, .twoOptionFormFieldBlock, .optionSetFormFieldBlock, .multiOptionSetFormFieldBlock, .lookupFormFieldBlock {
        display: block;
        margin-bottom: .9em;
        padding: unset !important;
    }
    [data-hide^="hide"] {
        display: none !important;
    }
    .primaryButtonWrapper,
    .submitButtonWrapper {
        text-align: left;
        padding: 0;
    }
    
    /*-----------------------------------------------------------------------------------------
        /Fields
    -----------------------------------------------------------------------------------------*/
    .optionSetFormFieldBlock select,
    .textFormFieldBlock input,
    .dateTimeFormFieldBlock input,
    .lookupFormFieldBlock input,
    .marketingForm textarea,
    div[data-editorblocktype="Captcha"] input,
    .textFormFieldBlock textarea,
    .multiOptionSetFormFieldBlock fieldset {
        font-size: 1rem;
        border: 1px solid fade(@color-body, 25%);
        border-width: 1px !important;
        background-color: white;
        color: @color-body;
        padding: @field-padding;
        border-radius: 5px;
        width: 100%;
        transition: background-color .2s ease-in-out, border .2s ease-in-out, box-shadow .2s ease-in-out;
        appearance: none;
        font-family: @font-body;
        outline: none;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.06);

        &:hover {
            border-color: @color-body;
            outline: none;
        }
        &:focus {
            border-color: @color-body;
            box-shadow: 0 13px 10px -10px rgba(0, 0, 0, 0.2);
        }
    }
    .optionSetFormFieldBlock select {
        background-image: url('/images/icon--select.svg');
        background-repeat: no-repeat;
        background-position: right 1.125rem center;
    }
    .multiOptionSetFormFieldBlock fieldset>div {
        // display: flex !important;
        display: inline;

        label {
            margin-left: 8px;
        }
    }
    .textFormFieldBlock label,
    .dateTimeFormFieldBlock label,
    .lookupFormFieldBlock label,
    .twoOptionFormFieldBlock label.block-label,
    .optionSetFormFieldBlock label.block-label,
    .multiOptionSetFormFieldBlock label.block-label,
    .multiOptionSetFormFieldBlock fieldset > div > label,
    div[data-editorblocktype="Captcha"] label[id^="wlspispHipInstructionContainer"] {
        font-family: @font-body;
        font-size: 1rem;
        font-weight: normal !important; 
        color: @color-body;
        margin-bottom: .2rem;
        text-align: left;

        * {
            font-family: inherit !important;
            font-size: inherit !important;
        }
    }
    .consentBlock {
        padding: 0 !important;
        margin-bottom: .9rem;

        & + .consentBlock {
            margin-top: -.6rem;
        }

        & > div {
            align-items: flex-start;
        }
        div input {
            width: auto !important;
            height: auto !important;
            margin-top: .2rem;
        }
        label {
            display: flex;
        }
    }
    .multiOptionSetFormFieldBlock {
        div input {
                width: auto !important;
                height: auto !important;
                margin-top: .2rem;
            }
    }


    /*-----------------------------------------------------------------------------------------
        /Multi options
    -----------------------------------------------------------------------------------------*/
    .multiOptionSetFormFieldBlock {
        fieldset {
            margin: 0 !important;
            max-height: 15rem;
            overflow: auto;
        }
    }
    .twoOptionFormFieldBlock div.radiobuttons>div,
    .twoOptionFormFieldBlock div.twooption_checkbox>div,
    .optionSetFormFieldBlock div.radiobuttons>div,
    .multiOptionSetFormFieldBlock fieldset>div,
    .consentBlock>div {
        align-items: start;
    }
    .twoOptionFormFieldBlock div.radiobuttons div input,
    .twoOptionFormFieldBlock div.twooption_checkbox div input,
    .optionSetFormFieldBlock div.radiobuttons div input,
    .multiOptionSetFormFieldBlock fieldset>div>input[type="checkbox"],
    .eventSession>div>input[type="checkbox"],
    .consentBlock div input {
        margin-top: .2rem;
    }


   /*-----------------------------------------------------------------------------------------
        /Inline labels
    -----------------------------------------------------------------------------------------*/
    .textFormFieldBlock {
        position: relative;

        @media(min-width: 37.5em) {
            label {
                position: absolute;
                top: 0.9rem;
                left: 1.3rem;
                margin-top: 0 !important;
                width: auto !important;
            }
            input {
                padding-left: 11rem;
            }
            textarea {
                padding-top: 3rem;
            }
        }
    }


    /*-----------------------------------------------------------------------------------------
        /Buttons
    -----------------------------------------------------------------------------------------*/
    .marketingForm .primaryButton,
    .submitButton {
        font-family: @font-body;
        position: relative;
        display: block;
        width: 100%;
        padding: 1.6em 1.5em;
        font-size: .94rem;
        .weight-medium;
        border: none;
        border-radius: 10px;
        box-shadow: none;
        outline: 0px solid #333;
        text-align: center;
        cursor: pointer;
        line-height: 1;
        white-space: nowrap;
        transition: .3s @ease-quint;
        color: white;
        background: @color-secondary !important;
        .bg-gradient-purple !important;

        &:hover,&:focus {
            box-shadow: 0 0 0 5px fade(@color-secondary,20%);
        }

        span {
            font-family: inherit !important;
        }

        @media(min-width: 31.25em) {
            display: inline-block;
            width: auto;
            padding: 1.6em 2.3em;
        }
    }


    /*-----------------------------------------------------------------------------------------
        /Captchas
    -----------------------------------------------------------------------------------------*/
    div[data-editorblocktype="Captcha"] {
        padding: 0 !important;

        td {
            text-align: left !important;
        }
        input {
            padding: @field-padding !important;
        }
    }
    

    /*-----------------------------------------------------------------------------------------
        /Validation
    -----------------------------------------------------------------------------------------*/
    .textFormFieldBlock label::after,
    .twooption_checkbox label::after,
    .dateTimeFormFieldBlock label::after,
    .lookupFormFieldBlock label::after,
    .twoOptionFormFieldBlock label.block-label::after,
    .twoOptionFormFieldBlock div.twooption_checkbox label::after,
    .optionSetFormFieldBlock label.block-label::after,
    .multiOptionSetFormFieldBlock label.block-label::after,
    .consentBlock label::after,
    .eventSession label::after,
    div[data-editorblocktype="Captcha"] label[id^="wlspispHipInstructionContainer"]::after {
        width: 1rem !important;
        display: none !important;
    }
    //They are using visibility: hidden, which leaves a visible gap when the asterix wraps FML
    .textFormFieldBlock[data-required="required"] label::after,
    .twooption_checkbox[data-required="required"] label::after,
    .dateTimeFormFieldBlock[data-required="required"] label::after,
    .lookupFormFieldBlock[data-required="required"] label::after,
    .twoOptionFormFieldBlock[data-required="required"] label.block-label::after,
    .twoOptionFormFieldBlock[data-required="required"] div.twooption_checkbox label::after,
    .optionSetFormFieldBlock[data-required="required"] label.block-label::after,
    .multiOptionSetFormFieldBlock[data-required="required"] label.block-label::after,
    .consentBlock[data-required="required"] label::after,
    .eventSession[data-required="required"] label::after,
    div[data-editorblocktype="Captcha"] label[id^="wlspispHipInstructionContainer"]::after {
        display: inline-block !important;
    }



}


/*-----------------------------------------------------------------------------------------
    /Thanks
-----------------------------------------------------------------------------------------*/
    div[data-cached-form-url] .onFormSubmittedFeedback .onFormSubmittedFeedbackMessage {
        color: @color-body !important;
        font-family: @font-body !important;
        font-size: 1.43em !important;
        text-decoration: none !important;
        padding: 1rem 0 0 !important;
        text-align: left !important;
        line-height: 1.5 !important;
        
        @media(min-width: 43.75em) {
            font-size: 1.53em !important;
        }
    }
    .onFormSubmittedFeedback {
        height: auto !important;
        width: 100% !important;
        background-color: transparent !important;
    }
    div[data-cached-form-url] .onFormSubmittedFeedbackIcon {
        display: none !important;
    }
    div[data-cached-form-url] .onFormSubmittedFeedback {
        justify-content: start !important;

        .onFormSubmittedFeedbackInternalContainer {
        padding: 0 !important;
        }
    }
    #js-form-container,
    .onFormSubmittedFeedback,
    .onFormSubmittedFeedbackInternalContainer {
        scroll-margin-top: 6.25rem;
    }


/*-----------------------------------------------------------------------------------------
    /Spinner
-----------------------------------------------------------------------------------------*/
    .formLoader {
        border-top:     2px solid fade(@color-secondary,20%) !important;
        border-right:   2px solid fade(@color-secondary,20%) !important;
        border-bottom:  2px solid fade(@color-secondary,20%) !important;
        border-left:    2px solid @color-secondary !important;
        width: 2rem !important;
        height: 2rem !important;
        margin: 0 !important;
    }


/*-----------------------------------------------------------------------------------------
    /Newsletter subscribe
-----------------------------------------------------------------------------------------*/
.ms-dynamics-newsletter-subscribe {
    width: 100%;
    // margin-bottom: 3rem;

    label {
        .visuallyhidden;
    }
    .marketingForm .textFormFieldBlock input {
        padding: @field-padding;
    }
    th {
        padding: 0 !important;
    }
    th,td,tr, tbody {
        width: 100%;
        height: auto !important;
        display: flex;
    }
    .consentBlock,
    div[data-targetproperty="address2_fax"] {
        display: none !important;
    }
    .columnContainer {
        display: flex !important;
        flex-wrap: wrap;
        gap: .5rem;
        align-items: start;
        width: 100% !important;
        position: relative;
    }
    .submitButtonWrapper {
        margin: 0 !important;
    }
    .textFormFieldBlock {
        flex: 1;
        margin-bottom: 0;

       &:nth-child(3) {
            flex: 1 1 50rem;
       }
    }
    .submitButton, input[type="email"], input[type="text"] {
       height: 54px;
       background-color: #f5f5f5;
       border-radius: 10px;
    }
    .submitButtonWrapper {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .submitButton {
        outline: none;
        background: @color-primary;
        width: 7.3em;
        padding: 0;
        border-radius: 0 10px 10px 0;
        // transition: .2s box-shadow @ease-quint;
        
        &:hover,&:focus {
            // box-shadow: 0 0 0 5px fade(@color-primary, 20%);
        }
    }
    div[data-cached-form-url] button.submitButton {
        min-width: 0 !important;
    }

}