/*-----------------------------------------------------------------------------------------
	/Header
-----------------------------------------------------------------------------------------*/
@header-height-mobile: 4em;
@header-height-desktop: 6em;
@header-height-xl: 6.5em;
@header-height-xxl: 8em;

.site-header {
    padding: 0 0 0 1.3em;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: white;
    z-index: 3;
    transition: .5s transform @ease-quint, .3s background-color ease-in-out, .3s box-shadow ease-in-out;

    @media(min-width: 43.75em) {
        padding: 0 2em;
    }

    .nav-up & {
        transform: translateY(-100%);

        @media(min-width: 80em) {
            transform: none;
        }
    }
    .scrolling & {
        box-shadow:
            0 -8px 19px rgba(0,0,0,.2),
             0 1px 0 rgba(0, 0, 0, 0.05);
        background: white !important; //This needs to override any template specificity
    }
    .mainnav-active & {
        transform: none !important;
    }
    .site-search-active & {
        transform: none !important;
    }
}
.site-header__inner {
    height: var(--header-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: .2s height;
    // background: fade(red,50%);

    .scrolling & {
        @media(min-width: 80em) {
            height: 4.5em;
        }
    }


}
.logo {
    position: relative;
    flex: 2;

    @media(min-width: 80em) {
        flex: none;
    }
}
.logo__img {
    transform-origin: 0;
    transition: .2s transform, .2s margin;
    width: 118px;

    @media(min-width: 43.75em) {
        width: auto;
    }
    @media(min-width: 80em) {
        margin-top: -1.1em;
        width: 138px;

        .scrolling & {
            margin-top: 0em;
            transform: scale(0.9);
        }
    }
    @media(min-width: 100em) {
        width: auto;
    }
}



/*-----------------------------------------------------------------------------------------
	/Mobile only buttons
-----------------------------------------------------------------------------------------*/
.site-header__mobile-controls {
    height: 100%;
    display: flex;
}
.site-header__mobile-button {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 0.7em;
    justify-content: center;
    outline: none;
}
.site-header__mobile-button--ph {
    @media(min-width: 43.75em) {
        display: none;
    }
}
.site-header__mobile-button--search {
    @media(min-width: 80em) {
        display: none;
    }
}



/*-----------------------------------------------------------------------------------------
	/Main nav
-----------------------------------------------------------------------------------------*/
.main-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: @color-body;
    transform: translateX(100%);
    transition: .6s transform @ease-quint;
    z-index: 1;
    width: 100%;
    max-width: 31em;

    @media(min-width: 80em) {
        max-width: none;
        width: auto;
        transform: none;
        margin: 0 0 0 1em;
        background: none;
        position: static;
    }
    &.active {
        opacity: 1;
        transform: none;
    }
}
.main-nav-items {
    padding: 6em 1em 2em;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;

    @media(min-width: 80em) {
        position: static;
        overflow: visible;
        padding: 0;
        display: flex;
    }
}
.main-nav-item--level1 {
    position: relative;
    border-bottom: 1px solid fade(white,10%);

    &:last-child {
        border: none;
    }
    @media(min-width: 80em) {
        border: none;
    }
}
.main-nav-link {
    font-size: 1.3em;
    outline: none;
    display: block;
    white-space: nowrap;
    color: white;

    @media(min-width: 80em) {
        .weight-medium;
        font-size: .94em;
        color: inherit;
    }
}
.main-nav-link--level1 {
    padding: 1em 1em 1em 2em;
    transition: .2s padding;
    position: relative;

    @media(min-width: 80em) {
        padding: 3.5em .7em;
    }

    .scrolling & {
        @media(min-width: 80em) {
            padding-top: 2.2em;
            padding-bottom: 1.9em;
        }
    }
    .level1-active & {
        @media not all and (min-width: 80em) {
            color: @color-primary;
        }
    }
}
.main-nav__label {
    position: relative;

    &:after {
        height: 2px;
        background: @color-body;
        content: "";
        position: absolute;
        left: 0;
        width: 0%;
        bottom: -0.3em;
        transition: .2s width @ease-quint, .2s opacity;
        opacity: 0;
    }
}
.main-nav-link--level1:hover .main-nav__label:after,
.main-nav-link--level1:focus .main-nav__label:after,
.level1-active .main-nav__label:after {
    width: 100%;
    opacity: 1;
}
.icon--dropdown {
    width: 10px;
    height: 6px;
    background-position: -300px 0;
}
.button--mainnav-level2-toggle {
    width: 4.3em;
    height: 4.8em;
    outline: none;
    position: absolute;
    left: -1em;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media(min-width: 80em) {
        display: none;
    }
}
.icon--level2-toggle {
    background-position: -300px -25px;
    height: 7px;
    width: 11px;

    .button--mainnav-level2-toggle.active & {
        transform: rotate(180deg);
    }
}
.icon--mainnav-dropdown {
    display: none;

    @media(min-width: 80em) {
        display: inline-block;
    }
}
.mainnav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: fade(@color-senary,95%);
    opacity: 0;
    visibility: hidden;
    transition: .8s opacity, .8s visibility;
    z-index: 1;

    @media(min-width: 80em) {
        display: none;
    }
    .mainnav-active & {
        opacity: 1;
        visibility: visible;
    }
}



/*-----------------------------------------------------------------------------------------
	/Main nav level2
-----------------------------------------------------------------------------------------*/
.main-nav-items--level2 {
    transition:
        .6s max-height @ease-quint,
        .6s padding @ease-quint,
        .2s top, .1s visibility .2s,
        .2s opacity .2s,
        .2s transform .2s;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    padding-left: 1.4em;

    @media(min-width: 80em) {
        transform: translateY(10px);
        padding: 2em 0;
        max-height: none;
        min-width: 17em;
        visibility: hidden;
        position: absolute;
        top: 7.3em;
        left: 0.7em;
        background: @color-body;
        border-radius: 0 0 8px 8px;
    }
    @media(min-width: 100em) {
        top: 8.1em;
    }

    .scrolling & {
        @media(min-width: 80em) {
            top: 5em;
        }
    }
    &.active {
        max-height: 500px;
        opacity: 1;
        padding-bottom: 2em;
    }

    .main-nav-item--level1:hover &,
    .main-nav-item--level1:focus & {
        @media(min-width: 80em) {
            visibility: visible;
            opacity: 1;
            transform: none;
        }
    }
    .main-nav-item--level1:focus-within & {
        @media(min-width: 80em) {
            transform: none;
            visibility: visible;
            opacity: 1;
            max-height: none;
        }
    }
}
.mainnav-item--level2 {
    page-break-inside: avoid;
}
.mainnav-link--level2 {
    color: white;
    display: block;
    padding: 0.3em 1.3em;
    // white-space: nowrap;
    transition: .15s color;
    outline: none;
    font-size: 1em;

    @media(min-width: 80em) {
        font-size: .94em;
        padding: 0.5em 2.5em;
        white-space: nowrap;
    }

    &:hover,&:focus {
        color: @color-primary;
    }
    .level2-active & {
        color: @color-primary;
    }
}

.main-nav-items--level2__blurb {
    padding: 0 1.2em 2em;

    @media(min-width: 80em) {
        padding: 0;
    }
}


/*-----------------------------------------------------------------------------------------
	/Main nav toggles
-----------------------------------------------------------------------------------------*/
.site-header__mobile-button--mainnav {
    position: relative;
    padding-right: 1.4em;

    @media(min-width: 43.75em) {
        padding-right: 2em;
    }
    @media(min-width: 80em) {
        display: none;
    }
}
.icon--mainnav-open {
    width: 25px;
    height: 16px;
    background-position: -350px -150px;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // margin: -8px 0 0 -12.5px;
}
.site-header__mobile-button--mainnav-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    height: 4em;
    padding-right: 1.5em;
    outline: none;

    @media(min-width: 43.75em) {
        left: 2.6em;
        right: auto;
        top: 1em;
    }
    @media(min-width: 80em) {
        display: none;
    }
}
.icon--mainnav-close {
    width: 20px;
    height: 20px;
    background-position: -250px -150px;
}



/*-----------------------------------------------------------------------------------------
	/Level 2 blurbs
-----------------------------------------------------------------------------------------*/
.main-nav-items--level2--cols {
    @media(min-width: 80em) {
        display: flex;
        padding-right: 2em;
        // visibility: visible;
        // opacity: 1;

        min-width: 31em; //IE 10/11 collapses otherwise

        p {
            font-size: .88em;
        }
    }
}
.main-nav-items--level2--cols--xl { // Regions variant
    @media(min-width: 80em) {
        min-width: 48rem;
    }
    .mainnav-link--level2 {
        padding-right: 0;
    }
    &.main-nav-items--level2.active {
        max-height: 800px;
    }
    .main-nav-items--level2__list {
        columns: 2 11rem;
        column-gap: 0;
        @media(min-width: 43.75em) {
        }
    }
    .main-nav-items--level2__blurb {
        padding-bottom: 0;
    }
}
.link--cta--mainnav-regions {
    padding-left: 1.3em;
    margin-top: 1.5rem;
}
.main-nav-items--level2__blurb {
    @media(min-width: 80em) {
        width: 18em;
        padding: 0 2em 0.9em 3em;
        border-right: 2px solid fade(white,10%);
    }
    a {
        color: white;
    }
}
.main-nav-heading {
    display: none;

    @media(min-width: 80em) {
        display: block;
        font-weight: 400;
        font-size: 1.29em;
        line-height: 1.5;
        margin-bottom: 0.6em;
    }
}



/*-----------------------------------------------------------------------------------------
	/Mobile only contact
-----------------------------------------------------------------------------------------*/
.main-nav__mobile-contact {
    background: linear-gradient(45deg,#F6921E,#FAA61A);
    padding: 1.1em 2em;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    position: relative;
    color: @color-body;

    @media(min-width: 43.75em) {
        display: none;
    }
}


/*-----------------------------------------------------------------------------------------
	/Site search
-----------------------------------------------------------------------------------------*/
.site-search {
    flex: 2;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: @color-senary;
    transition: .3s opacity;
    visibility: hidden;
    opacity: 0;
    z-index: 1;


    @media(min-width: 43.75em) {
        padding: 7em;
    }
    @media(min-width: 80em) {
        transition: none;
        background: none;
        margin: 0 2em 0 1em;
        padding: 0;
        position: relative;
        height: 100%;
        visibility: visible;
        opacity: 1;
        display: block;
    }
    @media(min-width: 82em) {
        margin-left: 2em;
    }
    &.active {
        visibility: visible;
        opacity: 1;
    }
}
.button--site-search {
    width: 44px;
    height: 44px;
    position: absolute;
    z-index: 3;
    left: 0.7em;
    top: 0.6em;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .2s;
    
    &:hover,
    &:focus {
        opacity: 0.8;
    }

    @media(min-width: 43.75em) {
        left: 6.4em;
        top: 7em;
    }
    @media(min-width: 80em) {
        left: -14px;
        margin-top: -22px;
        top: 50%;
    }
}
.icon--site-search {
    background-position: -250px 0;
    width: 22px;
    height: 22px;
}
.site-search__input {
    border: none;
    outline: none;
    padding: .8em 2em 1em 3em;
    width: 100%;
    background: transparent;
    color: @color-body;
    font-size: 1.2em;

    @media(min-width: 43.75em) {
        border-bottom: 2px solid @color-body;
        font-size: 1.6em;
        padding: 0 2em 0.8em 1.4em;
    }
    @media(min-width: 80em) {
        border-bottom: none;
        padding: 2em 0 2em 2em;
        font-size: .94em;
        height: 100%;
    }
}
.autocomplete-search-options.autocomplete-search-options--header,
.site-search-search-options {
    border-radius: 0 0 10px 10px;
    margin-top: 22px;
    width: 100%;
    z-index: 3;

    @media not all and (min-width: 43.75em) {
        max-height: 15em;
    }
    @media(min-width: 80em) {
        width: 25em;
    }

    .scrolling & {
        @media(min-width: 80em) {
            top: 3.65em;
        }
    }
    .autocomplete-search-option__link {
        display: flex;
    }
    .autocomplete-search-option--no-results {
    	padding: 1em;
        font-size: .88em;
    }
}
.button--site-search-close {
    height: 62px;
    width: 72px;
    position: absolute;
    top: 0;
    right: 0;

    @media(min-width: 80em) {
        display: none;
    }
}
.icon--site-search-close {
    background-position: -250px -175px;
    width: 20px;
    height: 20px;
}




/*-----------------------------------------------------------------------------------------
	/Header CTA area
-----------------------------------------------------------------------------------------*/
.header-cta {
    display: none;

    @media(min-width: 43.75em) {
        padding: 0 2em;
        margin-right: -2em;
        width: 13em;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        white-space: nowrap;
    }
    @media(min-width: 100em) {
        width: 15em;
        padding: 0 3em;
        margin-right: -3em;
    }
}
.header-cta-link {
    // color: white;
    .weight-medium;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    display: flex;
    align-items: center;
    padding: .1em 0;
    position: relative;
    cursor: default;
    outline: none;
}
.icon--header-ph {
    width: 23px;
    height: 23px;
    background-position: -50px -75px;
}
.icon--header-ph--mobile {
    width: 25px;
    height: 25px;
    background-position: -300px -150px;
}
.icon--header-contact {
    width: 23px;
    height: 14px;
    background-position: -500px -250px;
    border-radius: 20em;
}

.header-cta-link--contact {
    cursor: pointer;

    &:after {
        height: 2px;
        content: "";
        position: absolute;
        bottom: 0;
        // background: white;
        background-color: @color-body;
        right: .1em;
        opacity: 0;
        transition: .2s width @ease-quint, .2s opacity;
        width: 0;
    }
    &:hover:after, &:focus:after {
        opacity: 1;
        width: 7.2em;
    }
}



/*-----------------------------------------------------------------------------------------
	/Header CTA decoration  - ALL OF THIS SHIT IS WILDLY HACKY.  Proceed with caution
-----------------------------------------------------------------------------------------*/
.site-header-dec-wrap {
    display: none;
    @media(min-width: 62.5em) {
        position: relative;
        display: block;
    }
    @media(min-width: 1483px) {
        max-width: 1400px;
    }
}
.site-header-dec,
.site-main:after,
.header-cta:after,
.site-header:after {
	@media(min-width: 43.75em) {
        content: "";
    	width: 100%;
    	height: 8em;
    	position: absolute;
    	top: 0;
    	right: 0;
        z-index: -1;
        pointer-events: none;
    }
}
.header-cta:after {
    z-index: -1;
    height: 100%;
}
.site-main:after {
	margin-right: -700px;
	width: 50%;
    // background-position: center;
}
.site-header:after {
	margin-right: -700px;
	width: 50%;
    height: 100%;
}
.site-header-dec,
.site-main:after {
    background-image: linear-gradient(0deg,#F6921E,#f89a1b);
    background-repeat: no-repeat;
}
.site-header-dec {
    width: 13em;

    @media(min-width: 1483px) {
        width: 11em;
    }
    @media(min-width: 100em) {
        width: 12em;
    }
}
.header-cta:after,
.site-header:after {
    background: linear-gradient(0deg,#f7991b,#faa61a);
}
