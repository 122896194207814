@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=52b0d851-d02e-4b6c-b16f-15722cda9cb8");
@font-face{
    font-family:"Sailec W05";
    src:url("/fonts/22771bcc-8fbd-411f-b730-9a43197afb95.woff2") format("woff2"),url("/fonts/0610e07b-93ea-4f1f-b469-1a2a02398b58.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family:"Sailec W05";
    src:url("/fonts/0e5908e8-d404-4ed1-ac41-dc71e7bff268.woff2") format("woff2"),url("/fonts/ac673c30-d8bd-4758-91e0-86a36ba24abc.woff") format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}
@font-face{
    font-family:"Sailec W05";
    src:url("/fonts/a1b501c6-02c3-4270-abed-64bb4b8ce257.woff2") format("woff2"),url("/fonts/a3f39c3c-0827-44ea-b173-27ac5ba80d83.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family:"Sailec W05";
    src:url("/fonts/edec1a94-e2a6-4e33-ada9-743e2b241fbb.woff2") format("woff2"),url("/fonts/aa19e7c7-176b-4241-b77b-aeab4fa42f7e.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
