/*-----------------------------------------------------------------------------------------
	/Home
-----------------------------------------------------------------------------------------*/
.heading--page--home {
    max-width: 7em;
    font-size: 2.8em;

    @media(min-width: 43.75em) {
        font-size: 3.82em;
    }
}
.heading--block--home-hero {
    @media(min-width: 43.75em) {
        font-size: 1.8em;
    }
}
.home-hero-image {
    margin-bottom: -10em;
    position: relative;

    @media(min-width: 43.75em) {
        margin-bottom: -14em;
    }
    @media(min-width: 62.5em) {
        margin-bottom: 0;
    }
}
.home-hero-image__img {
    height: 24em;

    @media(min-width: 43.75em) {
        height: 35em;
    }
    @media(min-width: 62.5em) {
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        left: -20em;
        height: auto;
    }

    &:before {
        content: "";
        position: absolute;
        top: 0; right: 0; bottom: 0; left: 0;
        background: linear-gradient(0, #f1e9e5, fade(@color-senary,0%) 50%);

        @media(min-width: 62.5em) {
            background: linear-gradient(90deg, #f1e9e5, fade(@color-senary,0%) 30em);
        }
    }
}
.section--home-hero {
    position: relative;
    background:  @color-senary;

    @media(min-width: 62.5em) {
        background: none;

        &:before {
            content: "";
            position: absolute;
            top: 0; right: 0; bottom: 0; left: 0;
            background: linear-gradient(90deg, @color-senary 50%, fade(black,0%) 50%);
            z-index: -1;
        }
    }
}
.section--home-hero__cols {
    @media(min-width: 62.5em) {
        min-height: 75vh;
    }
}
.section--home-hero__type {
    position: relative;
    z-index: 1;
    order: 2;
    max-width: 41em;

    @media(min-width: 62.5em) {
        order: unset;
        padding-right: 0;
    }
    @media(min-width: 100em) {
        padding-left: 0;
    }
}



.section--home-hero--no-gradient {
    &:before {
        display: none;
    }
    .home-hero-image {
        margin-bottom: 0;
    }
    .home-hero-image__img {
        @media (min-width: 62.5em) {
            left: -71.4%;
        }
    }
    .home-hero-image__img:before {
        background: 0;
    }
    .section--home-hero__type {
        @media (min-width: 100em) {
            padding-left: 6rem;
        }
    }
}
.section--home-hero--light-text {
    .section--home-hero__type {
        @media (min-width: 62.5em) {
            color: #fff;
            .link {
                filter: brightness(0) invert(1);
            }
            .link ~ .icon {
                filter: brightness(0) invert(1);
            }
        }
    }
}

.section--home-hero:not(.section--home-hero--no-gradient).section--home-hero--light-text {
    &:before {
        @media (min-width: 62.5em) {
            background: linear-gradient(90deg, @color-body 50%, fade(@color-body,0%) 50%);
        }
    }
    .home-hero-image__img:before {
        @media (min-width: 62.5em) {
            background: linear-gradient(90deg, @color-body, fade(@color-body,0%) 30em);
        }
    }
}

.section--home-hero--no-gradient.section--home-hero--light-text {
    .section--home-hero__type {
        @media (min-width: 62.5em) {
            position: relative;
            &::before {
                display: block;
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                height: 100%;
                width: 100%;
                z-index: -1;
                background: linear-gradient(90deg, fade(black,20%), fade(black,0%));
            }
        }
    }
}

.home-notification-important-wrap {
    max-height: 5.3em;
    transition: .3s max-height @ease-quint;
    position: relative;
    z-index: 1;
    padding-right: 1.3em;
    padding-left: 1.3em;
    @media (min-width: 43.75em){
        padding-right: 2em;
        padding-left: 2em;
    }

    &.inactive {
        max-height: 0;
        overflow: hidden;
    }
}

.home-notification-important {
    padding: 0.7em 3.2em .7em 0;
    position: relative;
}
.home-notification-important__label {
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    margin-right: 1em;
}
.home-notification-important__link {
    vertical-align: middle;
    display: inline-block;
}
.button__home-notification-close {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 3em;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.icon--home-notification-close {
    width: 12px;
    height: 12px;
    background-position: -150px -75px;
}
.home-notification-update {
    background: linear-gradient(0deg,#f7991b,#faa61a);
    padding-top: 1.8em;
    padding-bottom: 1.8em;
    display: block;

    @media(min-width: 87.5em) {
        margin-top: -3em;
        position: relative;
    }
}
.home-notification-update__label {
    @media(min-width: 43.75em) {
        white-space: nowrap;
        max-width: 70%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}



/*-----------------------------------------------------------------------------------------
	/Gradient backdrop template
-----------------------------------------------------------------------------------------*/
.template--gradient {
    // background: linear-gradient(#f1e9e5 38em, white 60em) no-repeat;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        height: 100vh;
        right: 0;
        left: 0;
        background: linear-gradient(#f1e9e5 6em,#fff) no-repeat;
        z-index: -1;
        pointer-events: none;
    }

    .site-header {
        background-color: #f1e9e5;
    }
    &.scrolling .site-header {
        background-color: white;
    }
}
.template--gradient-overlay {
    .site-main-dec-wrap {
    	position: relative;
        z-index: -1;
    }
    .site-main-dec {
        position: absolute;
        content: "";
        left: -100vw;
        right: 0;
        top: 0;
        background: linear-gradient(#f1e9e5 22em, fade(#f1e9e5,0%) 50em) no-repeat;
        height: 100em;
        pointer-events: none;
    }
    .site-main-dec--pattern {
        position: absolute;
        content: "";
        left: -100vw;
        right: 0;
        top: 0;
        height: 100em;
        pointer-events: none;
        background-attachment: fixed;
        background-position: center;
    }
}
.template--header-filled {
    .site-header {
        background-color: #f1e9e5;
    }
}





/*-----------------------------------------------------------------------------------------
	/Why Arvida
-----------------------------------------------------------------------------------------*/
.mbhs-matrix-item {
    padding: 0 0 2em;
    margin-bottom: 2em;
    border-bottom: 1px solid @color-body;

    &:nth-child(4) {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    @media(min-width: 43.75em) {
        margin-bottom: 0;
        padding: 3em;
        border-right: 1px solid @color-body;

        &:nth-child(2n) {
            border-right: none;
        }
        &:nth-child(3) {
            border-bottom: none;
        }
    }
    @media(min-width: 62.5em) {
        display: flex;
    }


}
.mbhs-matrix-item__heading {
    width: 4em;
}
.mbhs-matrix-item__copy {
    flex: 1;
}



/*-----------------------------------------------------------------------------------------
	/Living with Arvida
-----------------------------------------------------------------------------------------*/
.location-map {
    position: relative;
    img {
        display: inline-block;
        margin-bottom: 1em;
    }
    &:after {
        .bg-senary;
        position: absolute;
        content: "";
        height: 100%;
        width: 200%;
        left: -50%;
        top: 0;
        z-index: -1;

        @media(min-width: 62.5em) {
            display: none;
        }
    }
}
.location-map__inner {
    text-align: center;
    padding-top: 2em;
    position: relative;

    @media(min-width: 62.5em) {
        padding-left: 2em;
        padding-right: 2em;
        text-align: right;
    }
}
.location-map__heading {
    text-align: left;
    margin-bottom: 1em;

    @media not all and (min-width: 43.75em) {
        font-size: 1.3em;
    }
    @media (min-width: 31.25em) {
        position: absolute;
        top: 2em;
        left: 0;
        max-width: 10em;
    }
    @media (min-width: 62.5em) {
        left: 2em;
    }
    @media(min-width: 100em) {
        top: 3em;
        left: 3em;
    }
}
.location-map__button {
    @media (min-width: 31.25em) {
        position: absolute;
        right: 0;
        bottom: 0;
    }
}
.living-article-hero-area {
    min-height: 43em;
}
.living-article-hero-area__img {
    order: 2;

    @media(min-width: 62.5em) {
        order: unset;
    }
}
.panel-overlap--living-communities {
    @media not all and (min-width: 62.5em) {
        display: none;
    }
}
.card-items--overflow-scroll .card-item--living-cta {
    // flex: 0 0 60%;
}
.living-option-tab-copy {
    @media not all and (min-width: 62.5em) {
        padding: 2em 0 0;
    }
}


/*-----------------------------------------------------------------------------------------
	/Community list view
-----------------------------------------------------------------------------------------*/
.card-item--community {
    .card-link {
        height: auto;
    }
}


/*-----------------------------------------------------------------------------------------
	/Community list map
-----------------------------------------------------------------------------------------*/
.community-map {
    height: 70vh;
    margin: 0 -2em;
    background: #b7d4ee;
    outline: none;

    @media(min-width: 43.75em) {
        margin: 0 -4em;
    }
    @media(min-width: 62.5em) {
        margin-left: -15vw;
        margin-right: 0;
    }
}
.gm-style {
    outline: none;
}
.map-button-active {
    color: white;
    .bg-gradient-purple;
    border-color: transparent;

    .icon--marker--dark {
        background-position: -300px -50px;
    }
}
.community-map-wrap {
    position: relative;
}
.community-map__zoom {
    position: absolute;
    bottom: 2.5em;
    right: 0;
    z-index: 1;

    @media(min-width: 43.75em) {
        right: -2em;
    }
    @media(min-width: 62.5em) {
        right: 2em;
    }
}
.button--map-zoom {
    width: 34px;
    height: 34px;
    background: white;
    border-radius: 100%;
    display: block;
    background-position: -50px -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5em;
    box-shadow: 0 2px 6px rgba(25, 92, 153, 0.29), ;
    outline: none;
    transition: .15s box-shadow;

    &:hover, &:focus {
        box-shadow: 0 2px 6px rgba(25, 92, 153, 0.29), 0 0 0 1px @color-body;
    }
}
.icon--zoom-in {
    background-position: -200px -50px;
    width: 12px;
    height: 12px;
}
.icon--zoom-out {
    background-position: -200px -75px;
    width: 12px;
    height: 2px;
}
.infowindow-detail {
    padding: 2em 2.7em;
}
.map-infowindow {
    width: 100%;

    @media(min-width: 43.75em) {
        width: 36em;
    }

    .card-icons {
        margin: 2em 0 0;
    }
}
.map-infowindow__img {
    @media(min-width: 43.75em) {
        padding-bottom: 0;
    }
}




/*-----------------------------------------------------------------------------------------
	/Community - detail
-----------------------------------------------------------------------------------------*/
.template--community-detail {

    .intercom-lightweight-app-launcher { //Intercom filth
        transition: .3s bottom ease-in-out;
    }
    &.scrolling .intercom-lightweight-app-launcher { //Intercom filth
    	bottom: 5.4em;
    }
    &.scrolling .intercom-launcher-frame { //Intercom filth
    	bottom: 5.4em;
    }
}
.community__postal-address{
    margin-bottom: 1.5em;
    p a[href^="tel:"]{
        color: @color-secondary;
    }
}
.section--community-lifestyle {
    .grid-row {
        gap: 0.3125rem;
        @media (min-width: 80em) {
            gap: 0.625rem;
        }
    }
    .grid-col {
        width: auto;
        flex: 1;
    }
}





/*-----------------------------------------------------------------------------------------
	/Community - detail basic
-----------------------------------------------------------------------------------------*/
.template--community-detail-basic {

    .featured-event-cta{
        margin-top: 5px;
    }
    .featured-event-cta__inner{
        padding-left: 2em;
        padding-right: 2em;

    }
}



/*-----------------------------------------------------------------------------------------
	/Community detail - premium
-----------------------------------------------------------------------------------------*/
.community-hero-premium {
    padding-top: 36vh;
    min-height: 30em;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 1;
    min-height: calc(var(--viewport-height, 100vh) - var(--header-height) - var(--cta-bar-height));

    @media(min-width: 43.75em) {
        padding-top: 0;
        height: calc(95vh - @header-height-desktop);
    }
    @media(min-width: 62.5em) {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
    }
    @media(min-width: 80em) {
        height: calc(var(--viewport-height, 100vh) - var(--header-height) - var(--cta-bar-height));
        padding-bottom: 5em;
    }
    @media(min-width: 100em) {
        padding-bottom: 7em;
    }

    &::after {
        content: "";
        position: absolute;
        height: 100%;
        bottom: 0;
        right: 0;
        left: 0;
        // background: linear-gradient(0deg,rgba(24, 26, 29, 0.76) 10em ,rgba(0,0,0,0));
        background: linear-gradient(6.32deg, rgba(22, 23, 26, 0.6) 10.98%, rgba(27, 29, 32, 0) 64.14%);
        

        @media (min-width: 43.75em) {
            top: 0;
            height: auto;
            // background: linear-gradient(0deg,rgba(24, 26, 29, 0.76) 5em ,rgba(0,0,0,0) 38em);
            background: linear-gradient(25.62deg, rgba(22, 23, 26, 0.8) 17.71%, rgba(27, 29, 32, 0) 53.31%);
        }
    }
    .button-bar {
        z-index: 1;
        position: relative;
        margin-top: 2em;
    }
}
.community-hero-premium__img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: @color-body;
}
.community-hero-premium__type {
    color: white;
    position: relative;
    z-index: 1;
    .headings--page:last-child {
        margin-bottom: 0;
    }
}
.site-main a[href^="tel:"].community-hero-premium__phone {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    color: white;
    font-size: 1.25rem;
    font-weight: normal;
    transition: .15s border-color,.15s color,.15s box-shadow,.15s -webkit-box-shadow;
    > span {
        transition: inherit;
        border-bottom: 2px solid transparent;
    }
    .icon {
        filter: brightness(0) invert(1);
    }
    &:focus, &:hover {
        outline: 0;
        color: white;
        > span {
            border-color: white;
            box-shadow: 0 4px 0 fade(white, 25%);
        }
    }
    @media (min-width: 43.75em) {
        font-size: 1.375rem;
    }
}


/*-----------------------------------------------------------------------------------------
	/Community detail - global
-----------------------------------------------------------------------------------------*/
.event-calendar__name {
    width: 22em;
}
.event-calendar__date {
    color: fade(@color-body,60%);
    font-weight: normal;
    font-size: .88em;
}
.event-calendar__blurb {
    flex-grow: 1;
}
.event-calendar__blurb__inner {
    max-width: 28em;
}
.event-calendar__cta {
    width: 8.2em;
}
.community-why-cta {
    text-align: center;
    max-width: 33em;
    padding: 8em 3em 4em;
    margin: -5em auto 0;
}
.more-info-items {
    margin-top: 2em;
    margin-bottom: 3em;
}
.more-info-link {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    outline: none;

    &:hover,&:focus {
        color: @color-secondary;
    }
}
.section--community-care__hero-col {
    order: 2;
    margin-top: 4rem;

    @media(min-width: 62.5em) {
        order: unset;
        margin-top: 0;
    }
}
.section--community-care__detail-col {
    @media(min-width: 62.5em) {
        order: 2;
    }
}
.personalised-health-meta__item {
    display: flex;
    margin-bottom: 1.3em;

    .icon {
        margin-top: -.1em;
    }
    &:last-child {
        margin-bottom: 0;
    }
}
.personalised-health-meta {
    margin-top: 1.6rem;
}
.personalised-health-meta__icon-wrap {
    width: 2rem;
    text-align: center;
}
.personalised-health-meta__label__info {
    display: block;
    font-weight: normal;

    @media(min-width: 43.75em) {
        display: inline;
    }
}
.personalised-health-meta__divider {
    margin: 0 0.4rem;
}
.personalised-health-meta__label {
    flex: 1;
    padding-left: 0.4em;
}
.panel-overlap--community-update,
.panel-overlap--grid--community-accom {
    @media not all and (min-width: 43.75em) {
        display: none !important; //Overrides Mr Xue's inline display:none
    }
}
.section--community-accom-inner {
    @media not all and (min-width: 43.75em) {
        padding-bottom: 0;
    }
}
.section--community-featured-event__hero {
    margin-bottom: 2em;
}
.section--community-accom__gallery {
    margin-top: 3rem;

    @media (min-width: 62.5em) {
        margin-top: 0;
    }
}
.community-mobile-view-links-wrap {
    + .featured-event-cta {
        margin-top: -1px;
    }
}
.community-mobile-view-links {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 24px;
    padding: 0 0 2rem;
    margin: 2rem 0 0;
    border-bottom: 1px solid fade(@color-body, 10%);
    
    @media (min-width: 23.4375em) {
        gap: 32px;
    }
    
    @media (min-width: 62.5em) {
        display: none;
    }

    .button--with-icon {
        background: 0;
        display: flex;
        gap: 8px;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        font-size: 0.9375rem;
        font-weight: 500;
        text-align: left;
        line-height: 1.5;
        white-space: nowrap;
        
        &:is(:hover, :focus) {
            .icon-circle {
                border-color: @color-body;
            }
        }
    }
    
    .button--with-icon > div {
        display: grid;
    }
    
    .icon-circle {
        width: 40px;
        height: 40px;
        display: grid;
        place-items: center;
        border-radius: 50%;
        border: 1px solid fade(@color-body, 10%);
        flex-shrink: 0;
    }
    
    .eyebrow {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        opacity: 0.6;
        letter-spacing: 0.04em;
    }
}


/*-----------------------------------------------------------------------------------------
	/Community basic
-----------------------------------------------------------------------------------------*/
.community-basic-hero {
    @media not all and (min-width: 62.5em) {
        &:has(.community-mobile-view-links) {
            padding-bottom: 0;
        }
    }
    @media(min-width: 62.5em) {
        border-bottom: 1px solid @color-body;
        align-items: flex-start;
        justify-content: space-between;
    }
}
.community-basic-hero__address {
    @media(min-width: 62.5em) {
        text-align: right;
    }
    .heading--block {
        margin: 0.6em 0 0.2em;
    }
}
.image-grid--community-basic {
    column-gap: 5px;
    row-gap: 5px;
    grid-template-columns: 6fr 6fr;

    @media(min-width: 62.5em) {
        grid-template-columns: 16fr 9fr;
        height: auto;
    }

	.image-grid__img:first-child {
		grid-column-start: 1;
        grid-column-end: 3;
		grid-row-start: 1;

        @media(min-width: 62.5em) {
            grid-column-end: 1;
            grid-row-end: 3;
        }
	}
	.image-grid__img:nth-child(2) {
		@media not all and (min-width: 62.5em) {
            grid-column-start: 1;
            grid-row-start: 2;
        }
	}
	.image-grid__img:nth-child(3) {
		@media not all and (min-width: 62.5em) {
            grid-column-start: 2;
            grid-row-start: 2;
        }
	}
}
.image-grid--community-basic__gallery-button {
    position: absolute;
    width: auto;
    bottom: 2em;
    right: 2em;
}
.site-main a[href^="tel:"].community-basic-hero__phone {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    color: @color-body;
    font-size: 1.25rem;
    font-weight: normal;
    transition: .15s border-color,.15s color,.15s box-shadow,.15s -webkit-box-shadow;
    > span {
        transition: inherit;
        border-bottom: 2px solid transparent;
    }
    &:focus, &:hover {
        outline: 0;
        color: @color-secondary;
        > span {
            border-color: @color-secondary;
            box-shadow: 0 4px 0 rgb(144 64 152 / 25%);
        }
    }
    @media (min-width: 43.75em) {
        font-size: 1.375rem;
    }
}


/*-----------------------------------------------------------------------------------------
	/Community detail - Featured event CTA
-----------------------------------------------------------------------------------------*/
.featured-event-cta {
    .bg-gradient-purple;
    color: #fff;
    display: block;
    padding: 1.25rem 0;
    transition: box-shadow .3s @ease-quint;
    &:hover,
    &:focus {
        box-shadow: 0 0 0 5px fade(@color-secondary,20%);
    }
}
.featured-event-cta__inner {
    @media (min-width: 31.25em) {
        display: flex;
        justify-content: space-between;
    }
}
.featured-event-cta__heading {
    span {
        margin: 0 0.5em 0 0;
    }
}
.featured-event-cta__cta {
    display: flex;
    align-items: center;
    white-space: nowrap;
    @media (min-width: 31.25em) {
        padding-left: 2em;
    }
    svg {
        margin-left: 0.4375rem;
    }
}


/*-----------------------------------------------------------------------------------------
	/Community detail - CTA bar
-----------------------------------------------------------------------------------------*/
.community-cta-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    height: 75px;
    padding: .7em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 8px 19px rgba(0,0,0,.2), 0 -1px 0 rgba(0, 0, 0, 0.05);
    z-index: 2;
    // transform: translateY(100%);
    transition: .3s transform ease-in-out;

    .scrolling & {
        transform: none;
    }
    &.inactive {
        transform: translateY(100%);
    }
    
    @media (min-width: 40em) {
        padding-left: 1.8em;
    }
    
    @media (min-width: 50em) {
        padding-inline: 4em;
    }
    
    @media (min-width: 87.5em) {
        padding-inline: 6em;
    }
    
    @media not all and (min-width: 43.75em) {
        .button-bar .button {
            padding: 1.3em 1rem;
            font-size: 0.9375rem;
        }
    }
}
.community-cta-bar__contextual {
    display: grid;
    grid: [stack] 1fr / [stack] 1fr;
    align-items: center;

    > * {
      grid-area: stack;
      transition: 0.15s opacity, 0.15s visibility;
    }
}
.community-cta-bar__links {
    display: none;
    @media (min-width: 62.5em) {
        display: flex;
        gap: 20px;
        align-items: center;
        margin: 0;
        padding: 0;
        
        .scrolling & {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }
}
.community-cta-bar__detail {
    display: flex;
    align-items: baseline;
    @media (min-width: 62.5em) {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        
        .scrolling & {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }
    }   
}
.community-cta-bar__heading {
    display: none;

    @media(min-width: 80em) {
        display: block;
        font-size: 1.3em;
        font-weight: normal;
        margin-right: 1em;
    }
}
.community-cta-bar__contact-mobile {
    display: none;

    @media(min-width: 40em) {
        display: inline-block;
        white-space: nowrap;
    }
    @media(min-width: 50em) {
        display: none;
    }
}
.community-cta-bar__contacts {
    font-size: .88em;
    display: flex;
}
.community-cta-bar__contact {
    display: none;

    @media(min-width: 50em) {
        display: block;
        margin-right: 1em;
    }
    
    @media (min-width: 800px) and (max-width: 999px) {
        a {
            display: block;
        }
    }
}
.community-cta-bar__button-bar {
    width: 100%;

    @media(min-width: 40em) {
        width: auto;
    }
    .button {
        width: 50%;

        @media(min-width: 40em) {
            width: auto;
        }
    }
}




/*-----------------------------------------------------------------------------------------
	/Info Centre
-----------------------------------------------------------------------------------------*/
.template--infocentre {
    .pagination-page.active {
        color: @color-quaternary;
        box-shadow: 0 4px 0 @color-quaternary;
    }
}
.section--cta__infocentre-form {
    max-width: 28em;
    position: relative;
}
.section--cta__infocentre-input {
    padding: .8em 1.2em .8em 2.8em;
    border-radius: 10px;
    width: 100%;
    border: none;
    color: @color-body;
    outline: none;
    transition: .2s box-shadow;

    @media not all and (min-width: 32.5em) {
        padding: 1.1em 1.2em 1.1em 3.5em;
        font-size: 1.2em;
    }

    &:focus {
        box-shadow: 0 20px 20px fade(black, 20%);
    }
}
.section--cta__infocentre-submit {
    left: 1.9em;
    top: 50%;
    margin-top: -13.5px;
    position: absolute;
}
.infocentre-cat-label {
    color: @color-quaternary;
}
.cms-blocks--infocentre {
    .cms-block {
        margin-left: 0;
    }
}
.infocentre-article-intro {
    @media(min-width: 43.75em) {
        margin-top: -2em;
    }
}
.infocentre-hero-cats {
    margin-top: 2em;
}
.infocentre-return-link {
    margin-top: 1.3em;
}




/*-----------------------------------------------------------------------------------------
	/Good Friends
-----------------------------------------------------------------------------------------*/
.template--good-friends {
    .site-main .link {
        color: @color-gf-teal;
        border-color: @color-gf-teal;

        &:hover,&:focus {
            box-shadow:  0 4px 0  fade(@color-gf-teal, 25%);
        }
    }
    .bg-dark .link {
        color: white;
        border-color: fade(white, 20%);

        &:hover,&:focus {
            border-color: white;
        }
    }
}
.gf-hero {
    @media(min-width: 62.5em) {
        height: 53em;
        max-width: none;
    }
    @media(min-width: 87.5em) {
        height: auto;
        max-width: 100%;
    }
}
.bg-gf-pattern {
    background-color: @color-gf-teal;
    background-image: url('/images/bg--gf.svg');
    @media(min-width: 80em) {
        background-attachment: fixed;
    }
}
.bg-gf-pattern--hor { 
    background-repeat: no-repeat;
    background-position: right 74%;
}
.headings--page--with-logo {
    @media(min-width: 43.75em) {
        display: flex;
        align-items: flex-start;
    }
    img {
        margin-top: .2em;

        @media not all and (min-width: 43.75em) {
            width: 5em;
            margin-bottom: 1em;
        }
    }
}
.headings--page--with-logo__headings {
    @media(min-width: 43.75em) {
        flex: 1;
        margin-left: 1.5em;
    }
}


//GF Steps
.gf-steps {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media(min-width: 31.25em) {
        margin: 0 1.5em 0 0;
        align-items: flex-start;
    }

    @media(min-width: 62.5em) {
		flex-direction: row;
	}
}
.gf-step {
    text-align: center;

    @media(min-width: 31.25em) {
        display: flex;
        align-items: center;
        text-align: left;
    }

    @media(min-width: 62.5em) {
        display: block;
        text-align: center;
        max-width: 18em;
	}
}
.gf-step--arrow {
    position: relative;
    flex-grow: 1;
    width: 5px;
    height: 2.5em;
    background: white;
    margin: 1.5em 4.2em;
    border-radius: 20em;

    @media(min-width: 31.25em) {
        margin: 1em 4.2em;
    }

    @media(min-width: 62.5em) {
		margin: 4.2em -3em;
        width: auto;
        height: 5px;
	}

    @media(min-width: 80em) {
		margin: 4.2em -2em;
	}

    &:after {
        content: "";
        .icon;
        position: absolute;
        right: -3px;
        top: 50%;
        width: 37px;
        height: 21px;
        margin-right: -13.5px;
        background-position: -400px -100px;

        @media(min-width: 62.5em) {
            width: 21px;
            height: 37px;
            margin-right: 0;
            margin-top: -18px;
            background-position: -400px -50px;
    	}
    }
}
.gf-step__icon {
    display: flex;
    flex-shrink: 0;
    width: 9em;
    height: 9em;
    border: 8px solid white;
    background: #CFE5EA;
    border-radius: 100%;
    margin: 0 auto 1em;
    box-shadow: 0 5px 15px rgba(4, 35, 43, 0.3);

    @media(min-width: 31.25em) {
        margin: 0 1.5em 0 0;
    }

    @media(min-width: 62.5em) {
		margin: 0 auto 1.5em;
	}
}
.gf-step__label {
    max-width: 23em;
}
.gf-step__icon__img {
    display: block;
    margin: auto;
}
.gf-step__content{
    max-width: 20em;
    margin: 0 auto;

    @media(min-width: 31.25em) {
        // max-width: 20em;
        margin: 0;
    }

    @media(min-width: 62.5em) {
        padding-left: .5em;
        padding-right: .5em;
    }
}





.price-list-feature {
    display: flex;
    margin-bottom: 1em;

    &:last-child {
        margin-bottom: 0;
    }
}
.icon--check--gf {
    width: 20px;
    height: 20px;
    background-position: -125px -50px;
    margin-top: .2em;

    .price-list-feature--none & {
        background: none;
        border: 2px solid fade(@color-body,20%);
        border-radius: 20em;
    }
}
.price-list-pricing {
    margin-bottom: 1em;
    margin-top: -1em;
}
.price-list-price {
    .weight-bold;
    font-size: 2.58em;
    display: inline;
}
.price-list-features {
    border-top: 1px solid @color-senary;
    padding-top: 1.7em;
    margin-top: 1em;
}
.price-list-feature__label {
    flex: 1;
    font-size: .94em;

    .price-list-feature--none & {
        text-decoration: line-through;
        color: fade(@color-body,30%);
    }
}
.gf-stats {
    white-space: nowrap;
    font-size: 3.2vw;
    margin-top: 1.5em;
    margin-bottom: 2em;

    @media(min-width: 31.25em) {
        font-size: 1em;
    }
    @media(min-width: 62.5em) {
        margin-top: 0;
        margin-bottom: 0;
    }
}
.gf-stat {
    display: inline-block;
    color: @color-gf-teal;
    padding-bottom: 0.7em;
    margin: 0 2.5em 0 0;
    border-bottom: 3px solid;
}
.gf-stat__stat {
    .weight-bold;
    margin: 0;
    font-size: 3.2em;
    line-height: 1;
}
.gf-stat__title {
    .weight-medium;
    text-transform: uppercase;
    font-style: normal;
    font-size: .82em;
    letter-spacing: .04em;
}

//Bespoke panel overlaps
.panel-overlap--grid--gf-how {
    height: 36em;
    width: 12%;
}


/*-----------------------------------------------------------------------------------------
	/Investors
-----------------------------------------------------------------------------------------*/
.template--investors { //Theming
    .tab-link:focus, .tab-link:hover {
        background-color: fade(@color-quinary,10%);
        color: @color-quinary;
    }
    .tab-link.active {
        background: @color-quinary;
        color: white;
    }
    .data-item__link:hover,
    .data-item__link:focus {
        color: @color-quinary;
    }
    .tag {
        background: @color-quinary;
    }
}
.link--investors {
    color: @color-quinary;
    border-color: @color-quinary;

    &:hover,&:focus {
        color: @color-quinary;
        border-color: @color-quinary;
        box-shadow: 0 4px 0 fade(@color-quinary,25%);
    }
}
.share-price-cta {
    justify-content: space-between;
    display: flex;
    align-items: baseline;
    margin-top: 1.5em;
    flex-wrap: wrap;

    .link--cta {
        margin-top: .6em;
    }
}
.share-price-cta__figures {
    margin-right: 2em;
}
.share-price {
    .weight-medium;
    display: flex;
    align-items: baseline;
    color: @color-quinary;
}
.share-price--l {
    margin-top: -1.3em;

    @media(min-width: 43.75em) {
        font-size: 1.4em;
    }
    @media(min-width: 62.5em) {
        margin-top: 1.7em;
    }
}
.share-price__figure {
    font-size: 2.7em;

    sup {
        font-size: .5em;
        font-size: .32em;
        margin: 1em 0 0 0.4em;
        float: right;
        .weight-medium;
    }
}
.share-price__direction {
    .weight-medium;
    font-size: 1.1em;
    display: flex;
    align-items: baseline;
}
.share-price__direction__up {
    margin-left: 0.2em;
    width: 0;
    height: 0;
    border-left: .45em solid transparent;
    border-right: .45em solid transparent;
    border-bottom: .75em solid @color-quinary;
}
.share-price__direction__down {
    margin-left: 0.2em;
    width: 0;
    height: 0;
    border-left: .45em solid transparent;
    border-right: .45em solid transparent;
    border-top: .75em solid @color-quinary;
}
.share-price-date {
    font-size: .85em;
    // color: #999;
    color: fade(@color-body,60%);
    margin-top: -.5em;
}
.investor-governance-grid-intro {
    @media not all and (min-width: 80em) {
        order: 2;
    }
}

.section-hero-img{
    margin-top: 1.5rem;

    @media(min-width: 62.5em) {
        margin-top: 0;
    }
}
.investor-double-column{
    .investor-double-column__heading{
        @media(min-width: 43.75em) {
            padding-right: 3rem;
        }
    }
    .investor-double-column__content{
        .wysiwyg {
            table{
                min-width: auto;
            }
            td{
                padding: .9rem .75rem .9rem 0;

                @media(min-width: 43.75em) {
                    padding: .9rem 1.2rem;
                }
            }
        }
    }
}

.investor-download{
    @media(min-width: 80em) {
        padding-bottom: 0;
    }
}

.investor-governance-grid {
    padding-bottom: 2em;

    @media not all and (min-width: 80em) {
        order: 1;
    }
    @media(min-width: 80em) {
        padding-bottom: 0;
    }
}
.image-strip-dec {
    justify-content: center;
    @media(min-width: 80em) {
        max-width: 86em;
    }
}
.investor-report-highlights {
    margin-top: 3.5em; //Offsets highlight decoration padding
}
.investor-report-highlight-col {

    @media not all and (min-width: 62.5em) {
        margin-bottom: 1em;
    }

    // & + .investor-report-highlight-col{
    //     margin-top: .5em;

    //     @media(min-width: 62.5em) {
    //         margin-top: 0;
    //     }
    // }

    & + .investor-report-highlight-col + .investor-report-highlight-col{

        @media(min-width: 62.5em) {
            margin-top: 1.5em;
        }
    }
}


.investor-caption{
    margin-top: 1rem;
    font-size: .8em;
    color: #FFF;

    @media(min-width: 62.5em) {
        font-size: .9em;
    }

    small{ color: inherit; }
}
.governance-card__qual {
    margin-top: .6em;
    margin-bottom: 1.5em
}
.governance-card__name {
    margin-bottom: .3em;
}
.investor-report-highlight {
    position: relative;
    height: 100%;
    outline: none;
    padding-bottom: 1.5em;

    &:before {
        position: absolute;
        top: -1.5em;
        left: 1.5em;
        right: 0;
        bottom: 0;
        background: #fff;
        content: "";
        transition: .2s box-shadow;
    }
    &:hover:before, &:focus:before {
        box-shadow: 0 37px 20px -20px rgba(0,0,0,.2);
    }
}
.investor-report-highlight__type {
    color: fade(@color-body,50%);
}
.investor-report-highlight__format {
    color: @color-body !important;
    margin-top: 2em;
    display: block;
}
.investor-report-highlight__img {
    position: relative;

    .image-bg {
        box-shadow: 0px 9px 21px rgba(183, 206, 227, 0.6);
    }
}
.investor-report-highlight__detail {
    position: relative;
    padding: 0 1.6em;
    font-size: .9em;

    @media(min-width: 43.75em) {
        font-size: 1em;
        padding: 1.5em 2.8em;
    }
    @media not all and (min-width: 43.75em) {
        .heading--block {
            font-size: 1.1em;
        }
    }
}


//Investors download wysiwyg 
.investor-download-wysiwyg {



    .investor-report-highlight-col {
        @media not all and (min-width: 62.5em){
            margin-bottom: -1em;
            margin-top: .5em;
            @media(min-width: 43.75em) {
                margin-top: 1.5em;
            }
        }
    }

    .investor-report-highlight__detail {
        @media (min-width: 62.5em){
            padding-right: 0;
        }
    }
    
    .card-tag{
        z-index: 1;
    }
    .investor-report-highlight{
        transition: all .2s;
        &.--no-pointers {
            pointer-events: none;
        }
    }
    .panel-overlap--grid {

        display: none;
        @media(min-width: 62.5em) {
            display: block;
        }
    }
}

.investor-download-wysiwyg--investor {
    background-color: @color-tertiary;
    @media(min-width: 62.5em) {
        background-color: transparent;
    }
    .panel-overlap__extend,
    .panel-overlap--grid{
        background-color: @color-tertiary;
    }
    .card-tag{
        background-color: @color-quinary;
    }
    .investor-report-highlight:hover,
    .investor-report-highlight:focus {
        .investor-report-highlight__format {
            color: @color-quinary!important;
        }
    }
    .investor-report-highlight__img {
        position: relative;
    }
}
.investor-download-wysiwyg--sustainability {
    background-color: @color-senary;
    @media(min-width: 62.5em) {
        background-color: transparent;
    }
    .panel-overlap__extend,
    .panel-overlap--grid{
        background-color: @color-senary;
    }
    .card-tag{
        background-color: @color-secondary;
    }
    .investor-report-highlight:hover,
    .investor-report-highlight:focus {
        .investor-report-highlight__format {
            color: @color-secondary!important;
        }
    }
    .investor-report-highlight__img {
        position: relative;
    
        .image-bg {
            box-shadow: 0px 9px 21px fade(@color-senary, 60%);
        }
    }
    mark {
        background-color: #F0E9E5;
        padding: 0.3em;
        color: @color-body;
    }
}

.card-tag--investors {
    background-color: @color-quinary;
}
.card-tag--sustainability {
    background-color: @color-primary;
}

//Subscribe block
.section--cta__investor-subscribe-form {
    max-width: 28em;
}
.section--cta__investor-subscribe-form__fields {
    position: relative;
}
.section--cta__newsletter-input {
    padding: .8em 1.2em;
    border-radius: 10px;
    width: 100%;
    border: none;
    color: @color-body;
    outline: none;
    transition: .2s box-shadow;

    &:focus {
        box-shadow: 0 20px 20px fade(black, 20%);
    }
}
.section--cta__subscribe-submit {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 4.9em;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;

    .icon {
        transition: .2s transform;
    }

    &:hover, &:focus {
        .icon {
            transform: translateX(5px);
        }
    }
}
.key-date-items {
    margin-bottom: -2em;
}
.key-date-item {
    border-top: 2px solid @color-body;
    padding: 2em 0;
}
.meeting-items {
    margin-bottom: -4em;
}
.meeting-item {
    padding: 2em 0 4em;
    border-top: 2px solid @color-body;
}
.meeting-item__link {
    padding: .4em 0;
}
.meeting-item__link__icon-wrap {
    width: 2.1em;
    text-align: center;
}
 
.template--gradient-overlay.template--investors-esg .site-main-dec{
    right: 50%;
}



.esg-iframe-container {
    position: relative;
    height: calc(90vh - @header-height-mobile);
    transition: .2s height;
    // background-color: #EEE8E4;
    
    @media(min-width: 43.75em) {
        height: calc(90vh - @header-height-desktop);
    }
    @media(min-width: 80em) {
        height: calc(95vh - @header-height-xl);
    }
    @media(min-width: 100em) {
        height: calc(95vh - @header-height-xxl);
    }
    .scrolling & {
        @media(min-width: 80em) {
            height: calc(95vh - 4.5em);
        }
    }

    &:after{
        content: '';
        position: absolute;
        top: 50%;
        right: ;
        bottom: ;
        left: 50%;
        transform: translate(-50%, -50%);
        background-image: url('../images/spinner.svg');
        width: 200px;
        height: 200px;
        z-index: 0;
    }

    iframe{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}




/*-----------------------------------------------------------------------------------------
	/Contact
-----------------------------------------------------------------------------------------*/
.template--contact {
    background: linear-gradient(#f6991a 17rem,white 15rem) no-repeat;

    .site-main {
        overflow: visible;

        &:after {
            display: none;
        }
    }
}
.contact-helper {
    display: none;

    @media(min-width: 62.5em) {
        background: #fff;
        display: flex;
        align-items: center;
        padding: 4em 2em ;
        border: 1px dashed #dacec8;
        box-shadow: 0 0 60px #e6ddd9;
        justify-content: center;
        border-radius: 10px;
        color: #999;
    }

    .icon {
        opacity: .4;
    }
}
.contact-method {
    margin-bottom: .7em;
}
.contact-method__link {
    display: flex;
    outline: none;

    &:hover,&:focus, &.active {
        color: @color-secondary;
    }
}
.contact-method__label {
    flex: 1;
}
.icon--contact-option {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 2px solid fade(@color-body,20%);
    transition: .15s border;

    @media(min-width: 43.75em) {
        margin-top: 4px;
    }

    .contact-method__link:hover &,
    .contact-method__link:focus & {
        border-color: @color-secondary;
    }
    .contact-method__link.active & {
        background-position: 0 -150px;
        border-radius: none;
        border: none;
    }

}
.contact-cols {
    margin-left: -5em;
    margin-bottom: -2em;
}
.contact-col {
    padding-left: 5em;
    padding-bottom: 2em;
}
.contact-options {
    position: relative;

    &:after {
        @media(min-width: 62.5em) {
            content: "";
            left: 54%;
            position: absolute;
            top: 0;
            bottom: 0;
            border-right: 1px solid;
        }
        @media(min-width: 80em) {
            left: 50%;
        }
    }
}
.contact-option-item__link {
    border-bottom: 1px solid @color-body;
    padding: 1rem 0;
    display: block;
}
.contact-option-item__heading {
    padding-right: 2rem;

    @media(min-width: 43.75em) {
        width: 40%;
    }
    @media(min-width: 62.5em) {
        width: auto;
    }
    @media(min-width: 80em) {
        width: 50%;
    }
}
.contact-option-item__link-label {
    font-size: 0.9375rem;
    color: @color-secondary;
    text-decoration: 2px underline fade(@color-secondary, 20%);
    text-underline-offset: 0.3em;
    transition: .1s;

    .contact-option-item__link:hover &,
    .contact-option-item__link:focus  &{
        text-decoration-color: currentColor;
    }
}
.contact-options--logo-motif {
    width: 2.9375rem;
    height: auto;

    @media(min-width: 31.25em) {
        width: 4.125rem;
    }
}



/*-----------------------------------------------------------------------------------------
	/News articles
-----------------------------------------------------------------------------------------*/
.template--news-list {
    .ui-autocomplete {
        border-radius: 0 0 10px 10px;
    }
}
.article-search-col {
    // text-align: right;
}
.article-search {
    position: relative;
    // display: inline-block;
}
.article-search__input {
    border: none;
    border-bottom: 2px solid @color-body;
    padding: .7em 0 .7em 1.9em;
    background: 0 0;
    width: 100%;
    outline: none;
    color: @color-body;
    max-width: 24em;
    border-radius: 0;
}
.button--article-search-submit {
    position: absolute;
    top: 0.9em;
    left: 0;
}
.news-article__hero-image {
    margin: 0 -2em;
    min-height: 60vh;
    position: relative;
    overflow: hidden;

    @media(min-width: 43.75em) {
        margin: 0 -4em;
    }
    @media(min-width: 80em) {
        margin: 0;
    }
    
    img {
        object-fit: cover;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
    }

    .covid-update & {
        padding-bottom: 20%;
        min-height: 20vh;
    }
}

.wysiwyg--table{
    overflow: auto;
    padding-left: 2em;
    padding-right: 2em;
    margin: 0 -2em;
    @media(min-width: 43.75em) {
        padding-left: 4em;
        padding-right: 4em;
        margin: 0 -4em;
    }
    @media(min-width: 62.5em){
        overflow: visible;
    }
    table{
        min-width: 54.5rem;
        margin: auto;
    }
    .bg-red     {background-color: #FC3B3B}
    .bg-amber   {background-color: #FAA61A}
    .bg-green   {background-color: #7FB439}
}



/*-----------------------------------------------------------------------------------------
	/Careers
-----------------------------------------------------------------------------------------*/
.careers-gallery-copy1 {
    @media not all and(min-width: 62.5em) {
        margin-bottom: 2em;
    }
}
.wysiwyg .career-excerpt-items {
    margin: 0;

    li {
        margin-bottom: .3em;
    }
}
.data-item__career-title {
    margin-bottom: 1.2em;
    @media (min-width: 62.5em) {
        width: 20em;
    }
}

.careers-hero{
    .careers-hero__headings{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 3rem;

        @media (min-width: 62.5em) {
            margin-bottom: 1.5em;
        }
    }
}

.template--careers{
    .cms-block--inline-image__img {
        &:before {
            margin-left: -10vw;
            margin-right: -10vw;
        }
    }
}


/*-----------------------------------------------------------------------------------------
	/Infocentre
-----------------------------------------------------------------------------------------*/
.template--infocentre { //Theming
    .headings--card__cat {
        color: @color-quaternary;
    }
    .site-main .link {
        color: @color-quaternary;
        border-color: @color-quaternary;

        &:hover,&:focus {
            box-shadow:  0 4px 0  fade(@color-quaternary, 25%);
        }
    }
    .bg-dark .link {
        color: white;
        border-color: fade(white, 20%);

        &:hover,&:focus {
            border-color: white;
        }
    }
    .card-link--info {
        box-shadow: 0 30px 20px -20px rgba(43, 125, 169, .2);

        &:hover, &:focus {
            box-shadow:
            0 30px 20px -20px rgba(43, 125, 169, .2),
            0 0 0 2px #b9dbec;
        }
    }
}
.bg-infocentre {
    background-color: #E1F1F9;
}


/*-----------------------------------------------------------------------------------------
	/Landing
-----------------------------------------------------------------------------------------*/

.template--landing{
	.section--community-lifestyle__text{
		.panel-overlap{
			bottom: 0;
		}
	}
	.image-grid__wrapper{
		padding-bottom: 5px;

		@media (min-width: 80em){
			padding-bottom: 10px;
		}
	}
}

.template--header-filled.template--landing.template--community-detail{
    .section--landing-quote, .section--landing-main-blurb{
        margin-top: 0;
    }
}

/*-----------------------------------------------------------------------------------------
	/Home
-----------------------------------------------------------------------------------------*/
.template--search-results {
    
}

.search-results-section {
    .pagination {
        text-align: left;
    }
}
.search-results-section__header {
    margin: 0 0 2rem;
    padding: 0 3em 0 0;
    @media (min-width: 43.75em) {
        margin: 0 0 4rem;
    }
}

.search-results {
    + .pagination {
        margin-top: 4rem;
    }
    @media (min-width: 80em) {
        margin-right: 8.3333333%;
    }
}

.search-results__item {
    border-bottom: 1px solid fade(#000, 10%);
    &:last-child {
        border-bottom: 0;
    }
    @media (min-width: 43.75em) {
        border-bottom-color: #000;
    }
}
.search-result {
    display: flex;
    padding: 1.25rem 0;
    @media (min-width: 43.75em) {
        padding: 1.5rem 0;
    }
    &:hover,
    &:focus {
        .search-result__col2 {
            color: @color-secondary;
        }
    }
}
.search-result--center {
    align-items: center;
}
.search-result__col1 {
    width: 5.3rem;
    flex: 0 0 5.3rem;
    margin: 0 0.875rem auto 0;
    text-align: right;
    @media (min-width: 43.75em) {
        width: 10rem;
        flex: 0 0 10rem;
        margin: 0 2rem auto 0;
        text-align: left;
    }
}
.search-result__col2 {
    max-width: 31.25rem;
    flex: 1;
    transition: color .2s;
}
.search-result__img {
    max-width: 7.125rem;
}
.search-result__img-bg {
    border-radius: 3px;
}
.search-result__module-name {
    .weight-medium;
    font-size: 0.75rem;
    line-height: 1.3;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    opacity: 0.5;
}
.search-result__meta {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.search-result__highlight {
    background: fade(@color-primary, 20%);
}



/*-----------------------------------------------------------------------------------------
    /Covid section
-----------------------------------------------------------------------------------------*/
.template--covid-section{

    main section:last-child {
        .section-inner--py;
    }
    .covid-section__big-heading{
        & + .covid-section__heading-copy .cms-block:before {
            width: 8em;
            display: block;
            height: 1px;
            background: #2e3238;
            content: "";
            margin: -1em 0 3em;
            
        }
    }
    .covid-section__big-subheading{
        @media(min-width: 43.75em) {
            margin-top: -2em;
        }
    }

    .bg--white + .bg--white{
        padding-top: 0;
    }

    .headings--card__cat{
        color: @color-secondary;
    }
    .site-main .link{
        color: @color-secondary;
    }
    .card-link--info{
        background: fade(@color-secondary, 3%);
        border: 1px solid fade(@color-secondary, 20%);;
        box-shadow: 0 30px 20px -20px rgb(144 64 152 / 20%);
        
        &:hover, &:focus{
            box-shadow: 0 30px 20px -20px rgb(144 64 152 / 20%), 0 0 0 2px @color-secondary;
        }
    }

    // .card-readmore{
    //     margin-top: 1em;
    //     @media(min-width: 31.25em){
    //         margin-top: 2em;
    //     }
    // }
    .card-link.card-link--info:hover .card-readmore{
        background-color: fade(@color-secondary, 20%);
    }
    .site-main .link:hover{
        box-shadow: 0 4px 0 rgb(144 64 152 / 25%);
    }
    .card-link:hover .link,
    .card-link:focus .link{
        box-shadow: 0 4px 0 rgb(144 64 152 / 25%);
    }
    .bg-dark .link{
        color: #fff;
    }
    .vertical-subnav-link.active, .vertical-subnav-link:focus, .vertical-subnav-link:hover{
        color: @color-secondary;
    }
    .vertical-subnav-item{
        border-bottom: 1px solid rgba(144, 64, 152, 0.2);
    }
    .infocentre-cat-label{
        color: @color-secondary;
    }
    a[href$=".pdf"] { 
        margin: 1em 0;
        display: inline-flex;
        align-items: center;
        position: relative;
        color: @color-secondary;
        border-bottom: 2px solid rgba(144,64,152,.2);
        &:hover,
        &:focus{
            border-bottom-color: @color-secondary;
        }
        &:before{
            content: "";
            position: relative;
            margin-right: .5em;
            background: url('/images/icons/icon--download--primary.svg');
            width: 21px;
            height: 25px;
            flex-shrink: 0;
            margin-bottom: auto;
        }
        &:after{
            content: "";
            position: absolute;
            left: 0;
            bottom: -6px;
            height: 6px;
            width: 31px;
            background-color: white;
            flex-shrink: 0;
        }
    }

    .vertical-subnav-items{
        &.sticky.--visible{
            position: static;
        }
        &.vertical-subnav-items--hidden.--visible{
            visibility: visible;
            opacity: 1;
            transform: none;
        } 

    }
}
.covid-header__headings {
    @media(min-width: 80em) {
        margin-bottom: 0;
    }
}
.covid-hero-cats{
    width: 100%;
    margin-top: 2rem;

    @media (min-width: 80em){
        width: 41.66666667%;
    }

    &.--no-cats {
        @media(max-width: 80em - .0625) {
            display: none;
        }

        display: flex;
        flex-direction: column;

        & > div {
            @media(min-width: 80em) {
                margin-top: auto;
                padding-bottom: 0.8125rem; // compensate  for line height of heading
            }
        }
    }
}
.covid-hero-cats__list {
    align-items: flex-start;
}
.covid-hero-cats__heading{
    // margin-bottom: .91em;
}

.covid-hero-cats__list{
    @media (min-width: 80em){
        margin-bottom: 3.3125rem;
    }
}

.icon--search--lg--covid{
    width: 27px;
    height: 27px;
    background-position: -350px -200px;
}

.section--covid-cta{
    background-color: white;

    @media (min-width: 62.5em){
        padding-bottom: 5em;
    }
    @media (min-width: 80em){
        padding-bottom: 7em;
    }

    .covid-header + &{
        .section-inner--pt;
    }

    .section-inner--pt + &{
        .section-inner--pt;
    }
    
    & + .bg--white{
        .section-inner--pt;
        
        @media (min-width: 80em){
            padding-top: 0;
        }
    }
}

.covid--category .section-hero--inner{
    @media (min-width: 62.5em){
        align-items: flex-end;
    }

    .headings--page{
        @media (min-width: 62.5em){
            margin-bottom: 0;
        }
    }
    
    .heading--page{
        margin-top: .5rem;
        
        @media (min-width: 43.75em){
            margin-top: 1rem;
        }
    }

    .covid--category__search{
        @media (min-width: 62.5em){
            margin-bottom: 0.8125rem;
        }
    }
}




/*-----------------------------------------------------------------------------------------
	/Region landing page
-----------------------------------------------------------------------------------------*/
.template--region-landing{
    .community-map {
        margin: 0 -2em -4em;
        
        @media(min-width: 43.75em) {
            margin: 0 -4em -5em;
        }
        @media(min-width: 62.5em) {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
        }
    }
}




/*-----------------------------------------------------------------------------------------
    /International cookie banner
-----------------------------------------------------------------------------------------*/

// .international-banner{
//     position: fixed;
//     right: .5em;
//     bottom: 5.5em;
//     left: 0.5em;
//     padding: 1.125rem 1.5rem;
//     background-color: #fff;
//     border-radius: 0.625rem;
//     border: 1px solid #dfdfdf;
//     z-index: 10;
//     box-shadow: 0 40px 10px -40px rgb(0 0 0 / 11%);
//     transition-duration: .3s;
//     transition-timing-function: cubic-bezier(.86,0,.07,1);
    
//     @media(min-width: 43.75em) {
//         display: flex;
//         align-items: center;
//         column-gap: 1.25rem;
//         max-width: 39.7em;
//         width: 100%;
//         padding: 1.5938rem 2.125rem;
//         right: auto;
//         bottom: 8.75em;
//         left: 50%;
//         transform: translateX(-50%);
//     }
//     @media(min-width: 62.5em) {
//         max-width: 41.875em;
//     }
    
//     .template--community-detail &{
//         bottom: 5.5em;
        
//         @media(min-width: 43.75em) {
//             bottom: 5.5em;
//         }
//     }
// }
// .international-banner__text{
//     font-size: 1rem;
//     margin-right: 1rem;
//     margin-bottom: 1.5rem;
//     @media(min-width: 43.75em) {
//         margin-bottom: 0;
//     }
// }
// .international-banner__button{
//     padding:  0.8125rem 1.9375rem;
//     font-size: 0.9375rem;
//     min-height: 0;
// }
// .international-banner__link{
//     color: @color-body;
//     border-color: fade(@color-body, 20%);

//     &:hover, &:focus{
//         color: @color-body;
//         border-color: @color-body;
//         box-shadow: 0 4px 0 fade(@color-body, 25%);
//     }
// }
// .international-banner-close{
//     width: 2rem;
//     height: 2rem;
//     position: absolute;
//     top: 0;
//     right: 0;
//     color: @color-body;

//     &:hover, &:focus{
//         color: @color-links;
//     }
// }

.international-banner{
    position: fixed;
    right: 0.4375em;
    bottom: 1rem;
    left: 0.4375em;
    padding: 1.125rem 1.5rem;
    background-color: #fff;
    border-radius: 0.625rem;
    border: 1px solid #dfdfdf;
    z-index: 10;
    box-shadow: 0 40px 10px -40px rgb(0 0 0 / 11%);
    transition-duration: .3s;
    transition-timing-function: ease-in-out;
    
    @media(min-width: 43.75em) {
        display: flex;
        align-items: center;
        column-gap: 1.25em;
        max-width: 35.875em;
        width: 100%;
        padding: 1.5938em 2.125em;
        right: auto;
        bottom: 1.25em;
        left: 1.25em;
    }
    @media(min-width: 62.5em) {
        left: 50%;
        transform: translateX(-50%);
        max-width: 41.875em;
    }
    .community-cta-bar-active.scrolling &{
        @media(min-width: 43.75em) {
            bottom: 5.4375em;
        }
    }
}
.international-banner__text{
    font-size: 1rem;
    margin-right: 1rem;
    margin-bottom: 1.5rem;
    @media(min-width: 43.75em) {
        margin-bottom: 0;
    }
}
.international-banner__button{
    padding:  0.8125rem 1.9375rem;
    font-size: 0.9375rem;
    min-height: 0;
}
.international-banner__link{
    color: @color-body;
    border-color: fade(@color-body, 20%);
}
.international-banner-close{
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0;
    right: 0;
    color: @color-body;

    &:hover, &:focus{
        color: @color-links;
    }
}



