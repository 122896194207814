:root {
	--cta-bar-height: 75px;
	--header-height: 64px;
	
	@media (min-width: 43.75em) {
		--header-height: 96px;
	}
	@media (min-width: 80em) {
		--header-height: 104px;
	}
	@media (min-width: 100em) {
		--header-height: 136px;
	}
}

/*-----------------------------------------------------------------------------------------
	/Less variables / mixins
-----------------------------------------------------------------------------------------*/
//Timestamp
	@date: `new Date().getTime()`;
	@timestamp: "?v=@{date}"; 
	// @timestamp: "?v=20180629";

//Fonts
	@font-body: 'Sailec W05', 'Helvetica Neue', Arial, sans-serif;
	.weight-regular { font-weight: 400; }
	.weight-medium { font-weight: 500; }
	.weight-bold { font-weight: 600; }

//Colors
	@color-body: #2E3238; //Granite
	@color-links: #2E3238; //Granite
	@color-primary: #F6921E; //Orange
	@color-secondary: #904098; //Purple
	@color-tertiary: #D7EDF3; //Washed out green
	@color-quinary: #00a3bc; //Teal
	@color-quaternary: #008FD5; //Blue
	@color-senary: #f1e9e5; //Off white
	@color-red: #dc4a4f; //Error
	@color-gf-teal: #007490;

	@color-grey-1: #eee;
	@color-grey-2: #ddd;
	@color-grey-3: #ccc;
	@color-grey-4: #bbb;
	@color-grey-5: #aaa;
	@color-grey-6: #999;
	@color-grey--a11y : #767676; //Lightest accessible grey

	.bg-dark {
		color: white;

		.link,
		p a {
			color: white;
			border-bottom-color: fade(white,20%);
		}
		.link:hover, .link:focus,
		p a:hover, p a:focus {
			color: white;
			border-bottom-color: white;
			box-shadow: 0 4px 0 fade(white,25%);
		}
	}
	.bg-body { background-color: @color-body; }
	.bg-tertiary { background-color: @color-tertiary; }
	.bg-tertiary-40 { background-color: fade(@color-tertiary,40%); }
	.bg-senary { background-color: @color-senary; }
	.bg-senary--light { background-color: fade(@color-senary,70%); }
	.bg-gradient-purple { background: linear-gradient(165deg, #662D91, #A154A1); }
	.bg--white {
		background-color: white;
	}
	.bg-orange--waves,
	.bg-purple--waves,
	.bg-teal--waves,
	.bg-blue--waves {
		background-size: cover;
		@media(min-width: 80em) {
			background-position: center;
			background-attachment: fixed;
		}
	}
	.bg-orange--waves {
		background-color: @color-primary;
		background-image: url('/images/bg--waves--orange.svg');
	}
	.bg-purple--waves {
		background-color: @color-secondary;
		background-image: url('/images/bg--waves--purple.svg');
	}
	.bg-teal--waves {
		background-color: @color-quinary;
		background-image: url('/images/bg--waves--teal.svg');
	}
	.bg-blue--waves {
		background-color: @color-quaternary;
		background-image: url('/images/bg--waves--blue.svg');
	}


//Shadows
	.shadow { box-shadow:  0 40px 30px -30px fade(black,20%); }
	.shadow-hover { box-shadow: 0 23px 20px -20px fade(black,55%); }

//Easings
	@ease-quint: cubic-bezier(0.86, 0, 0.07, 1);



/*-----------------------------------------------------------------------------------------
	/Aspect ratios
-----------------------------------------------------------------------------------------*/
.aspect-ratio {
    height: 0;
    position: relative;
}
.aspect-ratio--16x9 { padding-bottom: 56.25%; }
.aspect-ratio--2x1 { padding-bottom: 50%; }
.aspect-ratio--4x3 { padding-bottom: 75%; }
.aspect-ratio--6x4 { padding-bottom: 66.6%; }
.aspect-ratio--4x6 { padding-bottom: 140%; }
.aspect-ratio--3x4 { padding-bottom: 117%; }
.aspect-ratio--1x1 { padding-bottom: 100%; }




/*-----------------------------------------------------------------------------------------
	/Mixins + utilities
-----------------------------------------------------------------------------------------*/
.scrollbars {
	::-webkit-scrollbar {
	    width: 8px;
	}
	::-webkit-scrollbar-track {
	    border-radius: 10em;
		// background: fade(@color-body, 10%);
	}
	::-webkit-scrollbar-thumb {
		border-radius: 10em;
		background: fade(@color-body, 40%);
		}
}
.webkit-scrolling { -webkit-overflow-scrolling: touch;}
.webkit-tap-color { -webkit-tap-highlight-color: transparent; -webkit-tap-highlight-color: rgba(0,0,0,0); }

.pseudo {
	content: "";
	display: block;
}
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;}

.flex { display: flex; }
.invisible { visibility: hidden; }
.flex { display:flex; }
.flex-1 { flex: 1; }
.flex-column { flex-direction: column; }
.flex-row-reverse { flex-direction: row-reverse; }
.flex-shrink-0 { flex-shrink: 0; }
.flex-wrap { flex-wrap: wrap; }
.items-center { align-items: center; }
.items-start { align-items: flex-start; }
.items-stretch { align-items: stretch; }
.items-end { align-items: flex-end; }
.items-baseline { align-items: baseline; }
.justify-between { justify-content: space-between; }
.justify-end { justify-content: flex-end; }
.justify-center { justify-content: center; }
.text-right { text-align: right; }
.text-center { text-align: center; }

@media(min-width: 43.75em) {
	.m-flex { display:flex; }
}


/*------------------------------------------------------------------------------
    /Global sticky
------------------------------------------------------------------------------*/
.l-sticky {
	@media(min-width: 62.5em) {
		position: sticky;
		top: 10rem;
	}
	@media(min-width: 80em) {
		top: 8rem;
	}
}

/*------------------------------------------------------------------------------
    /Grid - rows
------------------------------------------------------------------------------*/
.grid-row {
    display: flex;
	flex-wrap: wrap;
}
@media(min-width: 43.75em) {
	.m-grid-row--none { display: block; }
}
.grid-row--center {
	align-items: center;
}

/*------------------------------------------------------------------------------
    /Grid - columns
------------------------------------------------------------------------------*/
.grid-col {
	width: 100%;
}
.grid-col--grow {
    flex: 1;
}
.has-gutters { margin-left: -1.2em; margin-bottom: -1.2em; }
.has-gutter { padding-left: 1.2em; padding-bottom: 1.2em; }
.has-gutters--y-l { margin-left: -1.2em; margin-bottom: -4em; }
.has-gutter--y-l { padding-left: 1.2em; padding-bottom: 4em; }
.has-gutters-s { margin-left: -10px; margin-bottom: -10px; }
.has-gutter-s { padding-left: 10px; padding-bottom: 10px; }
.has-gutters-l { margin-left: -4em; margin-bottom: -4em; }
.has-gutter-l { padding-left: 4em; padding-bottom: 4em; }

.has-gutters--cards {
	margin-bottom: -3em;
	@media(min-width: 62.5em) {
		margin-bottom: -5em;
	}
}
.has-gutter--cards {
	padding-bottom: 3em;
	@media(min-width: 62.5em) {
		padding-bottom: 5em;
	}
}
.xs-0 { width: 0; }
.xs-1 { width: 8.3333333%; }
.xs-2 { width: 16.6666666666%; }
.xs-3 { width: 25%; }
.xs-4 { width: 33.333333333%; }
.xs-5 { width: 41.666666666%; }
.xs-6 { width: 50%; }
.xs-7 { width: 58.333333333%; }
.xs-8 { width: 66.66666%; }
.xs-9 { width: 75%; }
.xs-10 { width: 83.333333333%; }
.xs-11 { width: 91.6666666667%; }
.xs-12 { width: 100%; }

.xs-offset-1 { margin-left: 8.333333333% }
.xs-offset-2 { margin-left: 16.6666666666% }
.xs-offset-8 { margin-left: 66.66666% }
.xs-offset-10 { margin-left: 83.333333333% }
.xs-offset-12 { margin-left: 100% }

.col-pb { padding-bottom: 3em; } //Vertical-only padding, toggleable once in columns


@media(min-width: 31.25em) { //500px
    .s-1 { width: 8.3333333%; }
    .s-2 { width: 16.6666666666%; }
    .s-2b { width: 19.999999999%; }
    .s-3 { width: 25%; }
    .s-4 { width: 33.333333333%; }
    .s-5 { width: 41.666666666%; }
    .s-6 { width: 50%; }
    .s-7 { width: 58.333333333%; }
    .s-8 { width: 66.66666%; }
    .s-9 { width: 75%; }
    .s-10 { width: 83.333333333%; }
    .s-11 { width: 91.6666666667%; }
    .s-12 { width: 100%; }
    .s-auto { width: auto; }

	.s-offset-1 { margin-left: 8.333333333% }
	.s-offset-2 { margin-left: 16.6666666666% }
	.s-offset-8 { margin-left: 66.66666% }
}
@media(min-width: 43.75em) { //700px
    .m-1 { width: 8.3333333%; }
    .m-2 { width: 16.6666666666%; }
    .m-2b { width: 19.999999999%; }
    .m-3 { width: 25%; }
    .m-4 { width: 33.333333333%; }
    .m-5 { width: 41.666666666%; }
    .m-6 { width: 50%; }
    .m-7 { width: 58.333333333%; }
    .m-8 { width: 66.66666%; }
    .m-9 { width: 75%; }
    .m-10 { width: 83.333333333%; }
    .m-11 { width: 91.6666666667%; }
    .m-12 { width: 100%; }
    .m-auto { width: auto; }

	.m-offset-0 { margin-left: 0 }
	.m-offset-1 { margin-left: 8.333333333% }
	.m-offset-2 { margin-left: 16.6666666666% }
	.m-offset-8 { margin-left: 66.66666% }
	.m-offset-10 { margin-left: 91.6666666667% }
}
@media(min-width: 62.5em) { //1000px
    .l-1 { width: 8.3333333%; }
    .l-2 { width: 16.6666666666%; }
    .l-2b { width: 19.999999999%; }
    .l-3 { width: 25%; }
    .l-4 { width: 33.333333333%; }
    .l-5 { width: 41.666666666%; }
    .l-6 { width: 50%; }
    .l-7 { width: 58.333333333%; }
    .l-8 { width: 66.66666%; }
    .l-9 { width: 74.9999999%; }
    .l-10 { width: 83.333333333%; }
    .l-11 { width: 91.6666666667%; }
    .l-12 { width: 100%; }
    .l-auto { width: auto; }

	.l-offset-0 { margin-left: 0 }
	.l-offset-1 { margin-left: 8.333333333% }
	.l-offset-2 { margin-left: 16.6666666666% }
	.l-offset-6 { margin-left: 50% }
	.l-offset-8 { margin-left: 66.66666% }

	.l-col-pb-0 { padding-bottom: 0; }

}
@media(min-width: 80em) { //1280px
    .xl-1 { width: 8.3333333%; }
    .xl-2 { width: 16.6666666666%; }
    .xl-2b { width: 19.999999999%; }
    .xl-3 { width: 25%; }
    .xl-4 { width: 33.333333333%; }
    .xl-5 { width: 41.666666666%; }
    .xl-6 { width: 50%; }
    .xl-7 { width: 58.333333333%; }
    .xl-8 { width: 66.66666%; }
    .xl-9 { width: 74.9999999%; }
    .xl-10 { width: 83.333333333%; }
    .xl-11 { width: 91.6666666667%; }
    .xl-12 { width: 100%; }
    .xl-auto { width: auto; }

	.xl-offset-0 { margin-left: 0 }
	.xl-offset-1 { margin-left: 8.333333333% }
	.xl-offset-2 { margin-left: 16.6666666666% }
	.xl-offset-8 { margin-left: 66.66666% }
}
@media(min-width: 87.5em) { //1400px
    .xxl-1 { width: 8.3333333%; }
    .xxl-2 { width: 16.6666666666%; }
    .xxl-2b { width: 19.999999999%; }
    .xxl-3 { width: 25%; }
    .xxl-4 { width: 33.333333333%; }
    .xxl-5 { width: 41.666666666%; }
    .xxl-6 { width: 50%; }
    .xxl-7 { width: 58.333333333%; }
    .xxl-8 { width: 66.66666%; }
    .xxl-9 { width: 74.9999999%; }
    .xxl-10 { width: 83.333333333%; }
    .xxl-11 { width: 91.6666666667%; }
    .xxl-12 { width: 100%; }
    .xxl-auto { width: auto; }

	.xxl-offset-1 { margin-left: 8.333333333% }
	.xxl-offset-2 { margin-left: 16.6666666666% }
	.xxl-offset-8 { margin-left: 66.66666% }
}



/*-----------------------------------------------------------------------------------------
	/Hide items
-----------------------------------------------------------------------------------------*/
.m-visible {
	display: none;

	@media(min-width: 43.75em) {
		display: block;
	}
}
.l-visible {
	display: none;

	@media(min-width: 62.5em) {
		display: block;
	}
}
.l-hidden {
	@media(min-width: 62.5em) {
		display: none;
	}
}
.xl-visible {
	display: none;

	@media(min-width: 80em) {
		display: block;
	}
}
.xl-hidden {
	@media(min-width: 80em) {
		display: none;
	}
}

/*-----------------------------------------------------------------------------------------
	/Whitespace
-----------------------------------------------------------------------------------------*/
.section-inner {
	padding-left: 2em;
	padding-right: 2em;

	@media(min-width: 43.75em) {
		padding-left: 4em;
		padding-right: 4em;
	}
	@media(min-width: 80em) {
		padding-left: 4em;
		padding-right: 4em;
	}
	@media(min-width: 87.5em) {
		padding-left: 6em;
		padding-right: 6em;
	}
}
.section-inner--pb {
	padding-bottom: 4em;

	@media(min-width: 43.75em) { padding-bottom: 5em; }
	@media(min-width: 80em) { padding-bottom: 7em; }
}
.section-inner--pt {
	padding-top: 4em;

	@media(min-width: 43.75em) { padding-top: 5em; }
	@media(min-width: 80em) { padding-top: 7em; }
}
.section-inner--py {
	padding-top: 4em;
	padding-bottom: 4em;

	@media(min-width: 43.75em) { padding-top: 5em; padding-bottom: 5em; }
	@media(min-width: 80em) { padding-top: 7em; padding-bottom: 7em; }
}
.col-pad--r {
	@media(min-width: 62.5em) {
		padding-right: 4.5em;
	}
}
.col-pad--l {
	@media(min-width: 62.5em) {
		padding-left: 5em;
	}
}
.col-pad--m {
	@media(min-width: 43.75em) {
		padding-left: 5em;
	}
}

.col-divider-right{
	position: relative;

	&:after{
		@media(min-width: 62.5em) {
			.pseudo;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 1px;
			background-color: @color-body;
		}
	}
}



/*-----------------------------------------------------------------------------------------
	/Whitespace - small
-----------------------------------------------------------------------------------------*/
.section-inner--s {
	padding-left: 2em;
	padding-right: 2em;

	@media(min-width: 43.75em) {
		padding-left: 3em;
		padding-right: 3em;
	}
	@media(min-width: 62.5em) {
		padding-left: 4em;
		padding-right: 4em;
	}
}
.section-inner--pb-s {
	padding-bottom: 2em;

	@media(min-width: 43.75em) {
		padding-bottom: 4em;
	}
}
.section-inner--pt-s {
	padding-top: 2em;

	@media(min-width: 43.75em) {
		padding-top: 4em;
	}
}



/*-----------------------------------------------------------------------------------------
	/Blocks
-----------------------------------------------------------------------------------------*/
.block {
	margin-bottom: 3em;

	&:last-child {
		margin-bottom: 0;
	}
}



/*-----------------------------------------------------------------------------------------
	/Containment - max widths
-----------------------------------------------------------------------------------------*/
@media(min-width:43.75em) {
	.mw-1400 { max-width: 1400px; }
	.mw-1200 { max-width: 1200px; }
	.mw-1000 { max-width: 1000px; }
	.mw-800 { max-width: 800px; }
	.mw-700 { max-width: 700px; }

	.mw-45em { max-width: 45em; }
	.mw-40em { max-width: 40em; }
	.mw-32em { max-width: 32em; }
	.mw-30em { max-width: 30em; }
	.mw-20em { max-width: 20em; }
}


/*------------------------------------------------------------------------------
    /Bespoke padding + margins
------------------------------------------------------------------------------*/
.mx-auto { margin-left: auto; margin-right: auto; }
.my-auto { margin-top: auto; margin-bottom: auto; }
.mb-auto { margin-bottom: auto; }
.mt-auto { margin-top: auto; }

.mt-1 { margin-top: 1em;}
.mt-2 { margin-top: 2em;}
.mt-3 { margin-top: 3em;}
.mt-4 { margin-top: 4em;}
.mt-5 { margin-top: 5em;}
.mt-6 { margin-top: 6em;}

.mb-05 { margin-bottom: .5em;}
.mb-1 { margin-bottom: 1em;}
.mb-2 { margin-bottom: 2em;}
.mb-3 { margin-bottom: 3em;}
.mb-4 { margin-bottom: 4em;}
.mb-5 { margin-bottom: 5em;}
.mb-6 { margin-bottom: 6em;}

.mr-1 { margin-right: .5rem;}
.mr-2 { margin-right: 1rem;}	
.mr-3 { margin-right: 1.5rem;}	
.mr-4 { margin-right: 2rem;}	

@media(min-width: 62.5em) {
	.l\:pl-3 {
		padding-left: 3em;
	}
	.l-mx-0 { margin-left: 0; margin-right: 0; }
}

.pr-4 { padding-right: 2rem; }
.pr-8 { padding-right: 4rem; }
.pl-2 { padding-left: 1rem; }
.pl-4 { padding-left: 2rem; }
.pl-6 { padding-left: 3rem; }
.pl-8 { padding-left: 4rem; }
.pt-4 { padding-top: 2rem;}
.pt-6 { padding-top: 3rem;}
.pb-4 { padding-bottom: 2rem;}

@media(min-width: 100em) {
	.xxl-pr-8 { padding-right: 4rem; }
}


/*------------------------------------------------------------------------------
    /Utilities
------------------------------------------------------------------------------*/
.height-100 { height: 100%; }
.relative { position: relative; }
.flex-1 { flex: 1;}